<template>
  <div id="level" :class="'id-'+levelConfig.id">
    <!-- LEVEL VIEW -->
    <transition name="fade">
      <LoadingScreen id="level-loader"
                     v-if="!levelReady"
                     :percentage="preloaderPercent">
      </LoadingScreen>
    </transition>

    <Statusbar id="status-bar"
               :level-id="levelConfig.id"
               :show-score="!summary"
               :animate-score="true"
               :theme="activeDisplay ? 'reduced' : 'default'">

      <template v-slot:maintitle>
        {{ levelConfig.title }}
      </template>
      <template v-slot:subtitle>
        {{ levelConfig.subtitle }}
      </template>
    </Statusbar>

    <Animation id="animations"
               ref="animations"
               :animation-layers="['background', 'player', 'robot']">
    </Animation>

    <Dialogue id="dialogue-container"
              :dialogue-texts="dialogueTexts"
              :layout="quizOpen ? 'sides' : 'centered'">
    </Dialogue>

    <Quiz id="quiz-container"
          v-if="activeQuiz"
          :quiz-config="activeQuiz.config"
          :locked="quizLocked"
          @status-change="onQuizStatusChange"
          @show-hint="showQuizHintDialog"
          @exit="onQuizEnd">
    </Quiz>

    <Display id="display-container"
             v-if="activeDisplay"
             :content="activeDisplay['config']['content']"
             @exit="onDisplayEnd">
    </Display>

    <Video id="video-container"
           v-if="activeVideo"
           :video-config="activeVideo">
    </Video>

    <LevelSummary id="level-summary"
                  v-if="summary"
                  :level-config="levelConfig"
                  @restart-level="restartLevel"
                  @exit-level="exitLevel"
                  @next-level="$emit('level-end', levelConfig.nextLevelId)">
    </LevelSummary>

    <transition name="fade">
      <div class="user-promt-wrapper">
        <btn id="user-promt"
             :icon="'right'"
             v-if="userPromt"
             @click="onUserPromtClick">
          {{ userPromt.text }}
        </btn>
      </div>
    </transition>

    <transition name="fade" @after-enter="onBlackoutIn" @after-leave="onBlackoutOut">
      <div id="blackout" v-if="blackout"></div>
    </transition>

    <Tutorial :show-tutorial="tutorialOpen" @tutorial-end="onTutorialEnd"></Tutorial>

    <Audio ref="audio"></Audio>
  </div>
</template>

<script>
import LoadingScreen from "@/components/LoadingScreen";
import Btn from "@/components/interface/btn"
import Statusbar from "@/components/Statusbar"
import Animation from "@/components/Animation"
import Dialogue from "@/components/Dialogue.vue"
import Quiz from "@/components/Quiz.vue";
import Display from "@/components/Display";
import Audio from "@/components/Audio";
import Preloader from "@/script/preload";
import LevelSummary from "@/components/LevelSummary";
import Tutorial from "@/components/Tutorial";
import basicLevelAssets from "@/assets/basicLevelAssets";
import basicTutorialAssets from "@/assets/basicTutorialAssets";

export default {
  name: 'Level',
  components: {
    LoadingScreen,
    Btn,
    Statusbar,
    Animation,
    Dialogue,
    Quiz,
    Display,
    Audio,
    LevelSummary,
    Tutorial
  },
  props: {
    'levelConfig': Object
  },
  data: () => {
    return {
      preloaderPercent: 0,
      levelReady: false,
      activeLevelStep: 0,
      blackout: false,
      blackoutCallback: null,
      tutorialOpen: false,
      tutorialCallback: null,
      userPromt: null,
      dialogueTexts: [],
      activeQuiz: null,
      quizLocked: false,
      activeVideo: null,
      activeDisplay: null,
      summary: null
    }
  },
  computed: {
    quizOpen() {
      return !!this.activeQuiz;
    },
    levelScore() {
      return this.$store.getters.levelScore(this.levelConfig.id);
    },
    showTutorial(){
      return this.$store.getters.shouldSeeTutorial;
    }
  },
  watch: {
    levelConfig(newConfig, oldConfig) {
      if (newConfig.id !== oldConfig.id) {
        this.restartLevel();
      }
    }
  },
  methods: {
    initLevel() {
      // TODO: end all sounds and animations (hard) in case we are replaying the level
      this.levelReady = false;
      this.activeLevelStep = 0;
      this.userPromt = null;
      this.dialogueTexts = [];
      this.activeQuiz = null;
      this.quizLocked = false;
      this.activeVideo = null;
      this.activeDisplay = null;
      this.summary = null;

      this.loadLevelAssets(() => {
        this.applySavegame(() => {
          this.levelReady = true;
        });
      });
    },

    loadLevelAssets(callback) {
      let levelAssets = this.levelConfig['preload'];

      let preloadPaths = {
        'animations': {
          ...basicLevelAssets['animations'],
        },
        'sounds': {
          ...basicLevelAssets['sounds'],
          ...levelAssets['sounds']
        },
        'images': {
          ...basicLevelAssets['images'],
          ...levelAssets['images']
        }
      }

      Object.keys(levelAssets['animations']).forEach((layerName) => {
        Object.keys(levelAssets['animations'][layerName]).forEach((animationName) => {
          preloadPaths['animations'][(layerName + '__' + animationName)] = levelAssets['animations'][layerName][animationName]['path'];
        });
      });

      Object.keys(levelAssets['speech']).forEach((characterName) => {
        Object.keys(levelAssets['speech'][characterName]).forEach((characterSpeech) => {
          preloadPaths['sounds'][(characterName + '__' + characterSpeech)] = levelAssets['speech'][characterName][characterSpeech];
        });
      });

      if (this.showTutorial){
        preloadPaths = {
          'animations': {
            ...preloadPaths['animations'],
          },
          'sounds': {
            ...preloadPaths['sounds'],
            ...basicTutorialAssets['sounds']
          },
          'images': {
            ...preloadPaths['images'],
            ...basicTutorialAssets['images']
          }
        }
      }

      Preloader(preloadPaths, (percentage)=>{
        this.preloaderPercent = percentage;
        if (percentage === 1){
          callback();
        }
      });
    },

    applySavegame(callback) {
      // let savedLevelState = this.$store.getters['levelState'](this.levelConfig.id);
      // if (savedLevelState){
      //   this.applyLevelState(savedLevelState, (startingIndex)=>{
      //     this.startLevel(startingIndex);
      //     callback();
      //   });
      //
      // } else {
      this.startLevel();
      callback();
      // }
    },

    applyLevelState(state, callback) {
      // console.log('LEVEL | Applying saved state', state);
      let startingIndex = 0;
      callback();
    },

    startLevel(startIndex = 0) {
      let dev_rating = this.$route.query['r'];
      let dev_index = this.$route.query['s'];

      if (dev_rating) {
        this.$store.commit('setLevelProgress', {
          'levelId': this.levelConfig.id,
          'status': 'started',
          'score': parseInt(dev_rating)
        });
      }

      if (dev_index) {
        startIndex = parseInt(dev_index);
      }
      // enter first step of level and loop through steps till last one.
      this.levelConfig.beforeLevelEnter
          ? this.levelConfig.beforeLevelEnter(this, this.loopLevelSteps.bind(this, startIndex))
          : this.loopLevelSteps(startIndex);
    },

    loopLevelSteps(stepIndex) {
      let nextStep = this.levelConfig.steps[stepIndex];

      if (nextStep) {
        // console.log('STEP LOOP | at:', stepIndex + 1, 'of:', this.levelConfig.steps.length, 'type:', nextStep.type || 'custom type');

        this.activeLevelStep = stepIndex;
        this.enterStep(nextStep, () => {
          this.loopLevelSteps(stepIndex + 1);
        });

      } else {
        // console.log('STEP LOOP | End reached - exiting loop');
        this.showSummary();

        this.$store.commit('setLevelProgress', {
          'levelId': this.levelConfig.id,
          'status': 'finished',
          'score': this.$store.getters.levelScore(this.levelConfig.id).scored
        });
        this.$store.commit('persistSavegame');
      }
    },

    enterStep(step, next) {
      // console.log('STEP | Entering:', step);
      beforeEnter.call(this);

      function beforeEnter() {
        step['beforeEnter'] ? step['beforeEnter'](this, maybeBlackoutIn.bind(this)) : maybeBlackoutIn.call(this);

        function maybeBlackoutIn(){
          if (step.blackout){
            this.blackoutCallback = afterEnter.bind(this);
            this.blackout = true;
          } else {
            afterEnter.call(this);
          }
        }
      }

      function afterEnter() {
        switch (step.type) {

          case 'autosave':
            step["afterEnter"] && step["afterEnter"](this, () => {
            });
            this.autosave();
            beforeLeave();
            break;

          case 'tutorial':
            step["afterEnter"] && step["afterEnter"](this, () => {
            });
            if (this.showTutorial){
              this.tutorialOpen = true;
              this.tutorialCallback = ()=>{
                this.tutorialOpen = false;
                beforeLeave.call(this);
              }
            } else {
              beforeLeave.call(this);
            }
            break;

          case 'dialogue':
            step["afterEnter"] && step["afterEnter"](this, () => {
            });
            this.showDialogue(step["dialogue"], () => {
              beforeLeave.call(this)
            });
            break;

          case 'quiz':
            step["afterEnter"] && step["afterEnter"](this, () => {
            });
            this.startQuiz(step['quiz'], () => {
              beforeLeave.call(this)
            });
            break;

          case 'display':
            step["afterEnter"] && step["afterEnter"](this, () => {
            });
            this.startDisplay(step, () => {
              beforeLeave.call(this)
            });
            break;

          default:
            step["afterEnter"] ? step["afterEnter"](this, beforeLeave.bind(this)) : beforeLeave.call(this);
        }
      }

      function beforeLeave() {

        if (step.blackout){
          this.blackoutCallback = _beforeLeave.bind(this);
          this.blackout = false;
        } else {
          _beforeLeave.call(this);
        }

        function _beforeLeave() {

          if (step.waitForUser) {
            this.waitForUser(step.waitForUser, () => {
              step["beforeLeave"] ? step["beforeLeave"](this, leave.bind(this)) : leave.call(this);
            });
          } else {
            step["beforeLeave"] ? step["beforeLeave"](this, leave.bind(this)) : leave.call(this);
          }
        }
      }

      function leave() {
        next.call(this);
        afterLeave.call(this);
      }

      function afterLeave() {
        step["afterLeave"] && step["afterLeave"](this);
      }
    },

    autosave() {
      // let activeAnimations = this.$refs['animations'].activeAnimations;
      // let activeAnimationConfigs = {};
      // Object.keys(this.$refs['animations'].activeAnimations).forEach((layerName)=>{
      //   activeAnimationConfigs[layerName] = activeAnimations[layerName].config;
      // });
      //
      // let state = {
      //   'activeAnimations': activeAnimationConfigs,
      //   'activeDialogues': [...this.dialogueTexts]
      // };
      //
      // console.log(state.activeAnimations)
      //
      // this.$store.commit('setLevelState', {
      //   'levelId': this.levelConfig.id,
      //   'stepIndex': this.activeLevelStep,
      //   'state': state
      // });
      //
      // this.$store.commit('persistSavegame');
    },

    exitLevel() {
      this.levelConfig.beforeLevelLeave ? this.levelConfig.beforeLevelLeave(this, gameOver.bind(this)) : gameOver();

      function gameOver() {
        // console.log('LEVEL ENDED');
        this.$router.push({'name': 'Dashboard'});
      }
    },

    restartLevel() {
      this.$store.commit('resetLevel', this.levelConfig.id);
      this.initLevel();
    },

    waitForUser(text, callback) {
      this.showUserPromt(text, () => {
        callback();
      });
    },

    showUserPromt(text, callback) {
      this.userPromt = {
        text,
        callback
      };
    },

    onUserPromtClick() {
      this.userPromt['callback'].call(this);
      this.userPromt = null;
    },

    onTutorialEnd(){
      this.$store.commit('setTutorialDone');
      setTimeout(()=>{
        this.tutorialCallback && this.tutorialCallback();
      }, 1000);
    },

    showSummary() {
      this.clearDialogueTexts();

      Object.keys(this.levelConfig.summary['reactions']).forEach((character)=>{
        this.startAnimation(character, this.levelConfig.summary['reactions'][character], true, true, ()=>{
          if (character === 'background'){
            this.summary = this.levelConfig.summary; // this creates the levelSummary component
            this.startAudio('SFX', basicLevelAssets['sounds']['basic-level-ended'], false, false);
          }
        });
      });
    },

    startAnimation(layerName, animation, loop, endPreviousAnimation, callback) {
      if (this.$refs['animations']){
        this.$refs['animations'].startAnimation(layerName, animation, loop, endPreviousAnimation, callback);
      }
    },

    requestAnimationEnd(layerName, callback) {
      if(this.$refs['animations']){
        this.$refs['animations'].requestAnimationEnd(layerName, callback);
      }
    },

    clearAnimationLayer(layerName) {
      if (this.$refs['animations']){
        this.$refs['animations'].clearAnimationLayer(layerName);
      }
    },

    startAudio(audioId, path, loop = false, waitForPrevious = true, callback) {
      if (this.$refs['audio']){
        this.$refs['audio'].startAudio(audioId, path, loop, waitForPrevious, callback);
      }
    },

    requestAudioEnd(audioId, hard, callback) {
      if (this.$refs['audio']){
        this.$refs['audio'].requestAudioEnd(audioId, hard, callback);
      }
    },

    showDialogue(dialogue, callback = ()=>{}) {
      // console.log('LEVEL | Showing dialogue', dialogue);
      if (dialogue.animation) {
        this.startAnimation(dialogue.character, dialogue.animation, true, 'swapOnFrame', () => {
          this.showDialogueText(dialogue.character, dialogue.text);
          this.startAudio('dialogue', dialogue.audio, false, true, () => {
            this.requestAudioEnd('dialogue', false, () => {
              this.startAnimation(dialogue.character, dialogue.animationAfter, true, 'swapOnFrame', () => {
                callback();
              });
            });
          });
        });

      } else {
        this.showDialogueText(dialogue.character, dialogue.text);
        this.startAudio('dialogue', dialogue.audio, false, true, () => {
          this.requestAudioEnd('dialogue', false, () => {
            callback();
          });
        });
      }
    },

    showDialogueText(character, text) {
      this.dialogueTexts.push({
        text: text,
        character: character
      });
    },

    clearDialogueTexts() {
      // console.log('LEVEL | Clearing dialogues');
      this.dialogueTexts = [];
    },

    startQuiz(quiz, callback) {
      this.clearDialogueTexts();
      this.startAudio('dialogue', quiz.question.audio, false, false, () => {
        this.activeQuiz = {
          'config': quiz,
          'callback': callback
        };
      });
    },

    onQuizStatusChange(statusName, prevStatusName) {
      // console.log('LEVEL | Quiz status changed to:', statusName);
      let quizScore = 0;

      switch (statusName) {
        case 'start-over':
          // this.clearDialogueTexts();
          break;

        case 'correct-star':
          quizScore = 1;
          this.clearDialogueTexts();
          this.requestAudioEnd('dialogue', true);
          this.playQuizReactions(this.activeQuiz.config.reactions[statusName]);
          break;

        case 'correct':
          this.clearDialogueTexts();
          this.requestAudioEnd('dialogue', true);
          this.playQuizReactions(this.activeQuiz.config.reactions[statusName]);
          break;

        case 'wrong':
          this.playQuizReactions(this.activeQuiz.config.reactions[statusName]);
          break;
      }

      let newScore = this.$store.getters.levelScore(this.levelConfig.id).scored + quizScore;

      if (newScore > this.levelConfig.availableScore){
        newScore = this.levelConfig.availableScore; // just in case...
      }

      this.$store.commit('setLevelProgress', {
        'levelId': this.levelConfig.id,
        'status': 'started',
        'score': newScore
      });

    },

    playQuizReactions(reactions){
      let activeQuiz = this.activeQuiz;
      let callbackCount = 0;

      activeQuiz.quizEndCallback = null;

      Object.keys(reactions).forEach((character) => {
        let reaction = reactions[character];

        if (reaction.text) {
          this.showDialogueText(character, reaction.text);
        }

        if (reaction.animation) {
          callbackCount++;

          let reactionAnimationFallbackFired = false;
          let reactionAnimationFallbackTimeout = setTimeout(()=>{
            reactionAnimationFallbackFired = true;
            this.startAnimation(character, reaction.animationAfter, true, false);

            callbackCount--;
            if (callbackCount === 0) {
              // if there is a callback waiting for the reactions: use it to end the quiz
              if (activeQuiz.reactionEndCallback){
                activeQuiz.reactionEndCallback.call(this, this);
              } else {
                // reactions are done, prepare a callback for the quiz-end in case the reactions were early.
                activeQuiz.quizEndCallback = activeQuiz.callback;
              }
            }
          }, 7000);

          this.startAnimation(character, reaction.animation, 'skipLoop', true, () => {
            this.requestAnimationEnd(character, ()=>{
              if (reactionAnimationFallbackFired){ return; } // stop here if fallback applied
              this.startAnimation(character, reaction.animationAfter, true, true, () => {
                if (reactionAnimationFallbackFired){ return; } // just to make sure...
                clearTimeout(reactionAnimationFallbackTimeout); // stop fallback timeout from happening

                callbackCount--;
                if (callbackCount === 0) {
                  // if there is a callback waiting for the reactions: use it to end the quiz
                  if (activeQuiz.reactionEndCallback){
                    activeQuiz.reactionEndCallback.call(this, this);
                  } else {
                    // reactions are done, prepare a callback for the quiz-end in case the reactions were early.
                    activeQuiz.quizEndCallback = activeQuiz.callback;
                  }
                }
              });
            });
          });
        }
      });

      // there are no animations running, so we can end the quiz right now
      if (callbackCount === 0){
        // if there is a callback waiting for the reactions: use it to end the quiz
        if (activeQuiz.reactionEndCallback){
          activeQuiz.reactionEndCallback.call(this, this);
        } else {
          // reactions are done, prepare a callback for the quiz-end in case the reactions were early.
          activeQuiz.quizEndCallback = activeQuiz.callback;
        }
      }
    },

    showQuizHintDialog(){
      this.requestAudioEnd('dialogue', true);
      this.showDialogue(this.activeQuiz.config.hint.dialogue);
    },

    onQuizEnd(quizStatus){
      this.$store.commit('persistSavegame');
      this.clearDialogueTexts();
      let activeQuiz = this.activeQuiz;
      this.activeQuiz = null;

      // if there is a callback waiting for the quiz-end: use it to end the quiz
      if (activeQuiz.quizEndCallback){
        setTimeout(activeQuiz.quizEndCallback.bind(this, this), 600);
      } else {
        // quiz-end is done, prepare a callback for the reaction-end in case the quiz-end was early.
        activeQuiz.reactionEndCallback = activeQuiz.callback;
      }
    },

    startDisplay(displayConfig, callback) {
      this.clearDialogueTexts();

      let zoomAnimation = displayConfig['startAnimation'] || {
        'path': basicLevelAssets['animations']['robot-display-zoom'],
        'startFrame': 0,
        'loopStartFrame': (24 * 2),
        'loopEndFrame': (24 * 3) - 1
      }

      this.startAnimation('robot', zoomAnimation, true, false);
      this.startAudio('SFX', basicLevelAssets["sounds"]["basic-robot-display-zoom"], false, false);

      setTimeout(() => {
        this.activeDisplay = {
          'config': displayConfig,
          'callback': callback
        };
      }, 1000);

      this.requestAudioEnd('SFX', false, () => {
        if (this.$refs['audio']){
          this.$refs['audio'].muteAll();
        }
      });
    },

    onDisplayEnd() {
      if (this.$refs['audio']){
        this.$refs['audio'].unmuteAll();
      }

      let zoomAnimation = this.activeDisplay['config']['endAnimation'] || {
        'path': basicLevelAssets['animations']['robot-display-zoom'],
        'startFrame': (24 * 3),
        // 'endFrame': (24 * 6) -1
      }

      this.startAnimation('robot', zoomAnimation, false, false, () => {
        this.startAudio('SFX', basicLevelAssets["sounds"]["basic-robot-display-zoom"], false, false);
        let callback = this.activeDisplay['callback'];
        let animationAfter = this.activeDisplay['config']['animationAfter'];
        setTimeout(() => {
          this.activeDisplay = null;
        }, 1000);

        this.requestAnimationEnd('robot', () => {
          if (animationAfter){
            this.startAnimation('robot', animationAfter, true, false);
          }
          callback();
        });
      });
    },

    onBlackoutIn(){
      // console.log('LEVEL | Blackout | in', this.blackoutCallback);
      this.blackoutCallback && this.blackoutCallback();
    },

    onBlackoutOut(){
      // console.log('LEVEL | Blackout | out', this.blackoutCallback);
      this.blackoutCallback && this.blackoutCallback();
    }
  },

  created() {
    if (!this.levelConfig) {
      this.$router.push({'name': 'Dashboard'});
    } else {
      // restart also starts on first time, so...
      this.restartLevel();
    }
  },

  mounted() {
    // DEV help
    window.level = this;

    // console.log('LEVEL | MOUNTED');

    // document.body.addEventListener('click', (e)=>{
    //   if (e.ctrlKey && e.altKey && this.levelConfig){
    //     console.log('CHEATER!');
    //     let oldScore = this.$store.getters.levelScore(this.levelConfig.id).scored;
    //     let newScore = (oldScore < this.levelConfig.availableScore) ? (oldScore + 1) : 0;
    //     this.$store.commit('setLevelProgress', {
    //       'levelId': this.levelConfig.id,
    //       'status': 'started',
    //       'score': newScore
    //     });
    //   }
    // });
  },

  beforeDestroy() {
    // console.log('LEVEL | BEFORE DESTROY');
    if (this.$refs['audio']){
      this.$refs['audio'].stopAll();
    }
  },

  destroyed() {
    // console.log('LEVEL | DESTROYED');
    // TODO: make sure nothing remains, reset all Data
  }
}
</script>

<style lang="scss">
@import "src/style/variables";
@import "src/style/mixins";
@import "src/style/animations";

#level {
  position: relative;
  height: 100%;
}

#level-loader {
  z-index: 1000;
}

#status-bar {
  //position: absolute;
  z-index: 500;
}

#animations {
  @extend %strech;
  z-index: 200;
}

#dialogue-container {
  position: relative;
  z-index: 300;
}

#quiz-container {
  //position: relative;
  z-index: 400;
}

#display-container {
  //position: relative;
  z-index: 400;
}

#video-container {
  //position: relative;
  z-index: 400;
}

#level-summary {
  //position: relative;
  z-index: 400;
}

.user-promt-wrapper {
  position: absolute;
  bottom: 0;
  left:0;
  right: 0;
  top: 0;
  z-index: 300;

  #user-promt {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, 0);
    min-width: 496px;
  }
}

#blackout {
  @extend %strech;
  z-index: 450;
  background: #000;
}

</style>