<template>
  <transition name="fade" appear>
    <div class="display" v-if="!exiting">
      <div class="content-container">
<!--    <pre style="max-width: 300px; margin: 0 auto;">{{JSON.stringify(currentContent)}}</pre>-->
        <div class="content-video" v-if="currentContent.type === 'video'">
          <Video :video-config="currentContent" @ended="$set(currentContent, 'ended', true)"></Video>
        </div>
        <div class="content-image" v-if="currentContent.type === 'image'">
          <img :src="currentContent.imageSrc">
          <p class="image-caption" v-html="currentContent.caption"></p>
        </div>
        <div class="content-text" v-if="currentContent.type === 'text'" v-html="currentContent.htmlText">
        </div>
      </div>
      <div class="button-container">
        <btn variant="invert" icon="left"  v-if="hasPrevContent"  @click="showPrevContent">Zurück</btn>
        <btn variant="primary" icon="right" v-if="hasNextContent"  @click="showNextContent">Weiter</btn>
        <btn variant="invert" icon="right" v-if="!hasNextContent && currentContent.type === 'video' && !currentContent.ended" @click="exitDisplay">Video überspringen</btn>
        <btn variant="primary" icon="right" v-if="!hasNextContent && (currentContent.type !== 'video' || currentContent.ended)" @click="exitDisplay">Weiter</btn>
      </div>
    </div>
  </transition>
</template>

<script>
import btn from "@/components/interface/btn";
import Video from "@/components/Video";

export default {
  'name': 'Display',
  'components': {
    btn,
    Video
  },
  'props': {
    'content': Array
  },
  'data': ()=>{
    return {
      'currentContentIndex': 0,
      'exiting': false
    }
  },
  'computed': {
    currentContent(){
      return this.content[this.currentContentIndex];
    },
    hasNextContent(){
      return this.currentContentIndex < this.content.length-1;
    },
    hasPrevContent(){
      return this.currentContentIndex > 0;
    }
  },
  'methods': {
    showNextContent(){
      if (this.hasNextContent){
        this.currentContentIndex++;
      }
    },
    showPrevContent(){
      if (this.hasPrevContent){
        this.currentContentIndex--;
      }
    },
    exitDisplay() {
      this.exiting = true;
      this.$emit('exit');
    }
  }
}
</script>

<style lang="scss">
  @import "src/style/variables";
  @import "src/style/mixins";
  @import "src/style/icon";

  .display {
    @extend %strech;
    padding: 190px 48px 48px;

    .content-container {
      //background-color: deepskyblue;
    }

    .content-video {
      text-align: center;

      .video {
        height: 577px;
      }
    }

    .content-image {
      height: 550px;
      width: 840px;
      padding: 24px;
      border-radius: 12px;
      margin: 0 auto;
      background: #fff;
      color: $text-color-dark;

      img {
        display: block;
        width: 100%;
        height: auto;
        max-height: 400px;
        margin: 0 auto 16px auto;
      }

      .image-caption {
        font-size: 18px;
      }
    }

    .content-text {
      height: 550px;
      width: 840px;
      padding: 24px;
      margin: 0 auto;
      border-radius: 12px;
      background-color: #fff;
      color: $text-color-dark;

      h3 {
        font-family: $font-regular;
        text-transform: uppercase;
        margin-bottom: 48px;
      }

      p {
        background-color: $color-grey-light;
        padding: 16px 150px 16px 16px;
        border-radius: 12px;

        & + p {
          margin-top: 16px;
        }

        strong {
          display: block;
          margin-bottom: 8px;
        }
      }
    }

    .button-container {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 40px;
      display: flex;
      justify-content: center;

      .button {
        min-width: 496px;

        & + .button {
          margin-left: 147px;
        }
      }
    }
  }
</style>