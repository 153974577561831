<template>
  <Modal class="achievement-modal" @close="$emit('close')">
     <h2 class="achievement-modal--title">Belohnungskiste</h2>
     <p class="achievement-modal--subtitle">Hier findest du alle deine Belohnungen, die du in den fünf Leveln freischalten kannst.</p>
     <div class="achievement-modal--list">
       <div class="achievement-modal--list-element" v-for="(achievement, achievementKey) in achievements" :key="achievementKey">
         <Achievement :config="achievement" theme="white-bg"></Achievement>
         <div class="achievement-name-title">
           <p class="level-name" v-html="achievement.levelName"></p>
           <p class="achievement-title" v-html="(achievement.achieved ? achievement.title : '???')"></p>
         </div>
         <p class="achievement-description" v-html="(achievement.achieved ? achievement.description : 'Sammle alle Sterne des Levels, um die Belohnung freizuschalten.')"></p>
       </div>
       <div class="achievement-modal--list-element">
         <Achievement :config="{'icon': 'award', 'achieved': awardAchieved}" theme="white-bg"></Achievement>
         <div class="achievement-name-title">
           <p class="achievement-title" v-html="(awardAchieved ? 'Wasserhelden-<br>Auszeichnung' : '???')"></p>
         </div>
         <p class="achievement-description" v-html="(awardAchieved ? 'Du hast alle 5 Level erfolgreich durchgespielt! Dafür erhältst du eine offizielle Auszeichnung.' : 'Beende alle 5 Level des Spiels, um die Belohnung freizuschalten.')"></p>
       </div>
     </div>
    <btn  v-if="awardAchieved"
          @click="downloadAward"
          variant="primary"
          icon="download">
      Urkunde herunterladen
    </btn>
  </Modal>
</template>

<script>
import Btn from "@/components/interface/btn"
import Modal from "@/components/Modal";
import Achievement from "@/components/Achievement";

export default {
  'name': 'AchievementModal',
  'components': {
    Btn,
    Modal,
    Achievement
  },
  'computed': {
    achievements() {
      return this.$store.getters.achievements;
    },
    awardAchieved(){
      return this.$store.getters.allLevelsFinished;
    }
  },
  'methods': {
    downloadAward(){
      let link = document.createElement("a");
      link.setAttribute("href", "/pdf/N-ERGIE-Wasserhelden-Auszeichnung.pdf");
      link.setAttribute("download", "/pdf/N-ERGIE-Wasserhelden-Auszeichnung.pdf");
      link.click();
    }
  }
}
</script>

<style lang="scss">
@import "src/style/variables";
@import "src/style/mixins";

.achievement-modal .modal-dialog{
  width: 869px;
  text-align: center;

  .achievement-modal--title {
    font-size: 35px;
    font-family: $font-bold;
    margin-bottom: 20px;
  }

  .achievement-modal--subtitle {
    font-family: $font-bold;
    margin-bottom: 38px;
  }

  .button {
    display: block;
    margin: 0 auto;
  }
}

.achievement-modal--list-element {
  display: flex;
  padding: 12px 0 12px 0;
  text-align: left;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid $color-grey-light;
  }

  .achievement {
    flex: 0 0 60px;
  }

  .achievement-name-title {
    flex: 0 0 168px;
    margin-left: 60px;

    .level-name {
      margin-bottom: 12px;
    }

    .achievement-title {
      font-size: 18px;
      font-family: $font-bold;
    }
  }

  .achievement-description {
    flex: 1 0 0;
    font-size: 12px;
    margin-left: 60px;
  }
}

</style>