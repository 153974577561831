export default {
  'goggles': {
    'title': 'Taucherbrille',
    'icon': 'goggles',
    'levelName': 'Level 1',
    'description': 'Du hast den wilden Tauchgang durch die Wasserrohre und die Tests im Wasserwerk mit Bravur bestanden.',
    'animation': {
      'path': '/animations/achievements/Taucherbrille/Taucherbrille.json',
      'startFrame': 0,
      'loopStartFrame': (24 * 2) -1
    }
  },
  'atom': {
    'title': 'Forscher-Abzeichen',
    'icon': 'atom',
    'levelName': 'Level 2',
    'description': 'Du hast dich in Experimenten und Quiz-Fragen als herausragender Wissenschaftler bewiesen.',
    'animation': {
      'path': '/animations/achievements/Atom/Atom.json',
      'startFrame': 0,
      'loopStartFrame': (24 * 2) -1
    }
  },
  'backpack': {
    'title': 'Wanderrucksack',
    'icon': 'backpack',
    'levelName': 'Level 3',
    'description': 'Du hast den Aufstieg zum Berg bei jedem Wetter gemeistert.',
    'animation': {
      'path': '/animations/achievements/Rucksack/Rucksack.json',
      'startFrame': 0,
      'loopStartFrame': (24 * 2) -1
    }
  },
  'globe': {
    'title': 'Globus',
    'icon': 'globe',
    'levelName': 'Level 4',
    'description': 'Als waschechter Astronaut und Wasserexperte hast du dir diese Belohnung wirklich verdient.',
    'animation': {
      'path': '/animations/achievements/Erde/Erde.json',
      'startFrame': 0,
      'loopStartFrame': (24 * 2) -1
    }
  },
  'trophy': {
    'title': 'Wasser-Pokal',
    'icon': 'trophy',
    'levelName': 'Level 5',
    'description': 'Als richtiger Profi im Wasser sparen und für den perfekten Abschluss gehört der goldene Pokal natürlich dir.',
    'animation': {
      'path': '/animations/achievements/Pokal/Pokal.json',
      'startFrame': 0,
      'loopStartFrame': (24 * 2) -1
    }
  }
}