<template>
  <div id="dashboard" @click="onUserInteraction" @keydown="onUserInteraction">
    <Audio ref="audio"></Audio>
    <div class="dashboard-slide-backdrop" v-if="(dash === 7 ||dash === 8)">
      <transition name="slider" mode="out-in" appear>
        <template v-for="(levelConfig, index) in allLevelConfigs">
          <div v-if="index === activeSlide" :key="levelConfig.id" class="slide">
            <img :src="levelConfig.levelSliderBackground" alt="">
          </div>
        </template>
      </transition>
    </div>
    <Animation :animation-layers="['background', 'layer-1', 'layer-2']" ref="animation"></Animation>
    <div class="dashboard-slides">
      <logo class="logo"></logo>
<!--      <btn v-if="dash === 1" class="info" @click="openInfo" variant="primary-round" :icon="'info'">&nbsp;</btn>-->
<!--      <div v-if="info === true">-->
<!--        <div class="info-wrapper">-->
<!--          <div class="info-content">-->
<!--            <btn class="closing-tag" @click="closeInfo" variant="blank-round" :icon="'wrong-red'">&nbsp;</btn>-->
<!--            <p class="headline">-->
<!--              Über &bdquo;Hallo, Wasserwissen!&rdquo;-->
<!--            </p>-->
<!--            <p class="info-text">-->
<!--              Trinkwasser ist das Lebensmittel Nummer 1! Tagtäglich kommt es in unserem Leben zum Einsatz.-->
<!--              Wir benutzen es zum Trinken und Kochen, aber auch zum Waschen und Reinigen.-->
<!--              Deshalb ist es so wichtig, unser Trinkwasser zu schützen und verantwortungsbewusst einzusetzen.-->
<!--              Wo kommt das Wasser her? Wie kommt es in unseren Wasserhahn? Wieviel Wasser benötigen wir im Alltag?-->
<!--              Mit diesen und weiteren Fragen wollen wir Kindern spielerisch ein Bewusstsein für Trinkwasser vermitteln.-->
<!--              <br><br>-->
<!--              Neben unseren angebotenen Führungen durch das Wasserwerk soll dieses Spiel eine digitale Ergänzung sein,-->
<!--              um das Wasserwissen zu vertiefen – ob zu Hause oder in der Schule.-->
<!--              Als Wasserversorger sehen wird dies als Teil unserer gesellschaftlichen Verantwortung.-->
<!--              <br><br>-->
<!--              Wir wünschen viel Spaß und Erfolg beim Spielen.-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <transition name="dash" mode="out-in">
        <div id="dash1" :key="1" v-if="dash === 1">
          <div class="headline-container">
            <p class="title-welcome">Hallo,</p>
            <p class="title-player">Wasser-wissen!</p>
            <p class="title-sub">Mit den Wasserhelden und Roboterin Sarah unterwegs auf Abenteuern.</p>
          </div>
          <div class="button-area">
            <div v-if="selectedPlayer === null" class="first-game">
              <p class="button-pre-title">Spiel am besten mit eingeschaltenem Ton.</p>
              <btn @click="stepForward"
                   class="dashboard-button"
                   :icon="'right'">
                Spiel starten
              </btn>
            </div>
            <div v-if="selectedPlayer !== null" class="continue">
              <btn @click="stepToLevelSelection"
                   :icon="'right'">
                Weiterspielen
              </btn>
              <btn @click="showNewGameModal = true"
                   variant="invert"
                   class="button-space"
                   :icon="'reload'">
                Neues Spiel starten
              </btn>
            </div>
          </div>
        </div>

        <div id="dash2" :key="2" v-if="dash === 2">
          <div class="description-box-center">
            <h2 class="headline-center">
              Über &bdquo;Hallo, Wasserwissen!&rdquo;
            </h2>
            <p class="info-text">
              <br>
              Trinkwasser ist das Lebensmittel Nummer 1! Tagtäglich kommt es in unserem Leben zum Einsatz.
              Wir benutzen es zum Trinken und Kochen, aber auch zum Waschen und Reinigen.
              Deshalb ist es so wichtig, unser Trinkwasser zu schützen und verantwortungsbewusst einzusetzen.
              Wo kommt das Wasser her? Wie kommt es in unseren Wasserhahn? Wieviel Wasser benötigen wir im Alltag?
              Mit diesen und weiteren Fragen wollen wir Kindern spielerisch ein Bewusstsein für Trinkwasser vermitteln.
              <br><br>
              Neben unseren angebotenen Führungen durch das Wasserwerk soll dieses Spiel eine digitale Ergänzung sein,
              um das Wasserwissen zu vertiefen – ob zu Hause oder in der Schule.
              Als Wasserversorger sehen wird dies als Teil unserer gesellschaftlichen Verantwortung.
              <br><br>
              Wir wünschen viel Spaß und Erfolg beim Spielen.
            </p>
          </div>
          <div class="btn-container">
            <btn @click="stepBack" :icon="'left'" variant="invert" class="char-btn-position"> Zurück</btn>
            <btn @click="stepForward" :icon="'right'" class="char-btn-position"> Weiter</btn>
          </div>
        </div>

        <div id="dash3" :key="3" v-if="dash === 3">
          <div class="description-box">
            <p>WIR STELLEN VOR :</p>
            <p class="char-name">Ro-Sa</p>
            <table class="char-table">
              <tr>
                <td><b>Name</b></td>
                <td class="table-space">Ro-Sa (Roboterin Sarah)</td>
              </tr>
              <tr>
                <td><b>Alter</b></td>
                <td class="table-space">545.827 Stunden und 12 Minuten</td>
              </tr>
              <tr>
                <td><b>Funktionen</b></td>
                <td class="table-space">Navigationssystem, Schrumpf-Strahl, Internetzugang, Simulator, Video-Bildschirm,
                  Solarzellen
                </td>
              </tr>
            </table>
            <p class="char-text">
              Ro-Sa ist eine superschlaue Roboter-Dame und weiß so ziemlich alles über die Welt.
              Sie wurde programmiert, um den Menschen zu helfen ihre Umwelt besser zu verstehen und sorgfältig mit ihr
              umzugehen.
            </p>
          </div>
          <div class="btn-container">
            <btn @click="stepBack" :icon="'left'" variant="invert" class="char-btn-position"> Zurück</btn>
            <btn @click="stepForward" :icon="'right'" class="char-btn-position"> Weiter</btn>
          </div>
        </div>

        <div id="dash4" :key="4" v-if="dash === 4">
          <div class="description-box">
            <p>WIR STELLEN VOR :</p>
            <p class="char-name">NICK</p>
            <table class="char-table">
              <tr>
                <td><b>Hobbys</b></td>
                <td class="table-space">E-Trike und Kanu fahren</td>
              </tr>
              <tr>
                <td><b>Lieblingsessen</b></td>
                <td class="table-space">Spaghetti mit Tomatensoße</td>
              </tr>
              <tr>
                <td><b>Eigenschaften</b></td>
                <td class="table-space">Forschertrieb, Neugierde, Ausdauer, <br> Spaß am Lernen, Gamer
                </td>
              </tr>
            </table>
            <p class="char-text">
              Nick will immer mehr wissen und verstehen, als er in der Schule lernt. Wenn er nicht mit seinen Freunden,
              Ro-Sa oder seiner Schwester Nici auf Abenteuersuche ist, liest er gerne und experimentiert ,
              um zu lernen, wie Dinge funktionieren.
            </p>
          </div>
          <div class="btn-container">
            <btn @click="stepBack" :icon="'left'" variant="invert" class="char-btn-position"> Zurück</btn>
            <btn @click="stepForward" :icon="'right'" class="char-btn-position"> Weiter</btn>
          </div>
        </div>

        <div id="dash5" :key="5" v-if="dash === 5">
          <div class="description-box">
            <p>WIR STELLEN VOR :</p>
            <p class="char-name">NICI</p>
            <table class="char-table">
              <tr>
                <td><b>Hobbys</b></td>
                <td class="table-space">Handball und Surfen</td>
              </tr>
              <tr>
                <td><b>Lieblingsessen</b></td>
                <td class="table-space">Pizza</td>
              </tr>
              <tr>
                <td><b>Eigenschaften</b></td>
                <td class="table-space">Clever, Hobby-Detektivin, Wissenschaftlerin,<br> gute Beobachterin, Sportlerin
                </td>
              </tr>
            </table>
            <p class="char-text">
              Nici lüftet immer jedes Geheimnis und löst jedes Rätsel, dass sich ihr stellt. Sie ist ein echter
              Technik-Nerd und baut gerne alle möglichen Geräte auseinander, um zu lernen, wie sie funktionieren
              <br><br>
              Auch dem Roboter Ro-Sa hat sie zusammen mit ihrem Bruder Nick schon so einige Extra-Funktionen eingebaut.
            </p>
          </div>
          <div class="btn-container">
            <btn @click="stepBack" :icon="'left'" variant="invert" class="char-btn-position"> Zurück</btn>
            <btn @click="stepForward" :icon="'right'" class="char-btn-position"> Weiter</btn>
          </div>
        </div>

        <div id="dash6" :key="6" v-if="dash === 6">
          <div class="player-headline">
            <p>Mit wem möchtest du das Abenteuer erleben?</p>
          </div>
          <div class="player-selection">
            <div class="back">
              <btn @click="stepBack" :icon="'left'" variant="invert-round">&nbsp;</btn>
            </div>
            <div class="player male">
              <btn @click="selectPLayerMale" :icon="'right'"> Mit Nick spielen</btn>
            </div>
            <div class="player female">
              <btn @click="selectPLayerFemale" :icon="'right'"> Mit Nici spielen</btn>
            </div>
          </div>
        </div>

        <div id="dash7" :key="7" v-if="(dash === 7 || dash === 8) && isInCockpit">
          <div class="level-slider">
            <Statusbar :theme="'reduced'"></Statusbar>
            <transition name="slider" mode="out-in">
              <template v-for="(levelConfig, index) in allLevelConfigs">
                <div v-if="index === activeSlide" :key="levelConfig.id" class="slide">
                  <div class="content-wrapper">
                    <div class="level-number small">
                      {{ levelConfig.title }}
                    </div>
                    <div class="level-name">
                      {{ levelConfig.subtitle }}
                    </div>
                    <div class="level-description small">
                      {{ levelConfig.description }}
                    </div>
                    <div class="level-stars">
                      <Rating theme="dashboard" :count="levelConfig.scoredScore"
                              :total="levelConfig.availableScore"></Rating>
                    </div>
                    <div class="level-status" v-if="levelConfig.textStatus">
                      {{ levelConfig.textStatus }}
                    </div>
                  </div>
                </div>
              </template>
            </transition>

          </div>
          <div class="slider-arrows">
            <btn class="arrow left" variant="primary-round" :icon="'left'" v-if="activeSlide > 0" @click="slideBack">
              &nbsp;
            </btn>
            <btn class="arrow right" variant="primary-round" :icon="'right'"
                 v-if="allLevelConfigs.length-1 > activeSlide" @click="slideForward">&nbsp;
            </btn>
          </div>
          <btn class="level-button" @click="requestLevel" :icon="'right'"> Level Starten</btn>
        </div>
      </transition>
    </div>
    <Modal v-if="showNewGameModal" @close="showNewGameModal = false">
      <div class="new-game-modal-content">
        <h2>Achtung</h2>
        <p>Wenn du ein neues Spiel startest, werden deine bisherigen Fortschritte gelöscht. Möchtest du wirklich ein
          ganz neues Spiel starten?</p>
        <btn @click="startNewGame" variant="invert-red" class="button-space" icon="reload-red">Ja, neues Spiel starten
        </btn>
        <btn @click="showNewGameModal = false" variant="primary" class="button-space" icon="right">Nein, fortsetzen
        </btn>
      </div>
    </Modal>
    <Modal id="lucky-modal" v-if="showLuckyModal" @close="showLuckyModal = false">
      <h2>Gewinnspiel</h2>
      <p><strong>Bis zum 30. April 2021 könnt Ihr eine tolle Trinkwasserflasche gewinnen!</strong></p>
      <p>
        Zur Einführung der WebApp „Hallo, Wasserwissen!“ verlosen wir 300 hochwertige Thermo-Trinkwasserflaschen für deine Abenteuer. Teilnehmen kannst du ganz einfach: Spiele die WebApp bis zum Ende, dann erhältst du eine Urkunde. Danach kannst du dich für das Gewinnspiel registrieren.
        <br>
        Viel Glück!
      </p>
    </Modal>
  </div>
</template>

<script>
import basicAssets from "@/assets/basicAssets";
import btn from "../components/interface/btn";
import logo from "@/components/interface/logo";
import Rating from "@/components/Rating";
import Animation from "@/components/Animation";
import Audio from "@/components/Audio";
import Statusbar from "@/components/Statusbar";
import Modal from "@/components/Modal";

export default {
  name: 'Dashboard',

  components: {
    Audio,
    logo,
    btn,
    Rating,
    Animation,
    Statusbar,
    Modal
  },

  props: {},

  data: () => {
    return {
      dash: 1,
      activeSlide: 0,
      isInCockpit: false,
      backgroundMusicPlaying: false,
      info: false,
      menu: false,
      showNewGameModal: false,
      showLuckyModal: false
    }
  },

  computed: {
    selectedPlayer() {
      return this.$store.getters['selectedPlayer'];
    },

    allLevelConfigs() {
      let configs = [];

      Object.values(this.$store.getters.availableLevels).forEach((levelConfig) => {
        levelConfig = levelConfig();
        levelConfig.scoredScore = this.$store.getters.levelScore(levelConfig.id).scored;


        if (this.$store.getters.levelProgress(levelConfig.id).status === 'started') {
          levelConfig.textStatus = 'Dieses Level hast du noch nicht beendet.';
        }

        if (this.$store.getters.levelProgress(levelConfig.id).status === 'finished') {
          levelConfig.textStatus = 'Spiel das Level jetzt nochmal, um alle Sterne zu sammeln!';

          if (levelConfig.scoredScore === levelConfig.availableScore) {
            levelConfig.textStatus = 'Glückwunsch! Du hast alle Sterne dieses Levels gesammelt.'
          }
        }

        configs.push(levelConfig);
      });
      return configs;
    },
  },


  methods: {

    openMenu() {
      this.menu = true;
    },

    closeMenu() {
      this.menu = false;
    },

    closeInfo() {
      this.info = false;
    },

    openInfo() {
      this.info = true;
    },

    requestLevel() {
      this.$emit("request-level", this.allLevelConfigs[this.activeSlide].id);
    },

    slideForward() {
      this.activeSlide++;
    },

    slideBack() {
      this.activeSlide--;
    },

    startNewGame() {
      this.showNewGameModal = false;
      this.$store.commit('resetGame');
      this.$store.commit('persistSavegame');
      this.stepForward(2);
    },

    stepToLevelSelection() {
      this.stepForward(7);
    },

    stepBack(dash) {

      if (dash) {
        this.dash = dash;
      } else {
        this.dash -= 1;
      }

      switch (this.dash) {
        case 1:
          if (this.$refs['animation']){
            this.$refs['animation'].clearAnimationLayer('layer-1');
          }
          break;

        case 2:
          if (this.$refs['animation']){
            this.$refs['animation'].clearAnimationLayer('layer-1');
          }
          break;

        case 3:
          if(this.$refs['animation']) {
            this.$refs['animation'].startAnimation('layer-1', {
              'path': basicAssets['animations']['dashboard-animation-steckbrief-rosa']
            }, true, false);
            this.$refs['animation'].clearAnimationLayer('layer-1');
          }
          break;

        case 4:
          if (this.$refs['animation']) {
            this.$refs['animation'].startAnimation('layer-1', {
              'path': basicAssets['animations']['dashboard-animation-steckbrief-nick']
            }, true, false);
          }
          break;

        case 5:
          if(this.$refs['animation']) {
            this.$refs['animation'].startAnimation('layer-1', {
              'path': basicAssets['animations']['dashboard-animation-steckbrief-nici']
            }, true, false);
            this.$refs['animation'].clearAnimationLayer('layer-2');
          }
          break;

        case 6:
          if(this.$refs['animation']) {
            this.$refs['animation'].startAnimation('layer-1', {
              'path': basicAssets['animations']['dashboard-animation-char-auswahl-nici']
            }, true, false);
            this.$refs['animation'].startAnimation('layer-2', {
              'path': basicAssets['animations']['dashboard-animation-char-auswahl-nick']
            }, true, false);
          }
          break;

        case 7:
          if (this.$refs['animation']){

            if (this.$refs['animation'].getActiveAnimation('layer-1')) {
              this.$refs['animation'].startAnimation('layer-1', {
                'path': basicAssets['animations']['dashboard-animation-background-schwenk-nici']
              }, false, false);
            }

            if (this.$refs['animation'].getActiveAnimation('layer-2')) {
              this.$refs['animation'].startAnimation('layer-2', {
                'path': basicAssets['animations']['dashboard-animation-background-schwenk-nick']
              }, false, false);
            }

            this.$refs['animation'].startAnimation('background', {
              'path': {
                'player-nici': basicAssets['animations']['dashboard-animation-background-cockpit-nici'],
                'player-nick': basicAssets['animations']['dashboard-animation-background-cockpit-nick']
              }[this.selectedPlayer]
            }, false, false, () => {
              this.$refs['animation'].requestAnimationEnd('background', () => {
                this.$refs['animation'].startAnimation('background', {
                  'path': {
                    'player-nici': basicAssets['animations']['dashboard-animation-cockpit-nici'],
                    'player-nick': basicAssets['animations']['dashboard-animation-cockpit-nick']
                  }[this.selectedPlayer]
                }, true, true);
                this.isInCockpit = true;
              });
            });

          }
          break;

        case 8:
          if (this.$refs['animation']) {
            this.$refs['animation'].startAnimation('background', {
              'path': {
                'player-nici': basicAssets['animations']['dashboard-animation-cockpit-nici'],
                'player-nick': basicAssets['animations']['dashboard-animation-cockpit-nick']
              }[this.selectedPlayer]
            }, true, true);
            this.isInCockpit = true;
          }
          break;
      }
    },

    stepForward(dash) {

      if (dash) {
        this.dash = dash;
      } else {
        this.dash += 1;
      }

      switch (this.dash) {
        case 3:
          if (this.$refs['animation']) {
            this.$refs['animation'].startAnimation('layer-1', {
              'path': basicAssets['animations']['dashboard-animation-steckbrief-rosa']
            }, true, false);
          }
          break;

        case 4:
          if (this.$refs['animation']) {
            this.$refs['animation'].startAnimation('layer-1', {
              'path': basicAssets['animations']['dashboard-animation-steckbrief-nick']
            }, true, false);
          }
          break;

        case 5:
          if (this.$refs['animation']) {
            this.$refs['animation'].startAnimation('layer-1', {
              'path': basicAssets['animations']['dashboard-animation-steckbrief-nici']
            }, true, false);
          }
          break;

        case 6:
          if (this.$refs['animation']) {
            this.$refs['animation'].startAnimation('layer-1', {
              'path': basicAssets['animations']['dashboard-animation-char-auswahl-nici']
            }, true, false);
            this.$refs['animation'].startAnimation('layer-2', {
              'path': basicAssets['animations']['dashboard-animation-char-auswahl-nick']
            }, true, false);
          }
          break;

        case 7:
          if (this.$refs['animation']) {
            if (this.$refs['animation'].getActiveAnimation('layer-1')) {
              this.$refs['animation'].startAnimation('layer-1', {
                'path': basicAssets['animations']['dashboard-animation-background-schwenk-nici']
              }, false, false);
            }

            if (this.$refs['animation'].getActiveAnimation('layer-2')) {
              this.$refs['animation'].startAnimation('layer-2', {
                'path': basicAssets['animations']['dashboard-animation-background-schwenk-nick']
              }, false, false);
            }

            this.$refs['animation'].startAnimation('background', {
              'path': {
                'player-nici': basicAssets['animations']['dashboard-animation-background-cockpit-nici'],
                'player-nick': basicAssets['animations']['dashboard-animation-background-cockpit-nick']
              }[this.selectedPlayer]
            }, false, false, () => {
              this.$refs['animation'].requestAnimationEnd('background', () => {
                this.$refs['animation'].startAnimation('background', {
                  'path': {
                    'player-nici': basicAssets['animations']['dashboard-animation-cockpit-nici'],
                    'player-nick': basicAssets['animations']['dashboard-animation-cockpit-nick']
                  }[this.selectedPlayer]
                }, true, true);
                this.isInCockpit = true;
              });
            });
          }
          break;

        case 8:
          if (this.$refs['animation']) {
            this.$refs['animation'].startAnimation('background', {
              'path': {
                'player-nici': basicAssets['animations']['dashboard-animation-cockpit-nici'],
                'player-nick': basicAssets['animations']['dashboard-animation-cockpit-nick']
              }[this.selectedPlayer]
            }, true, true);
            this.isInCockpit = true;
          }
          break;
      }
    },
    selectPLayerMale() {
      this.$store.commit('selectPlayer', 'player-nick');
      this.stepForward()
    },
    selectPLayerFemale() {
      this.$store.commit('selectPlayer', 'player-nici');
      this.stepForward()
    },
    startBackgroundMusic() {
      if (window.userInteracted && !this.backgroundMusicPlaying) {
        if (this.$refs['audio']){
          this.$refs['audio'].startAudio('background', basicAssets['sounds']['basic-menu-music'], true);
          this.backgroundMusicPlaying = true;
        }
      }
    },
    onUserInteraction() {
      window.userInteracted = true;
      this.startBackgroundMusic();
    }
  },

  mounted() {
    this.activeSlide = this.$store.getters.firstUnfinishedLevelIndex;

    if (typeof (this.$route.query['levelselect']) !== 'undefined') {

      if (this.selectedPlayer) {
        this.activeSlide = parseInt(this.$route.query['levelselect']);
        this.stepForward(8);
      } else {
        this.$router.push({'name': 'Dashboard'});
        location.reload();
      }

    } else {
      let animation = {
        'path': basicAssets['animations']['dashboard-animation-background-cockpit-nici'],
        'loopStartFrame': 0,
        'loopEndFrame': 2,
      }
      if (this.$refs['animation']){
        this.$refs['animation'].startAnimation('background', animation, true, false);
      }
    }

    this.startBackgroundMusic();
  },

  beforeDestroy() {
    if (this.$refs['audio']){
      this.$refs['audio'].requestAudioEnd('background', true);
    }
  }
}
</script>

<style lang="scss">
@import "src/style/variables";
@import "src/style/mixins";
@import "src/style/animations";

.dash-enter-active,
.dash-leave-active {
  transition: opacity $transition-duration;
}

.dash-enter,
.dash-leave-to {
  opacity: 0;
}

.slider-enter-active,
.slider-leave-active {
  transition: opacity $transition-duration;

}

.slider-enter,
.slider-leave-to {
  opacity: 0;
}

#dashboard {
  background-color: #0187c1;
  text-align: center;
  position: relative;
  height: 100%;
  width: 100%;

  .dashboard-slide-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

  }

  .dashboard-slides {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 50;

    .info {
      position: absolute;
      bottom: 50px;
      right: 50px;
      cursor: pointer;
      z-index: 99;

      .icon {
        right: 15px;
      }
    }

    .info-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 999;

      .info-content {
        position: relative;
        left: 0;
        right: 0;
        margin: 50px auto 0 auto;
        width: 750px;
        padding: 100px;
        border-radius: 12px;
        background-color: #FFFFFF;

        .closing-tag {
          position: absolute;
          z-index: 1000;
          display: block;
          top: 30px;
          right: 30px;
        }

        .info-text {
          color: $color-blue-dark;
          font-size: 18px;
          letter-spacing: 0;

          .font-bold {
            font-family: $font-bold;
          }

        }

        .headline {
          color: $color-blue-dark;
          font-size: 35px;
          font-family: $font-bold;
          letter-spacing: 0;
          text-align: center;
          margin-bottom: 24px;
        }
      }
    }

    .logo {
      position: absolute;
      top: 20px;
      left: 0;
      right: 0;
    }

    #dash1 {

      .headline-container {
        width: 400px;
        margin: 40px auto 0 auto;

        .title-welcome {
          margin-top: 150px;
          font-size: 34px;
          line-height: 1;
          margin-bottom: 20px;
        }

        .title-player {
          font-size: 72px;
          line-height: 1;
          font-family: $font-bold;
        }

        .title-sub {
          margin-top: 20px;
          font-size: 18px;
        }
      }

      .button-area {
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;
        text-align: center;
        display: flex;
        flex-direction: column;

        .button-pre-title {
          font-size: 18px;
          margin-bottom: 16px;
        }

        .continue {
          display: flex;
          flex-direction: column;
          margin: 0 auto;

          .button-space {
            margin-top: 32px;
          }
        }
      }
    }


    #dash2, #dash3, #dash4, #dash5 {


      .description-box {
        padding: 45px;
        width: 664px;
        text-align: left;
        font-size: 16px;
        background-color: #ffffff;
        color: $color-blue-dark;
        border-radius: 12px;
        margin: 170px 0 0 120px;

        .char-name {
          margin: 15px 0;
          font-size: 45px;
          font-family: $font-bold;
        }

        .char-table {
          .table-space {
            padding-left: 20px;
          }
        }

        .char-text {
          margin-top: 20px;
        }
      }

      .description-box-center{
        margin: 170px 0 0 380px;
        padding: 45px;
        width: 664px;

        font-size: 16px;
        background-color: #ffffff;
        color: $color-blue-dark;
        border-radius: 12px;
        .info-text{
          text-align: left;
        }
      }

      .btn-container {
        position: absolute;
        bottom: 48px;
        margin: 0 auto;
        left: 0;
        right: 0;

        .char-btn-position {
          margin: 0 100px;
        }
      }
    }

    #dash6 {
      .player-headline {
        position: absolute;
        top: 220px;
        left: 0;
        right: 0;
        font-size: 35px;
        text-align: center;
        width: 420px;
        margin: 0 auto;
      }

      .player-selection {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 48px;
        display: flex;
        justify-content: center;

        .player {
          margin: 0 75px;
        }

        .back {
          position: absolute;
          bottom: -10px;
          left: 40px;
        }
      }

    }

    #dash7 {
      .level-button {
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }


      .level-slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .status-bar {
          .overall-score {
            text-align: left;
          }
        }

        .level-background {
          position: relative;
          width: 100%;
          height: 100%;
          z-index: -99;
        }

        .content-wrapper {
          padding-top: 250px;

          .level-number {
            text-transform: uppercase;
          }

          .level-name {
            margin: 16px 0 16px 0;
            font-size: 35px;
            font-family: $font-bold;
            letter-spacing: 1.86px;
          }

          .level-status {
            font-size: 13px;
            margin-top: 80px;
          }

          .small {
            font-size: 21px;
          }

          .level-stars {
            display: flex;
            justify-content: center;
            margin-top: 14px;

            .stars {
              margin: 0 10px;
            }
          }
        }
      }

      .slider-arrows {
        width: 100%;

        .arrow {
          position: absolute;
          top: 340px;
          bottom: 0;
        }

        .right {
          right: 0;
          left: 1000px;
        }

        .left {
          right: 0;
          left: 375px;
        }
      }

    }
  }

  .new-game-modal-content {
    h2 {
      font-family: $font-bold;
      font-size: 35px;
      margin-bottom: 42px;
    }

    p {
      font-family: $font-bold;
      margin-bottom: 60px;
    }

    .button {
      min-width: 500px;

      & + .button {
        margin-top: 32px;
      }
    }
  }

  @keyframes lucky-slide {
    from {
      transform: translate(100%, 0);
    }
    to {
      transform: translate(0, 0);
    }
  }

  #lucky-modal .modal-dialog {
    font-size: 18px;
    text-align: left;
    padding-bottom: 120px;

    h2 {
      font-size: 35px;
      text-align: center;
      margin-bottom: 40px;
    }

    p + p {
      margin-top: 40px;
    }
  }
}
</style>