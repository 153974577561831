import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard'
import Level from '../views/Level'
import Summary from '../views/GameSummary'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/level',
    name: 'Level',
    component: Level
  },
  {
    path: '/summary',
    name: 'Summary',
    component: Summary
  }
]

const router = new VueRouter({
  routes
})

export default router
