<template>
  <div id="game-summary">
    <Statusbar id="status-bar"
               :show-score="false"
               theme="reduced">
    </Statusbar>
    <Animation id="achievement-animation" ref="achievement-animation" :animation-layers="['achievement']"></Animation>
    <div class="summary-achievement-title">Wasserhelden-Auszeichung</div>
    <div class="summary-content">
      <h3>Herzlichen Glückwunsch!</h3>
      <h2>Du hast &bdquo;Hallo, Wasserwissen!&rdquo; <br> bis zum Ende gespielt.</h2>
      <p>Mit unseren Helden hast du viele Abenteuer erlebt, eine Menge gelernt und dich in allen
        Quizfragen super geschlagen. </p>
      <p>Dafür hast du eine persönliche Auszeichnung verdient, die du hier auf der Seite
      herunterladen kannst.</p>
      <p>Gerne kannst du jedes Level noch einmal spielen, um alle Sterne und Belohnungen des
        Spiels zu sammeln. Wir wünschen dir viel Spaß dabei!</p>
    </div>
    <div class="game-summary_buttons">
      <Btn variant="invert" icon="left" @click="toDashboard">Zurück zum Start</Btn>
      <Btn icon="right" @click="downloadAward">Auszeichnung herunterladen</Btn>
    </div>
  </div>
</template>

<script>
import Btn from "@/components/interface/btn"
import Statusbar from "@/components/Statusbar"
import Animation from "@/components/Animation"
import basicAssets from "@/assets/basicAssets";

export default {
  name: 'Summary',
  components: {
    Btn,
    Statusbar,
    Animation
  },
  props: {
    'levelConfig': Object
  },
  data: () => {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    downloadAward() {
      let link = document.createElement("a");
      link.setAttribute("href", "/pdf/N-ERGIE-Wasserhelden-Auszeichnung.pdf");
      link.setAttribute("download", "/pdf/N-ERGIE-Wasserhelden-Auszeichnung.pdf");
      link.click();
    },
    toDashboard(){
      this.$router.push({'name': 'Dashboard'});
    }
  },

  created() {
    if (!this.$store.getters.allLevelsFinished) {
      this.$router.push({'name': 'Dashboard'});
    }
  },

  mounted() {
    if (this.$refs['achievement-animation']) {
      this.$refs['achievement-animation'].startAnimation('achievement', {
        'path': basicAssets['animations']['game-achievement'],
        'startFrame': 0,
        'loopStartFrame': (24 * 2) - 1
      }, true, false);
    }
  },

  beforeDestroy() {
    // this.$refs['audio'].stopAll();
  }
}
</script>

<style lang="scss">
@import "src/style/variables";
@import "src/style/mixins";
@import "src/style/animations";

#game-summary {
  @extend %strech;
  padding: 170px 150px 0 150px;
  background-color: $color-blue;

  .summary-content {
    margin-top: 84px;
    width: 555px;

    h3 {
      font-family: $font-regular;
      font-size: 18px;
      text-transform: uppercase;
    }

    h2 {
      font-size: 35px;
      margin: 28px 0;
    }

    p + p {
      margin-top: 60px;
    }
  }

  .summary-achievement-title {
    text-transform: uppercase;
    font-family: $font-bold;
    font-size: 24px;
    position: absolute;
    right: 210px;
    bottom: 280px;
  }

  .game-summary_buttons {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;

    > * + * {
      margin-left: 150px;
    }

    .button {
      min-width: 496px;
    }
  }
}

</style>