import levelAchievements from "@/levels/levelAchievements";
import basicLevelAssets from "@/assets/basicLevelAssets";

let baseConfig = {
  'id': 'Level_5',
  'title': 'Level 5',
  'subtitle': 'Wasser schützen',
  'description': 'Nachhaltiger Umgang',
  'levelSliderBackground': basicLevelAssets['images']['levelSliderBackground-level_5'],
  'availableScore': 4,
  'achievement': levelAchievements['trophy'],
  'nextLevelId': 'END_OF_GAME'
};

export const config = baseConfig;

export default (selectedPlayer = 'player-nici') => {

  let sounds = {
    'ambient-music': '/sounds/Level_5/5-0-Backgroundmusic-Landra\'s Dream.mp3',
    'ambient-bird': '/sounds/Level_1/1-1-Bird-ambience__straget__house-sparrow.mp3',
    'ambient-street': '/sounds/Level_1/1-1-Street-Ambience396667__dbspin__quiet-berlin-street-day-with-wind-noise.mp3',
  }

  let images = {}

  let animations = {
    'background': {
      'kitchen': {
        'path': '/animations/level-5/Kueche-Ende/Kueche-Ende.json',
        'loopStartFrame': 0,
        'loopEndFrame': (24 * 1) - 1
      },
      'level-end': {
        'path': '/animations/level-5/Kueche-Ende/Kueche-Ende.json',
        'startFrame': (24 * 1),
        'loopStartFrame': (24 * 2) - 1,
        'loopEndFrame': (24 * 3) - 1
      }
    },
    'robot': {
      'idle': {
        'path': '/animations/robot/Rosa-default/Rosa-default.json',
        // 'startFrame': 0,
        // 'endFrame': (24 * 2) -1
      },

      'talk': {
        'path': '/animations/robot/Rosa-sprechen/Rosa-sprechen.json',
        // 'loopStartFrame': 0,
        // 'loopEndFrame': (24 * 2) -1,
      },

      'joy':  {
        'path': '/animations/robot/Rosa-Freude/Rosa-freude.json',
        'startFrame': 0,
        'loopStartFrame': (24 * 2) -1,
        'loopEndFrame': (24 * 4) -1,
        'endFrame': (24 * 6) -1,
      },

    },
    'player': {
      'player-nici': {
        'idle': {
          'path': '/animations/player-nici/Nici-default/Nici-default.json'
        },

        'talk': {
          'path': '/animations/player-nici/Nici-sprechen/Nici-sprechen.json'
        },

        'joy': {
          'path': '/animations/player-nici/Nici-freude-full/Nici-freude-full.json',
          'startFrame': 0,
          'loopStartFrame': (24 * 2) - 1,
          'loopEndFrame': (24 * 4) - 1,
          'endFrame': (24 * 5) - 1,
        }
      },
      'player-nick': {
        'idle': {
          'path': '/animations/player-nick/Nick-default/Nick-default.json'
        },

        'talk': {
          'path': '/animations/player-nick/Nick-sprechen/Nick-sprechen.json'
        },

        'joy': {
          'path': '/animations/player-nick/Nick-freude-full/Nick-freude-full.json',
          'startFrame': 0,
          'loopStartFrame': (24 * 2) - 1,
          'loopEndFrame': (24 * 4) - 1,
          'endFrame': (24 * 5) - 1,
        }
      }
    }
  }
  animations["player"] = animations["player"][selectedPlayer];

  let speech = {
    'robot': {
      'd1-1': '/speech/robot/RoSa 73.mp3',
      'd1-2': '/speech/robot/RoSa 74.mp3',
      'quiz-1': '/speech/robot/RoSa 75-1.mp3',
      'quiz-1-hint': '/speech/robot/RoSa 75-2.mp3',
      'd2-1': '/speech/robot/RoSa 76.mp3',
      'd2-2': '/speech/robot/RoSa 77.mp3',
      'd2-3': '/speech/robot/RoSa 78.mp3',
      'd2-4': '/speech/robot/RoSa 79.mp3',
      'd2-5': '/speech/robot/RoSa 80.mp3',
      'd2-6': '/speech/robot/RoSa 81.mp3',
      'quiz-2': '/speech/robot/RoSa 82-1.mp3',
      'quiz-2-hint': '/speech/robot/RoSa 82-2.mp3',
      'd3-1': '/speech/robot/RoSa 83.mp3',
      'd3-2': '/speech/robot/RoSa 84.mp3',
      'd3-3': '/speech/robot/RoSa 85.mp3',
      'quiz-3': '/speech/robot/RoSa 86-1.mp3',
      'quiz-3-hint': '/speech/robot/RoSa 86-2.mp3',
      'd4-1': '/speech/robot/RoSa 87.mp3',
      'd4-2': '/speech/robot/RoSa 88.mp3',
      'quiz-4': '/speech/robot/RoSa 89-1.mp3',
      'quiz-4-hint': '/speech/robot/RoSa 89-2.mp3',
      'd5-1': '/speech/robot/RoSa 90.mp3',
      'd5-2': '/speech/robot/RoSa 91.mp3',
    },
    'player': {
      'player-nici': {
        'd1-1': '/speech/nici/Julie 46.mp3',
        'd1-2': '/speech/nici/Julie 47.mp3',
        'd2-1': '/speech/nici/Julie 48.mp3',
        'd2-2': '/speech/nici/Julie 49.mp3',
        'd2-3': '/speech/nici/Julie 50.mp3',
        'd2-4': '/speech/nici/Julie 51.mp3',
        'd3-1': '/speech/nici/Julie 52.mp3',
        'd3-2': '/speech/nici/Julie 53.mp3',
        'd3-3': '/speech/nici/Julie 54.mp3',
        'd4-1': '/speech/nici/Julie 55.mp3',
        'd4-2': '/speech/nici/Julie 56.mp3',
        'd5-1': '/speech/nici/Julie 57.mp3',
        'd5-2': '/speech/nici/Julie 58.mp3',
      },
      'player-nick': {
        'd1-1': '/speech/nick/Nils 46.mp3',
        'd1-2': '/speech/nick/Nils 47.mp3',
        'd2-1': '/speech/nick/Nils 48.mp3',
        'd2-2': '/speech/nick/Nils 49.mp3',
        'd2-3': '/speech/nick/Nils 50.mp3',
        'd2-4': '/speech/nick/Nils 51.mp3',
        'd3-1': '/speech/nick/Nils 52.mp3',
        'd3-2': '/speech/nick/Nils 53.mp3',
        'd3-3': '/speech/nick/Nils 54.mp3',
        'd4-1': '/speech/nick/Nils 55.mp3',
        'd4-2': '/speech/nick/Nils 56.mp3',
        'd5-1': '/speech/nick/Nils 57.mp3',
        'd5-2': '/speech/nick/Nils 58.mp3',
      }
    }
  }
  speech["player"] = speech["player"][selectedPlayer];

  let dialogues = {
    'player-d1-1': {
      'character': 'player',
      'text': 'Was für ein aufregender Tag.',
      'audio': speech["player"]["d1-1"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },
    'robot-d1-1': {
      'character': 'robot',
      'text': 'Das stimmt allerdings. Du hast vorhin gefragt, ob es hilft, einfach weniger Wasser zu verwenden. Ganz so einfach ist das gar nicht.',
      'audio': speech["robot"]["d1-1"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },
    'robot-d1-2': {
      'character': 'robot',
      'text': 'Unsere <strong>Wasserwerke</strong> und das <strong>Wassernetz</strong> brauchen schon einen bestimmten Wasserverbrauch, damit die Rohre regelmäßig durchgespült werden. <br> Kein Leitungswasser mehr zu nutzen, ist also keine Lösung. Aber sorgsam damit umzugehen, und nur so viel zu verwenden wie es sinnvoll ist, das hilft.',
      'audio': speech["robot"]["d1-2"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },
    'player-d1-2': {
      'character': 'player',
      'text': 'Okay, also vorsichtig mit Trinkwasser umgehen und nicht einfach sinnlos Wasser verschwenden – das habe ich verstanden!',
      'audio': speech["player"]["d1-2"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },
    'robot-quiz-hint-1': {
      'character': 'robot',
      'text': 'Eine volle Badewanne enthält etwa 120 Liter. Beim Duschen werden etwa 15 Liter pro Minute verbraucht.',
      'audio': speech["robot"]["quiz-1-hint"],
      // no animations
    },
    'robot-d2-1': {
      'character': 'robot',
      'text': 'Genau. Die anderen Mittel helfen auch, aber zu duschen anstatt zu baden, macht am meisten Unterschied. Ich zeig dir noch ein paar Wege, wie du mit Wasser sinnvoll umgehen kannst – versuch doch mal, ein paar davon umzusetzen!',
      'audio': speech["robot"]["d2-1"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },
    'player-d2-1': {
      'character': 'player',
      'text': 'Klingt ja gar nicht mal so schwer. Ich versuch das einfach mal!',
      'audio': speech["player"]["d2-1"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },
    'robot-d2-2': {
      'character': 'robot',
      'text': 'Sehr gute Idee.',
      'audio': speech["robot"]["d2-2"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },
    'player-d2-2': {
      'character': 'player',
      'text': 'Ich hab richtig Hunger! Wie wär’s, wenn ich uns ein paar Hamburger mache?',
      'audio': speech["player"]["d2-2"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },
    'robot-d2-3': {
      'character': 'robot',
      'text': 'Ich brauch doch gar kein Essen, nur grünen Strom! Und, wenn ich dich darauf hinweisen darf: Auch für so einen Burger wird eine ganze Menge Wasser verbraucht.',
      'audio': speech["robot"]["d2-3"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },
    'player-d2-3': {
      'character': 'player',
      'text': 'Hä? Warum denn das?',
      'audio': speech["player"]["d2-3"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },
    'robot-d2-4': {
      'character': 'robot',
      'text': 'Na überleg doch mal. Jedes Lebensmittel wird ja erstmal produziert, bevor du es im Supermarkt kaufen kannst.',
      'audio': speech["robot"]["d2-4"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },
    'player-d2-4': {
      'character': 'player',
      'text': 'Ach stimmt natürlich... Gemüse, Obst und Getreide müssen ja auch erstmal wachsen. Dafür brauchen sie natürlich auch eine Menge Wasser.',
      'audio': speech["player"]["d2-4"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },
    'robot-d2-5': {
      'character': 'robot',
      'text': 'Eben. Die Felder müssen bewässert und die Tiere für das Fleisch mit Wasser versorgt werden. <br>Das nennt man <strong>„virtuelles Wasser“</strong> – Wasser, das zur Produktion oder zum Heranwachsen von Produkten benötigt wurde.',
      'audio': speech["robot"]["d2-5"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },
    'robot-d2-6': {
      'character': 'robot',
      'text': 'Was denkst du denn, wie viel Wasser zum Beispiel für die Herstellung eines solchen Hamburgers gebraucht wird?',
      'audio': speech["robot"]["d2-6"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },
    'robot-quiz-hint-2': {
      'character': 'robot',
      'text': 'Das Tier muss mit Wasser versorgt und auch dessen pflanzliches Futter gewässert werden, bevor es verfüttert werden kann.',
      'audio': speech["robot"]["quiz-2-hint"],
      // no animations
    },
    'player-d3-1': {
      'character': 'player',
      'text': 'So viel?! Das ist ja verrückt. Vor allem wenn man bedenkt, dass Menschen normalerweise „nur“ 123 Liter für sich selbst im Haushalt brauchen.',
      'audio': speech["player"]["d3-1"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },
    'robot-d3-1': {
      'character': 'robot',
      'text': 'Ja, 2500 Liter für einen einzigen Hamburger ist eine ganze Menge. Und ganze 2250 Liter, also fast alles davon, kommt von der Herstellung des Fleisches. Für das Gemüse wird am wenigsten Wasser gebraucht.',
      'audio': speech["robot"]["d3-1"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },
    'player-d3-2': {
      'character': 'player',
      'text': 'Na dann – ganz einfach: Mach ich mir lieber mal einen leckeren Salat, ist ja eh gesünder.',
      'audio': speech["player"]["d3-2"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },
    'robot-d3-2': {
      'character': 'robot',
      'text': 'Sehr gute Idee. Dieses virtuelle Wasser steckt allerdings nicht nur in Lebensmitteln, sondern auch in einer Menge anderer Produkte, wie zum Beispiel Kleidung.',
      'audio': speech["robot"]["d3-2"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },
    'player-d3-3': {
      'character': 'player',
      'text': 'Ach klar, wenn ich so darüber nachdenke ... der Stoff einer Jeans wird ja aus Baumwolle hergestellt, dafür braucht man natürlich auch viel Wasser beim Anbau.',
      'audio': speech["player"]["d3-3"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },
    'robot-d3-3': {
      'character': 'robot',
      'text': 'Genau. Und bei der Verarbeitung, zum Beispiel dem Färben, wird auch nochmal viel Wasser benötigt.',
      'audio': speech["robot"]["d3-3"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },
    'robot-quiz-hint-3': {
      'character': 'robot',
      'text': 'Für die Herstellung von einem Kilogramm Jeansstoff werden etwa 10.000 Liter Wasser aufgewendet.',
      'audio': speech["robot"]["quiz-3-hint"],
      // no animations
    },
    'robot-d4-1': {
      'character': 'robot',
      'text': 'Wenn man alles, was wir an Wasser benutzen und das, was in unseren Lebensmitteln, Kleidung und anderen Produkten steckt zusammenzählt, kommt ganz schön was zusammen.<br> Es wird geschätzt, dass jeder Einzelne in Deutschland so täglich auf 4000 Liter Wasser kommt. Das nennt man auch den <strong>„Wasserfußabdruck“</strong>.',
      'audio': speech["robot"]["d4-1"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },
    'player-d4-1': {
      'character': 'player',
      'text': 'Oha, das sind ja mehr als 33 Badewannen! Der Wasserfußabdruck ist also alles Wasser, das wegen uns irgendwie verwendet wurde oder wir selbst verwendet haben?',
      'audio': speech["player"]["d4-1"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },
    'robot-d4-2': {
      'character': 'robot',
      'text': 'Genau. Und ganz häufig wird vieles Wasser davon in trockeneren, südlichen Ländern verwendet, obwohl das Wasser dort eh knapp ist. <br> Baumwolle und auch viele Früchte wachsen schließlich nur da, wo es warm ist. Aber wir haben heute ja gelernt: Jeder kann etwas tun, um zu helfen.',
      'audio': speech["robot"]["d4-2"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },
    'player-d4-2': {
      'character': 'player',
      'text': 'Ja, sorgsamer mit Wasser umgehen und nur so viel Wasser laufen lassen wie nötig. Öfter mal auf Fleisch verzichten und auch nicht mehr Kleidung kaufen als man braucht – wegen des Wasserfußabdrucks. Ich glaube, das ist echt wichtig.',
      'audio': speech["player"]["d4-2"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },
    'robot-quiz-hint-4': {
      'character': 'robot',
      'text': 'Die Waschmaschine braucht zwar auch viel Wasser – aber auf deine Hygiene zu verzichten, ist natürlich nicht der beste Weg!',
      'audio': speech["robot"]["quiz-4-hint"],
      // no animations
    },
    'robot-d5-1': {
      'character': 'robot',
      'text': 'Genau. Wenn wir alle unser Wasser sinnvoll nutzen und darauf aufpassen, dann haben wir noch lange gutes und sauberes Trinkwasser aus dem Wasserhahn – auf unserem schönen blauen Planeten.',
      'audio': speech["robot"]["d5-1"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },
    'player-d5-1': {
      'character': 'player',
      'text': 'Da hast du vollkommen Recht. Was für ein interessanter und spannender Tag. Ich träum heute Nacht bestimmt noch vom Weltall!',
      'audio': speech["player"]["d5-1"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },
    'robot-d5-2': {
      'character': 'robot',
      'text': 'Apropos träumen – ich glaub es wird langsam Zeit für’s Bett – und für mich, meine Batterien wieder aufzuladen. Ich freu mich schon auf’s nächste Abenteuer!',
      'audio': speech["robot"]["d5-2"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },
    'player-d5-2': {
      'character': 'player',
      'text': 'Ich mich auch Ro-Sa. Gute Nacht!',
      'audio': speech["player"]["d5-2"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },
  }

  // console.log(Object.values(dialogues).reduce((accumulator, currentValue) => accumulator + '\n' + currentValue.text, ''));

  let defaultQuizReactions = {
    'correct-star': {
      'robot': {
        'text': 'Richtige Antwort beim ersten Versuch! Dafür verdienst du einen Stern.',
        'animation': animations["robot"]["joy"],
        'animationAfter': animations["robot"]["idle"],
      },
      'player': {
        'animation': animations["player"]["joy"],
        'animationAfter': animations["player"]["idle"]
      }
    },
    'correct': {
      'robot': {
        'text': 'Richtige Antwort! Gut gemacht.',
        'animation': animations["robot"]["joy"],
        'animationAfter': animations["robot"]["idle"],
      },
      'player': {
        'animation': animations["player"]["joy"],
        'animationAfter': animations["player"]["idle"]
      }
    },
    'wrong': {
      'robot': {
        'text': 'Das war leider nicht richtig. Versuch’s einfach nochmal!',
      }
    }
  }







  // //// //////////
  // ////  STEPS
  // //// //////////

  return {
    ... baseConfig,

    'preload': {
      animations,
      sounds,
      speech,
      images
    },

    'beforeLevelEnter': function (game, next){
      next();
    },

    'steps': [
      // Level Setup
      {
        'beforeEnter': function (game, next) {

          let animationCount = 0;
          game.startAnimation('player', animations['player']['idle'], true, false, ()=>{
            animationCount ++
            if (animationCount === 3){ next(); }
          });
          game.startAnimation('robot', animations['robot']['idle'], true, false, ()=>{
            animationCount ++
            if (animationCount === 3){ next(); }
          });
          game.startAnimation('background', animations['background']['kitchen'], true, false, ()=>{
            animationCount ++
            if (animationCount === 3){ next(); }
          });

          game.startAudio('background-1', sounds['ambient-music'], true);
          // game.startAudio('background-2', sounds['ambient-bird'], true);
          game.startAudio('background-3', sounds['ambient-street'], true);
        },

        // 'waitForUser': 'Level Starten'
      },
      {
        'type': 'tutorial'
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d1-1"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d1-1"],
        'waitForUser': 'Weiter'
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d1-2"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d1-2"],
        'waitForUser': 'Weiter'
      },
      // Quiz 1
      {
        'type': 'quiz',
        'quiz': {
          'title': 'Frage 1',
          'question': {
            'text': 'Was denkst du, wobei spart man am meisten Wasser?',
            'audio': speech['robot']["quiz-1"]
          },
          'options': [
            {
              'text': 'Seltener auf die Toilette gehen',
              'isCorrect': false
            },
            {
              'text': 'Geschirr nicht mit der Hand spülen',
              'isCorrect': false
            },
            {
              'text': 'Häufiger duschen statt baden',
              'isCorrect': true
            }
          ],
          'hint': {
            'dialogue': dialogues['robot-quiz-hint-1']
          },
          'reactions': defaultQuizReactions
        }
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d2-1"],
      },
      {
        'type': 'display',
        'content': [
          {
            'type': 'text',
            'htmlText': '' +
                '<h3>So kannst du im Alltag sorgsamer mit Wasser umgehen:</h3>' +
                '<p><strong>Beim Händewaschen zwischendurch den Wasserhahn zudrehen.</strong>' +
                '<p><strong>Benutze einen Zahnputzbecher zum Mund ausspülen.</strong>' +
                '<p><strong>Wasche Obst und Gemüse in einer Schüssel.</strong>' +
                '<p><strong>Benutze Regenwasser beim Pflanzengießen.</strong>' +
                '<p><strong>Bei kleinen Toilettenbesuchen die kleine Spülung benutzen.</strong>'
          }
        ],
        'animationAfter': animations['robot']['idle']
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d2-1"],

      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d2-2"],
        'waitForUser': 'Weiter'
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d2-2"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d2-3"],
        'waitForUser': 'Weiter'
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d2-3"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d2-4"],
        'waitForUser': 'Weiter'
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d2-4"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d2-5"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d2-6"],
        'waitForUser': 'Weiter'
      },
      // Quiz 2
      {
        'type': 'quiz',
        'quiz': {
          'title': 'Frage 2',
          'question': {
            'text': 'Wie viel Wasser braucht man für die Produktion eines ganzen Hamburgers?',
            'audio': speech['robot']["quiz-2"]
          },
          'options': [
            {
              'text': 'ca. 250 Liter',
              'isCorrect': false
            },
            {
              'text': 'ca. 1000 Liter',
              'isCorrect': false
            },
            {
              'text': 'ca. 2500 Liter',
              'isCorrect': true
            }
          ],
          'hint': {
            'dialogue': dialogues['robot-quiz-hint-2']
          },
          'reactions': defaultQuizReactions
        }
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d3-1"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d3-1"],
        'waitForUser': 'Weiter'
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d3-2"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d3-2"],
        'waitForUser': 'Weiter'
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d3-3"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d3-3"],
        'waitForUser': 'Weiter'
      },
      // Quiz 3
      {
        'type': 'quiz',
        'quiz': {
          'title': 'Frage 3',
          'question': {
            'text': 'Wieviel virtuelles Wasser denkst du, stecken in einer einzigen Jeans?',
            'audio': speech['robot']["quiz-3"]
          },
          'options': [
            {
              'text': 'ca. 900 Liter',
              'isCorrect': false
            },
            {
              'text': 'ca. 8000 Liter',
              'isCorrect': true
            },
            {
              'text': 'ca. 20.000 Liter',
              'isCorrect': false
            }
          ],
          'hint': {
            'dialogue': dialogues['robot-quiz-hint-3']
          },
          'reactions': defaultQuizReactions
        }
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d4-1"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d4-1"],
        'waitForUser': 'Weiter'
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d4-2"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d4-2"],
        'waitForUser': 'Weiter'
      },
      // Quiz 4
      {
        'type': 'quiz',
        'quiz': {
          'title': 'Frage 4',
          'question': {
            'text': 'Was denkst du, ist eine gute Methode, deinen Wasser&shy;fußabdruck zu verkleinern?',
            'audio': speech['robot']["quiz-4"]
          },
          'options': [
            {
              'text': 'Öfter Gemüse aus der Region essen',
              'isCorrect': true
            },
            {
              'text': 'Rund um das Jahr Erdbeeren kaufen',
              'isCorrect': false
            },
            {
              'text': 'Kleidung seltener waschen',
              'isCorrect': false
            }
          ],
          'hint': {
            'dialogue': dialogues['robot-quiz-hint-4']
          },
          'reactions': defaultQuizReactions
        }
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d5-1"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d5-1"],
        'waitForUser': 'Weiter'
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d5-2"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d5-2"],
        'waitForUser': 'Weiter'
      },

    ],

    // ///// End of level
    'summary':{
      'reactions': {
        'background': animations['background']['level-end'],
        'player': animations['player']['joy'],
        'robot': animations['robot']['joy']
      }
    },

    'beforeLevelLeave': function (game, next){
      next();
    },
  }
}