import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

// import Level_0 from "@/levels/level-0";
import Level_1 from "@/levels/level-1";
import Level_2 from "@/levels/level-2";
import Level_3 from "@/levels/level-3";
import Level_4 from "@/levels/level-4";
import Level_5 from "@/levels/level-5";

export default new Vuex.Store({
  state: {
    selectedPlayer: null,
    availableLevels: {
      // Level_0,
      Level_1,
      Level_2,
      Level_3,
      Level_4,
      Level_5,
    },
    levelProgress: {},
    tutorialDone: false,

    // somehow vuex needs the getters in state object too:
    // gameState: null,
    // levelScore: null,
    // overallScore: null,
    // firstUnfinishedLevelIndex: null,
    // allLevelsFinished: null,
    // achievements: null,
    // awardAchieved: null,
    // shouldSeeTutorial: null
  },

  getters: {
    selectedPlayer: (state) => {
      return state.selectedPlayer
    },
    gameState: (state) => {
      return state;
    },
    availableLevels: (state) => {
      return state.availableLevels;
    },
    levelProgress: (state) => {
      return (levelId) => {
        return state.levelProgress[levelId] || {};
      }
    },
    levelScore(state, store){
      return (levelId) => {
        let availableScore = state.availableLevels[levelId]()['availableScore'];
        let scored = store.levelProgress(levelId).score || 0;

        return {
          'available': availableScore,
          'scored': scored
        }
      }
    },
    overallScore(state, store){
      let availableScore = 0;
      let overallScore = 0;

      for (let levelName in state.availableLevels){
        let levelId = state.availableLevels[levelName]()['id'];
        let score = store.levelScore(levelId);
        availableScore = availableScore + score.available;
        overallScore = overallScore + score.scored;
      }

      return {
        'available': availableScore,
        'scored': overallScore
      }
    },
    firstUnfinishedLevelIndex(state, store){
      for (let levelName in state.availableLevels){
        let levelId = state.availableLevels[levelName]()['id'];
        let hasFinishedLevel = store.levelProgress(levelId).status === 'finished';
        // console.log('store.levelProgress(levelId).status', store.levelProgress(levelId).status)
        if (!hasFinishedLevel){
          return Object.keys(state.availableLevels).indexOf(levelName);
        }
      }
      return Object.keys(state.availableLevels).length -1;
    },
    allLevelsFinished(state, store){
      let allCompleted = true;

      for (let levelName in state.availableLevels){
        let levelId = state.availableLevels[levelName]()['id'];
        let hasFinishedLevel = store.levelProgress(levelId).status === 'finished';
        if (!hasFinishedLevel){
          allCompleted = false;
        }
      }
      return allCompleted;
    },
    achievements(state, store){
      let achievements = {};
      for (let levelKey in state.availableLevels){
        let level = state.availableLevels[levelKey]();
        achievements[levelKey] = Object.assign({}, level['achievement']); // make a copy

        let levelId = level.id;
        let levelScore = store.levelScore(levelId);
        achievements[levelKey]['achieved'] = levelScore.available === levelScore.scored;
      }
      // console.log('STORE | Gettin achievements', achievements['Level_1'].achieved);
      return achievements;
    },
    awardAchieved(state, store){
      let unachievedCount = 0;
      Object.values(store.achievements).forEach((achievement)=>{
        if (!achievement.achieved){
          unachievedCount++;
        }
      });

      return !unachievedCount;
    },
    shouldSeeTutorial(state, store){
      return !state.tutorialDone;
    }
  },

  mutations: {
    loadPersistedGame(state) {
      let savedGame = localStorage.getItem('savedGame');
      if (savedGame) {
        savedGame = JSON.parse(savedGame);
        // console.log('STORE | Loading saved state:', savedGame);
        let newState = {...state, ...savedGame};
        this.replaceState(newState);
      } else {
        // console.log('STORE | No savegame found');
      }
    },

    persistSavegame(state){
      let saveGame = {
        'selectedPlayer': state.selectedPlayer,
        'levelProgress':{...state.levelProgress},
        'tutorialDone': state.tutorialDone
      };

      // console.log('STORE | Saving state:', saveGame);
      saveGame = JSON.stringify(saveGame);
      localStorage.setItem('savedGame', saveGame);
    },

    selectPlayer(state, playerName){
      state.selectedPlayer = playerName;
    },

    setLevelProgress(state, progress){
      // console.log('STORE |', 'setLevelProgress', progress);
      Vue.set(state.levelProgress, progress.levelId, {
        'status': progress.status,
        'score': progress.score
      });
    },

    resetLevel(state, levelId){
      Vue.delete(state.levelProgress, levelId);
    },

    resetGame(state){
      state.selectedPlayer = null;
      state.levelProgress = {};
      state.tutorialDone = false;
    },

    setTutorialDone(state){
      state.tutorialDone = true;
    }
  }
});