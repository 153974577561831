<template>
  <div class="video" :class="{'paused' : isPaused}">
    <!--    <video controls controlslist="nodownload nofullscreen noremoteplayback">-->
    <video ref="videoElement" @click="onVideoClick">
      <source :src="videoConfig.videoSrc"
              type="video/mp4">
    </video>
    <btn @click="onVideoClick"
         id="video-button"
         variant="primary-round"
         icon="play"
         v-if="isPaused"></btn>
  </div>
</template>

<script>
import Btn from '@/components/interface/btn';

export default {
  'name': 'Video',
  'components': {
    Btn
  },
  'props': {
    'videoConfig': Object
  },
  'data'(){
    return {
      'isPaused' : true
    }
  },
  'methods': {
    onVideoClick(){
      let videoElement = this.$refs['videoElement'];

      if (videoElement.paused){
        videoElement.play();
      } else {
        videoElement.pause();
      }

      this.isPaused = videoElement.paused;
    }
  },
  mounted() {
    let videoElement = this.$refs['videoElement'];

    videoElement.addEventListener('ended', ()=>{
      // console.log('VIDEO | Ended');
      this.$emit('ended');
      this.isPaused = true;
      videoElement.currentTime = 0;
    });
  }
}
</script>

<style lang="scss" scoped>
  @import "src/style/variables";
  @import "src/style/mixins";
  @import "src/style/icon";

  .video {
    position: relative;
    display: inline-block;
    cursor: pointer;
    border-radius: 15px;
    overflow: hidden;

    &.paused:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: #000;
      z-index: 0;
      cursor: default;
    }

    #video-button {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
    }
  }

  video {
    display: block;
    background: #000;
    height: 100%;
    width: auto;
    margin: 0 auto;
  }
</style>