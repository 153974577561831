<template>
  <div class="status-bar" :class="'status-bar--'+theme">
    <Logo class="status-bar-logo"></Logo>
    <div class="overall-score">
      <p class="overall-score__description">Insgesamt</p>
      <p class="overall-score__values">
        <span class="overall-score__num">{{overallScore.scored}}</span>
        <span class="overall-score__separator">/</span>
        <span class="overall-score__num">{{overallScore.available}}</span>
      </p>
    </div>
    <div class="title">
      <div class="maintitle">
        <slot name="maintitle"></slot>
      </div>
      <div class="subtitle">
        <slot name="subtitle"></slot>
      </div>
    </div>
    <transition name="fade">
      <Rating class="level-rating"
              v-if="showScore"
              :total="levelScore.available"
              :count="levelScore.scored"
              :theme="ratingTheme"
              :animated="animateScore">
      </Rating>
    </transition>
    <div class="achievements" @click="showAchivementModal = true">
      <Achievement
          v-for="(achievement, achievementKey) in achievements"
          :key="achievementKey +'_'+ achievement.achieved"
          :config="achievement"
          :theme="achievementTheme">
      </Achievement>
      <AchievementModal v-if="showAchivementModal" @close="showAchivementModal = false"></AchievementModal>
    </div>
   <Menu :has-home="!isInLevel" :has-level-selection="isInLevel"></Menu>
  </div>
</template>

<script>
import Logo from "@/components/interface/logo";
import Rating from "@/components/Rating";
import Achievement from "@/components/Achievement";
import AchievementModal from "@/components/AchievementModal";
import Menu from "@/components/Menu";


export default {
  'name': 'Statusbar',
  'components': {
    Logo,
    Rating,
    Achievement,
    AchievementModal,
    Menu
  },
  'props': {
    'levelId': String,
    'showScore': Boolean,
    'animateScore': {
      'type': Boolean,
      'default': false
    },
    'theme': {
      'type': String,
      'default': 'default' // 'alt: 'reduced'
    }
  },
  'data'(){
    return {
      'achievements': {},
      'showAchivementModal': false,
    }
  },
  'computed': {
    overallScore(){
      return this.$store.getters.overallScore;
    },
    levelScore(){
      return this.$store.getters.levelScore(this.levelId);
    },
    // achievements() {
    //   let achievements = this.$store.getters.achievements;
      // console.log('STATUSBAR | Computing achievements', achievements['Level_1'].achieved);
      // return achievements;
    // },
    ratingTheme(){
      return (this.theme === 'reduced') ? 'inverted' : 'default';
    },
    achievementTheme(){
      return (this.theme === 'reduced') ? 'inverted' : 'default';
    },
    isInLevel(){
      return !!this.levelId;
    }
  },
  created() {
    // cache the achievements, so it will not show as achieved right after the last quiz.
    this.achievements = Object.assign({}, this.$store.getters.achievements);
  }
}
</script>

<style lang="scss">
@import "src/style/variables";
@import "src/style/mixins";
@import "src/style/icon";


.status-bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 170px;
  color: #fff;

  background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE2MyIgdmlld0JveD0iMCAwIDE0NDAgMTYzIiB3aWR0aD0iMTQ0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHBhdGggaWQ9ImEiIGQ9Im0wIDBoMTQ0MHYxMDUuODA1NTA1Yy0yMzMuMTcxNDEgMjEuOTU5NjMzLTQ3NS4zMzY1NzIgMzEuOTQxMjg0LTcyNi40OTU0ODkgMjkuOTQ0OTU0LTI1MS4xNTg5MTgtMS45OTYzMzEtNDg4Ljk5Mzc1NS0xMS45Nzc5ODItNzEzLjUwNDUxMS0yOS45NDQ5NTR6Ii8+PGZpbHRlciBpZD0iYiIgaGVpZ2h0PSIxNTQuNCUiIHdpZHRoPSIxMDUuMSUiIHg9Ii0yLjYlIiB5PSItMjUuNyUiPjxmZU9mZnNldCBkeD0iMCIgZHk9IjIiIGluPSJTb3VyY2VBbHBoYSIgcmVzdWx0PSJzaGFkb3dPZmZzZXRPdXRlcjEiLz48ZmVHYXVzc2lhbkJsdXIgaW49InNoYWRvd09mZnNldE91dGVyMSIgcmVzdWx0PSJzaGFkb3dCbHVyT3V0ZXIxIiBzdGREZXZpYXRpb249IjEyIi8+PGZlQ29sb3JNYXRyaXggaW49InNoYWRvd0JsdXJPdXRlcjEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgICAwIDAgMCAwIDAgICAwIDAgMCAwIDAgIDAgMCAwIDAuNjM2OTA5OTY1IDAiLz48L2ZpbHRlcj48L2RlZnM+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48dXNlIGZpbGw9IiMwMDAiIGZpbHRlcj0idXJsKCNiKSIgeGxpbms6aHJlZj0iI2EiLz48dXNlIGZpbGw9IiM3NTE5MzciIGZpbGwtcnVsZT0iZXZlbm9kZCIgeGxpbms6aHJlZj0iI2EiLz48L2c+PC9zdmc+");
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  transition: background-position $transition-duration ease-in-out;

  &.status-bar--default {
    background-position: center top;
  }

  &.status-bar--reduced {
    background-position: center -150px;
  }

  .title {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    text-align: center;

    .maintitle {
      margin-top: 24px;
      font-size: 18px;
      text-transform: uppercase;
    }

    .subtitle {
      //margin-top: 8px;
      font-size: 35px;
      font-family: $font-bold;
    }
  }

  .level-rating {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .status-bar-logo {
    position: absolute;
    left: 4px;
    bottom: -35px;
  }

  .overall-score {
    padding: 16px 32px;

    .overall-score__description {
      font-size: 18px;
      font-family: $font-light;
      text-transform: uppercase;
      opacity: 0.6;
    }

    .overall-score__values {
      display: inline-block;
      padding: 12px 0 12px 60px;
      @extend .icon-star;
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 32px;
    }

    .overall-score__separator,
    .overall-score__num {
      font-size: 30px;
      font-family: $font-light;
    }

    .overall-score__num:first-child {
      font-family: $font-black;
    }

    .overall-score__separator,
    .overall-score__num:last-child {
      opacity: 0.3;
    }

    .overall-score__separator {
      margin: 0 12px;
    }
  }

  .achievements {
    cursor: pointer;
    position: absolute;
    right: 170px;
    top: 24px;

    .achievement {
      display: inline-block;

      &:not(:first-of-type) {
        margin-left: 6px;
      }
    }
  }
}

</style>