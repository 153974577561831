<template>
  <div class="tutorial">
    <Modal v-if="modalOpen" :closable="false">
      <h2>Tutorial</h2>

      <div class="tutorial-content" v-if="tutorialStep === 0">
        <p><strong>Hallo! Ich bin’s: Ro-Sa. Ich helfe dir dabei, das Spiel zu verstehen. Lass uns loslegen!</strong></p>
        <p>Während des Spiels werde ich dir immer wieder knifflige Quiz-Fragen stellen. Für jede Frage, die du beim <strong>allerersten Versuch</strong> richtig beantwortest, sammelst du einen Stern.</p>
        <div class="image-text image-text--bottom">
          <img :src="tutorialAssets['images']['tutorial-slide-1-img-1']">
          <p>Hier siehst du, wie viele Sterne du in diesem Level gesammelt hast – und wie viele du sammeln kannst.</p>
        </div>
      </div>

      <div class="tutorial-content" v-if="tutorialStep === 1">
        <div class="image-text image-text--bottom">
          <img :src="tutorialAssets['images']['tutorial-slide-2-img-1']">
          <p>Hier steht, wie viele Sterne du schon insgesamt im Spiel gesammelt hast.</p>
        </div>
        <div class="image-text image-text--bottom">
          <img :src="tutorialAssets['images']['tutorial-slide-2-img-2']">
          <p>Das ist die geheime Belohnungskiste! Immer wenn du alle Sterne in einem Level gesammelt hast, schaltest du eine Belohnung frei.</p>
        </div>
      </div>

      <div class="tutorial-content" v-if="tutorialStep === 2">
        <div class="image-text image-text--right">
          <img :src="tutorialAssets['images']['tutorial-slide-3-img-1']">
          <p>Wenn du mal nicht weiter weißt, dann klicke einfach das Fragezeichen über meinem Kopf an – vielleicht habe ich ja einen hilfreichen Tipp für dich!</p>
        </div>
        <p>Jetzt wünsch ich dir viel Erfolg beim Spielen! </p>
        <p>
          <strong>Und nicht vergessen:</strong>
          <br>Wenn du die Quizfragen schon beim ersten Mal richtig beantwortest, sammelst du Sterne und kannst tolle Belohnungen freischalten!
          <br>Dein Fortschritt wird gespeichert, aber <strong>Vorsicht:</strong> Wenn du ein Level nicht bis zum Ende spielst und von vorne anfängst, musst du deine Sterne in diesem Level noch mal einsammeln.
        </p>
      </div>

      <div class="tutorial-buttons">
        <Btn icon="left" variant="primary" v-if="tutorialStep > 0" @click="tutorialStep -= 1">Zurück</Btn>
        <Btn icon="right" variant="primary" v-if="tutorialStep < 2" @click="tutorialStep += 1">Verstanden</Btn>
        <Btn icon="right" variant="primary" v-if="tutorialStep === 2" @click="onTutorialEnd">Los geht's!</Btn>
      </div>
    </Modal>
    <Audio ref="tutorialSound"></Audio>
  </div>
</template>

<script>
import Btn from "@/components/interface/btn";
import Modal from "@/components/Modal";
import basicTutorialAssets from "@/assets/basicTutorialAssets";
import Audio from "@/components/Audio";

export default {
  'name': 'Tutorial',
  'components': {
    Modal,
    Btn,
    Audio
  },
  'props': {
    'showTutorial': {
      'type': Boolean,
      'default': false
    }
  },
  'data'(){
    return {
      'tutorialStep': 0,
      'tutorialDone': false,
      'tutorialAssets': basicTutorialAssets
    }
  },
  'computed': {
    modalOpen(){
      return this.showTutorial && !this.tutorialDone;
    }
  },
  'watch': {
    'modalOpen'(newVal, oldVal){
      if(newVal === true && oldVal === false){
        setTimeout(this.startStepAudio.bind(this), 1000);
      }
    },
    'tutorialStep'(){
      this.startStepAudio();
    }
  },
  'methods': {
    startStepAudio(){
      let audio = this.$refs['tutorialSound'];
      audio.stopAll();

      // console.log('TUTORIAL | STEP:',this.tutorialStep, audio);

      switch (this.tutorialStep) {
        case 0:
          audio.startAudio('tutorial', this.tutorialAssets["sounds"]['tutorial-slide-1-text-1'], false, false, ()=>{
            audio.requestAudioEnd('tutorial', false, ()=>{
              if (this.tutorialStep === 0){
                audio.startAudio('tutorial', this.tutorialAssets["sounds"]['tutorial-slide-1-text-2'], false, false, ()=>{
                  audio.requestAudioEnd('tutorial', false, ()=>{
                    if (this.tutorialStep === 0){
                      audio.startAudio('tutorial', this.tutorialAssets["sounds"]['tutorial-slide-1-text-3'], false, false);
                    }
                  });
                });
              }
            });
          });
          break;

        case 1:
          audio.startAudio('tutorial', this.tutorialAssets["sounds"]['tutorial-slide-2-text-1'], false, false, ()=>{
            audio.requestAudioEnd('tutorial', false, ()=>{
              if (this.tutorialStep === 1){
                audio.startAudio('tutorial', this.tutorialAssets["sounds"]['tutorial-slide-2-text-2'], false, true);
              }
            });
          });
          break;

        case 2:
          audio.startAudio('tutorial', this.tutorialAssets["sounds"]['tutorial-slide-3-text-1'], false, false, ()=>{
            audio.requestAudioEnd('tutorial', false, ()=>{
              if (this.tutorialStep === 2 && !this.tutorialDone){
                audio.startAudio('tutorial', this.tutorialAssets["sounds"]['tutorial-slide-3-text-2'], false, true);
              }
            });
          });
          break;
      }
    },
    onTutorialEnd() {
      let audio = this.$refs['tutorialSound'];
      audio.stopAll();

      this.tutorialDone = true;
      this.$emit('tutorial-end');
    }
  },
  mounted() {
    if (this.modalOpen){
      setTimeout(this.startStepAudio.bind(this), 1000);
    }
  }
}
</script>

<style lang="scss">
@import "src/style/variables";
@import "src/style/mixins";
@import "src/style/icon";

.tutorial {
  font-size: 18px;

  .modal-dialog {
    padding-top: 50px;
    width: 950px;
  }

  h2 {
    font-size: 35px;
    text-align: center;
  }

  .tutorial-content {
    width: 560px;
    margin: 42px auto;

    > * + p {
      margin-top: 32px;
    }

    > * + .image-text {
      margin-top: 42px;
    }
  }

  .image-text {
    border-radius: 12px;
    overflow: hidden;
    background-color: $color-grey-light;

    p {
      padding: 24px;
      font-family: $font-bold;
    }

    &.image-text--right {
      display: flex;
      align-items: center;
    }
  }

  .tutorial-buttons {
    display: flex;
    justify-content: center;

    .button {
      min-width: 360px;
    }

    .button + button {
      margin-left: 32px;
    }
  }
}

</style>