<template>
  <transition name="fade" appear>
    <div class="modal modal-backdrop">
      <div class="modal-dialog">
        <div class="close" v-if="closable" @click.stop="$emit('close')"></div>
        <slot>

        </slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  'name': 'Modal',
  'props': {
    'closable': {
      'type': Boolean,
      'default': true
    }
  }
}
</script>

<style lang="scss">
@import "src/style/variables";
@import "src/style/mixins";
@import "src/style/icon";

.modal-backdrop {
  z-index: 3000;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
}

.modal-dialog {
  @include modal();
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 752px;
  max-height: 800px;
  padding: 70px 85px 55px;
  overflow: auto;

  .close {
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 30px;
    height: 36px;
    width: 36px;

    @extend .icon-wrong-red;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

</style>