<template>
  <transition name="quiz-transition" appear>
    <div class="quiz" :class="'selection-'+selectionStatus +' '+ (locked ? 'quiz--locked' : '')">
      <div class="title" v-html="quizConfig.title"></div>
      <div class="question" v-html="quizConfig.question.text"></div>
      <div class="options">
        <div class="option" :class="(selectedOption === index) ? 'selected--'+selectionStatus : ''"
             v-for="(option, index) in this.quizConfig.options" :key="index"
             @click="onOptionSelect(index)">

          <div class="option-status" v-html="['A', 'B', 'C', 'D', 'E'][index]"></div>
          <div class="option-text" v-html="option.text"></div>
        </div>
      </div>
      <btn @click="onBtnClick"
           class="quiz-button" :class="{'quiz-button-disabled': btnDisabled}"
           :icon="buttonContent.icon"
           :disabled="btnDisabled">
        {{ buttonContent.text }}
      </btn>
      <btn @click="showHint"
           id="hint-button"
           variant="primary-round"
           icon="help"
           v-if="hintVisible"></btn>
      <Audio ref="audio"></Audio>
    </div>
  </transition>
</template>

<script>
  import Audio from '@/components/Audio';
  import Btn from '@/components/interface/btn';
  import basicLevelAssets from "@/assets/basicLevelAssets";

  const QUIZ_STATUS = {
    'INITIAL': 'initial',
    'PENDING': 'pending',
    'CORRECT': 'correct',
    'CORRECT_STAR': 'correct-star',
    'WRONG': 'wrong'
  };

  export default {
    'name': 'Quiz',
    'components': {
      Audio,
      Btn
    },
    'props': {
      'quizConfig': Object,
      'locked': Boolean
    },
    data(){
      return {
        'selectedOption': null,
        'selectionStatus': QUIZ_STATUS.INITIAL,
        'tryCount': 0,
        'hintRequested': false
      }
    },
    'computed': {
      hasSelectedOption(){
        return this.selectedOption !== null;
      },
      hintVisible(){
        return !this.hintRequested
            && !this.locked
            && (this.selectionStatus !== QUIZ_STATUS.INITIAL)
            && (this.selectionStatus !== QUIZ_STATUS.CORRECT)
            && (this.selectionStatus !== QUIZ_STATUS.CORRECT_STAR);
      },
      btnDisabled(){
        return ((this.selectionStatus === QUIZ_STATUS.PENDING) && !this.hasSelectedOption)
          || ((this.selectionStatus !== QUIZ_STATUS.PENDING) && this.locked);
      },
      buttonContent(){
        let text = 'Weiter';
        let icon = 'none';

        switch (this.selectionStatus){
          case QUIZ_STATUS.INITIAL:
            icon = 'right';
            break;

          case QUIZ_STATUS.PENDING:
            text = 'Antwort senden';
            icon = 'right';
            break;

          case QUIZ_STATUS.CORRECT:
          case QUIZ_STATUS.CORRECT_STAR:
            text = 'Weiter';
            icon = 'right';
            break;

          case QUIZ_STATUS.WRONG:
            text = 'Nochmal versuchen'
            icon = 'reload';
            break;
        }

        return {
          text,
          icon
        }
      }
    },

    'watch': {
      selectionStatus(newStatus, oldStatus){
        // console.log('QUIZ | Status changed from:', oldStatus, 'to', newStatus);

        if ((newStatus === QUIZ_STATUS.PENDING) && (oldStatus !== QUIZ_STATUS.INITIAL)){
          newStatus = 'start-over';
        }

        if (oldStatus !== QUIZ_STATUS.INITIAL){
          this.$emit('status-change', newStatus, oldStatus);
        }
      }
    },

    'methods': {
      onOptionSelect(index){
        if (this.selectionStatus === QUIZ_STATUS.INITIAL){
          this.selectionStatus = QUIZ_STATUS.PENDING;
        }

        if (this.selectionStatus === QUIZ_STATUS.WRONG){
          this.startOver();
        }

        if (this.selectionStatus === QUIZ_STATUS.PENDING){
          this.selectedOption = index;

          if (this.$refs['audio']){
            this.$refs['audio'].startAudio('quiz-selection', basicLevelAssets['sounds']['basic-quiz-selection']);
          }
        }
      },

      onBtnClick(){
        switch (this.selectionStatus){
          case QUIZ_STATUS.PENDING:
            if (this.hasSelectedOption){
              this.checkSelection();
            }
            break;

          case QUIZ_STATUS.CORRECT:
          case QUIZ_STATUS.CORRECT_STAR:
            this.$emit('exit', this.selectionStatus);
            break;

          case QUIZ_STATUS.WRONG:
            this.startOver();
            break;
        }
      },

      checkSelection(){
        this.tryCount++;
        let selectedOption = this.quizConfig.options[this.selectedOption];
        if (selectedOption.isCorrect){
          this.onCorrectChoice();
        } else {
          this.onWrongChoice();
        }
      },

      onCorrectChoice(){
        if (this.tryCount === 1){
          this.selectionStatus = QUIZ_STATUS.CORRECT_STAR;
        } else {
          this.selectionStatus = QUIZ_STATUS.CORRECT;
        }
        if (this.$refs['audio']){
          this.$refs['audio'].startAudio('quiz-selection', basicLevelAssets['sounds']['basic-quiz-right']);
        }
      },

      onWrongChoice(){
        this.selectionStatus = QUIZ_STATUS.WRONG;
        if (this.$refs['audio']){
          this.$refs['audio'].startAudio('quiz-selection', basicLevelAssets['sounds']['basic-quiz-wrong']);
        }
      },

      startOver(){
        this.selectedOption = null;
        this.selectionStatus = QUIZ_STATUS.PENDING;
        // this.hintRequested = false;
      },
      showHint(){
        this.$emit('show-hint');
        this.hintRequested = true;
      }
    },
    mounted() {
      setTimeout(()=>{
        if (this.selectionStatus === QUIZ_STATUS.INITIAL){
          this.selectionStatus = QUIZ_STATUS.PENDING;
        }
      }, 3000);
    }
  }
</script>

<style lang="scss" scoped>
@import "src/style/variables";
@import "src/style/mixins";
@import "src/style/icon";

  .quiz {
    @include modal;
    padding: 48px 32px 44px;

    position: absolute;
    left: 50%;
    top: 194px;
    transform: translate(-50%, 0);
    opacity: 1;

    //height: 656px;
    width: 560px;

    .title {
      font-size: 18px;
      line-height: 1;
      text-align: center;
    }

    .question {
      margin-top: 28px;
      margin-bottom: 54px;
      font-size: 35px;
      line-height: 1.2;
      text-align: center;
      @extend %stagger-animation;
      animation-delay: ($animation-duration * 3);
    }
  }

  .quiz-transition-enter,
  .quiz-transition-leave-to {
    top: 110%;
    opacity: 0;
  }

  .quiz-transition-enter-active,
  .quiz-transition-leave-active {
    transition:
        top $transition-duration ease-out,
        opacity $transition-duration ease-out;
  }

  .option {
    position: relative;
    padding: 12px;
    border-radius: 50px;
    background-color: $color-grey-light;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    transition:
        background-color $transition-duration ease-in-out,
        opacity $transition-duration ease-in-out;

    @extend %stagger-animation;

    &:nth-of-type(1){
      animation-delay: 2s;
    }
    &:nth-of-type(2){
      animation-delay: 2.6s;
    }
    &:nth-of-type(3){
      animation-delay: 3.2s;
    }
    &:nth-of-type(4){
      animation-delay: 3.8s;
    }

    //.selection-initial & {
      //animation: none;
      //visibility: hidden;
    //}

    &:not(:first-child){
      margin-top: 16px;
    }

    .option-status {
      flex: 1 0 50px;
      height: 50px;
      width: 50px;
      padding: 8px;
      background-color: #fff;
      border-radius: 100%;
      font-size: 32px;
      line-height: 1.1;
      text-align: center;
      font-family: $font-bold;
    }

    .option-text {
      flex: 1 1 100%;
      //font-size: 28px;
      font-size: 22px;
      line-height: 1.2;
      padding: 0 62px 0 12px;
      text-align: center;
    }

    &:hover {
      background-color: $color-grey-mid;
      transition-duration: 0s;
    }

    //.quiz--locked &,
    //.selection-wrong &,
    .selection-correct &,
    .selection-correct-star & {
      pointer-events: none;
    }

    &.selected--pending {
      background-color: $color-grey;

      .option-text {
        color: #fff;
        font-family: $font-bold;
      }
    }

    &.selected--correct {
      background-color: $color-green-light;
      color: $color-green;
    }

    &.selected--correct-star {
      background-color: $color-green-light;

      .option-text {
        color: $color-green;
      }

      .option-status {
        @extend .icon-star;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: #fff;
        color: transparent;
        background-size: 90%;
      }
    }

    &.selected--wrong {
      background-color: $color-red-light;

      .option-text {
        color: $color-red;
      }

      .option-status {
        @extend .icon-wrong;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: $color-red;
        color: transparent;
      }
    }

    .selection-correct &:not(.selected--correct),
    .selection-correct-star &:not(.selected--correct-star),
    .selection-wrong &:not(.selected--wrong) {
      opacity: 0.5;
    }
  }

  .quiz-button {
    display: block;
    width: 100%;
    margin-top: 40px;
    opacity: 1;
    transition: opacity $transition-duration ease-in-out;
    @extend %stagger-animation;
    animation-delay: 1s;

    //.quiz--locked &,
    .selection-initial & {
      animation: none;
      visibility: hidden;
    }

    //&.quiz-button-disabled {
    //  opacity: 0;
    //  pointer-events: none;
    //}
  }

  #hint-button {
    @extend %stagger-and-zoom-animation;
    animation-delay: 1s;

    position: absolute;
    left: -260px;
    top: 150px;

    .id-Level_4 & {
      top: 60px;
    }
  }

  %stagger-animation {
    animation-name: stagger-in;
    animation-fill-mode: both;
    animation-duration: $animation-duration;
    animation-timing-function: ease-out;
  }

  @keyframes stagger-in {
    from {
      opacity: 0;
      transform: translate(0, 50%);
    }
    to {
      //opacity: not explicitly set, will use opacity value of element;
      transform: translate(0 ,0);
    }
  }

  %stagger-and-zoom-animation {
    @extend %stagger-animation;
    animation-name: stagger-and-zoom-in;
  }

  @keyframes stagger-and-zoom-in {
    from {
      opacity: 0;
      transform-origin: center center;
      transform: translate(0, 50%) scale(3);
    }
    to {
      //opacity: not explicitly set, will use opacity value of element;
      transform-origin: center center;
      transform: translate(0 ,0) scale(1);
    }
  }

</style>