import basicLevelAssets from "@/assets/basicLevelAssets";
import levelAchievements from "@/levels/levelAchievements";

let baseConfig = {
  'id': 'Level_1',
  'title': 'Level 1',
  'subtitle': 'Auf Tauchgang',
  'description': 'Wasseraufbereitung',
  'levelSliderBackground': basicLevelAssets['images']['levelSliderBackground-level_1'],
  'availableScore': 5,
  'achievement': levelAchievements['goggles'],
  'nextLevelId': 'Level_2'
};

export const config = baseConfig;

export default (selectedPlayer = 'player-nici') => {

  // //// //////////
  // ////  SOURCES
  // //// //////////

  let sounds = {
    'beaming': '/sounds/Menu_General/0-Multi-Mobil-Einsteigen-Beamen-208533__slag777__space-sweep.mp3',
    'flying': '/sounds/Menu_General/0-Multi-Mobil-Flug-Start-Flight-Space-Earth__awr1001__jet-airplane.mp3',
    '1-1-kueche-musik': '/sounds/Level_1/1-1-Backgroundmusic-Hold on a Sec.mp3',
    '1-1-kuche-natur': '/sounds/Level_1/1-1-Bird-ambience__straget__house-sparrow.mp3',
    '1-1-kuche-street': '/sounds/Level_1/1-1-Street-Ambience396667__dbspin__quiet-berlin-street-day-with-wind-noise.mp3',
    '1-2-pipe-ambient': '/sounds/Level_1/1-2-Pipe-Ambience-Bubble389468__lzmraul__water-surface-bubbles.mp3',
    '1-2-pipe-bubble': '/sounds/Level_1/1-2-Pipe-Ambience-Water_burghrecords__stream-running-water-aviemore-scotland.mp3',
    '1-3-hochbehalter-ambient': '/sounds/Level_1/1-3-Ambience-Hochbehälter419741__eeriedescence__water-echo.mp3',
    '1-3-hochbehalter-splash': '/sounds/Level_1/1-3-Water-Splash-Hochbehälter442773__qubodup__big-water-splash.mp3',
    '1-4-outdoor-ambient': '/sounds/Level_1/1-4-Outdoor-ambience-403318__straget__birds-in-the-forest-three.mp3',
    '1-5-wasserwerk-ambient': '/sounds/Level_1/1-5-Wasserwerk-Ambience454450__kyles__laundromat-washers-washing-machines-close-rinse-cycle1_01.mp3',
  }

  let images = { }

  let animations = {
    'background': {
      'kitchen': {
        'path': '/animations/level-1/Kueche-Beginn/Kueche-Beginn.json',
        'startFrame': 0,
        'endFrame': (24 * 1) -1
      },

      'wasserschutz': {
        'path': '/animations/level-1/Wasserschutzgebiet/Wasserschutzgebiet.json',
        'startFrame': 0,
        'endFrame': (24 * 1) -1
      },

      'maschinenraum': {
        'path': '/animations/level-1/Maschinenraum/Maschinenraum.json',
        'startFrame': 0,
        'endFrame': (24 * 1) -1
      },
      'maschinenraum-level-end': {
        'path': '/animations/level-1/Maschinenraum/Maschinenraum.json',
        'startFrame': (24 * 1),
        'loopStartFrame': (24 * 2) - 1,
        'loopEndFrame': (24 * 3) - 1
      },
    },

    'robot': {
      'idle': {
        'path': '/animations/robot/Rosa-default/Rosa-default.json',
        // 'startFrame': 0,
        // 'endFrame': (24 * 2) -1
      },

      'talk': {
        'path': '/animations/robot/Rosa-sprechen/Rosa-sprechen.json',
        // 'loopStartFrame': 0,
        // 'loopEndFrame': (24 * 2) -1,
      },

      'joy':  {
        'path': '/animations/robot/Rosa-Freude/Rosa-freude.json',
        'startFrame': 0,
        'loopStartFrame': (24 * 2) -1,
        'loopEndFrame': (24 * 4) -1,
        'endFrame': (24 * 6) -1,
      },
    },

    'player': {
      'player-nici': {
        'idle': {
          'path': '/animations/player-nici/Nici-default/Nici-default.json'
        },

        'talk': {
          'path': '/animations/player-nici/Nici-sprechen/Nici-sprechen.json'
        },

        'joy':  {
          'path': '/animations/player-nici/Nici-freude-full/Nici-freude-full.json',
          'startFrame': 0,
          'loopStartFrame': (24 * 2) -1,
          'loopEndFrame': (24 * 4) -1,
          'endFrame': (24 * 5) -1,
        },

        'beam-to-pipe': {
          'path': '/animations/player-nici/Abflug-multimobil-Nici/Abfahrt-multimobil-Nici.json',
        },

        'beam-to-screen': {
          'path': '/animations/player-nici/Erscheinen-Nici/Erscheinen-Nici.json',
        },

        'pipe-small': {
          'path': '/animations/level-1/Wasserrohr-Nici/Wasserrohr-Nici.json',
          'startFrame': 0,
          'loopStartFrame': (24 * 2) -1,
          'loopEndFrame': (24 * 4)
        },
        'pipe-clock': {
          'path': '/animations/level-1/Wasserrohr-Nici/Wasserrohr-Nici.json',
          'startFrame': (24 * 4) + 1, //export error
          'loopStartFrame': (24 * 6),
          'loopEndFrame': (24 * 8) -1
        },
        'pipe-thick': {
          'path': '/animations/level-1/Wasserrohr-Nici/Wasserrohr-Nici.json',
          'startFrame': (24 * 8),
          'loopStartFrame': (24 * 14) -1,
          'loopEndFrame': (24 * 16) -1,
          'endFrame': (24 * 18) -1
        },
        'hochbehaelter-ankunft': {
          'path': '/animations/level-1/Wasserhochbehaelter/Wasserhochbehaelter-Nici.json',
          'startFrame': 0,
          'loopStartFrame': (24 * 3),
          'loopEndFrame': (24 * 5) -1,
        },
        'hochbehaelter-beaming': {
          'path': '/animations/level-1/Wasserhochbehaelter/Wasserhochbehaelter-Nici.json',
          'startFrame': (24 * 5),
          'loopStartFrame': (24 * 7),
          'loopEndFrame': (24 * 9) -1,
        }
      },

      'player-nick': {
        'idle': {
          'path': '/animations/player-nick/Nick-default/Nick-default.json'
        },

        'talk': {
          'path': '/animations/player-nick/Nick-sprechen/Nick-sprechen.json'
        },

        'joy':  {
          'path': '/animations/player-nick/Nick-freude-full/Nick-freude-full.json',
          'startFrame': 0,
          'loopStartFrame': (24 * 2) -1,
          'loopEndFrame': (24 * 4) -1,
          'endFrame': (24 * 5) -1,
        },

        'beam-to-pipe': {
          'path': '/animations/player-nick/Abflug-multimobil-Nick/Abfahrt-multimobil-Nick.json',
        },

        'beam-to-screen': {
          'path': '/animations/player-nick/Erscheinen-Nick/Erscheinen-Nick.json',
        },

        'pipe-small': {
          'path': '/animations/level-1/Wasserrohr-Nick/Wasserrohr-Nick.json',
          'startFrame': 0,
          'loopStartFrame': (24 * 2) -1,
          'loopEndFrame': (24 * 4)
        },
        'pipe-clock': {
          'path': '/animations/level-1/Wasserrohr-Nick/Wasserrohr-Nick.json',
          'startFrame': (24 * 4) +1, //export error
          'loopStartFrame': (24 * 6),
          'loopEndFrame': (24 * 8) -1
        },
        'pipe-thick': {
          'path': '/animations/level-1/Wasserrohr-Nick/Wasserrohr-Nick.json',
          'startFrame': (24 * 8),
          'loopStartFrame': (24 * 14) -1,
          'loopEndFrame': (24 * 16) -1,
          'endFrame': (24 * 18) -1
        },
        'hochbehaelter-ankunft': {
          'path': '/animations/level-1/Wasserhochbehaelter/Wasserhochbehaelter-Nick.json',
          'startFrame': 0,
          'loopStartFrame': (24 * 3),
          'loopEndFrame': (24 * 5) -1,
        },
        'hochbehaelter-beaming': {
          'path': '/animations/level-1/Wasserhochbehaelter/Wasserhochbehaelter-Nick.json',
          'startFrame': (24 * 5),
          'loopStartFrame': (24 * 7),
          'loopEndFrame': (24 * 9) -1,
        }
      }
    }
  }
  animations["player"] = animations["player"][selectedPlayer];

  let speech = {
    'robot': {
      'd1-1': '/speech/robot/RoSa 01.mp3',
      'd1-2': '/speech/robot/RoSa 02.mp3',
      'd1-3': '/speech/robot/RoSa 03.mp3',
      'd2-1': '/speech/robot/RoSa 04.mp3',
      'd2-2': '/speech/robot/RoSa 05.mp3',
      'quiz-1': '/speech/robot/RoSa 06-1.mp3',
      'quiz-1-hint': '/speech/robot/RoSa 06-2.mp3',
      'quiz-2': '/speech/robot/RoSa 07-1.mp3',
      'quiz-2-hint': '/speech/robot/RoSa 07-2.mp3',
      'd4-1': '/speech/robot/RoSa 08.mp3',
      'd4-2': '/speech/robot/RoSa 09.mp3',
      'd4-3': '/speech/robot/RoSa 10.mp3',
      'd5-1': '/speech/robot/RoSa 11.mp3',
      'd5-2': '/speech/robot/RoSa 12.mp3',
      'd5-3': '/speech/robot/RoSa 13.mp3',
      'quiz-3': '/speech/robot/RoSa 14-1.mp3',
      'quiz-3-hint': '/speech/robot/RoSa 14-2.mp3',
      'd6-1': '/speech/robot/RoSa 15.mp3',
      'quiz-4': '/speech/robot/RoSa 16-1.mp3',
      'quiz-4-hint': '/speech/robot/RoSa 16-2.mp3',
      'd7-1': '/speech/robot/RoSa 17.mp3',
      'd7-2': '/speech/robot/RoSa 18.mp3',
      'd7-3': '/speech/robot/RoSa 19.mp3',
      'quiz-5': '/speech/robot/RoSa 20-1.mp3',
      'quiz-5-hint': '/speech/robot/RoSa 20-2.mp3',
      'd8-1': '/speech/robot/RoSa 21.mp3',
      'd8-2': '/speech/robot/RoSa 22.mp3',
      'd8-3': '/speech/robot/RoSa 23.mp3',
    },

    'player': {
      'player-nici': {
        'd1-1': '/speech/nici/Julie 01.mp3',
        'd1-2': '/speech/nici/Julie 02.mp3',
        'd1-3': '/speech/nici/Julie 03.mp3',
        'd1-4': '/speech/nici/Julie 04.mp3',
        'd2-1': '/speech/nici/Julie 05.mp3',
        'd2-2': '/speech/nici/Julie 06.mp3',
        'd3-1': '/speech/nici/Julie 07.mp3',
        'd5-1': '/speech/nici/Julie 08.mp3',
        'd5-2': '/speech/nici/Julie 09.mp3',
        'd5-3': '/speech/nici/Julie 10.mp3',
        'd6-1': '/speech/nici/Julie 11.mp3',
        'd6-2': '/speech/nici/Julie 12.mp3',
        'd7-2': '/speech/nici/Julie 13.mp3',
        'd8-1': '/speech/nici/Julie 14.mp3',
        'd8-2': '/speech/nici/Julie 15.mp3',
      },

      'player-nick': {
        'd1-1': '/speech/nick/Nils 01.mp3',
        'd1-2': '/speech/nick/Nils 02.mp3',
        'd1-3': '/speech/nick/Nils 03.mp3',
        'd1-4': '/speech/nick/Nils 04.mp3',
        'd2-1': '/speech/nick/Nils 05.mp3',
        'd2-2': '/speech/nick/Nils 06.mp3',
        'd3-1': '/speech/nick/Nils 07.mp3',
        'd5-1': '/speech/nick/Nils 08.mp3',
        'd5-2': '/speech/nick/Nils 09.mp3',
        'd5-3': '/speech/nick/Nils 10.mp3',
        'd6-1': '/speech/nick/Nils 11.mp3',
        'd6-2': '/speech/nick/Nils 12.mp3',
        'd7-2': '/speech/nick/Nils 13.mp3',
        'd8-1': '/speech/nick/Nils 14.mp3',
        'd8-2': '/speech/nick/Nils 15.mp3',
      }
    }
  }
  speech["player"] = speech["player"][selectedPlayer];

  let dialogues = {

    'player-d1-1': {
      'character': 'player',
      'text': 'Puh, endlich alles erledigt. Wir haben geputzt, gewaschen, Pflanzen gegossen…',
      'audio': speech["player"]["d1-1"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d1-1': {
      'character': 'robot',
      'text': 'Allerdings. Und für alles haben wir Wasser benutzt. Ganz schön viel Wasser. Vielleicht sollten wir etwas sparsamer damit umgehen.',
      'audio': speech["robot"]["d1-1"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'player-d1-2': {
      'character': 'player',
      'text': 'Sparsamer? Mit Wasser? Aber das wird doch nie leer … oder etwa doch? Du weißt doch immer alles – woher kommt denn eigentlich das Wasser aus dem Wasserhahn?',
      'audio': speech["player"]["d1-2"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d1-2': {
      'character': 'robot',
      'text': 'So einfach ist das gar nicht. Aber ich kann’s dir zeigen! Wie wär’s denn mit einem kleinen Abenteuer? Dabei können wir herausfinden, wie das mit dem Trinkwasser funktioniert. Bist du dabei?',
      'audio': speech["robot"]["d1-2"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'player-d1-3': {
      'character': 'player',
      'text': 'Na klar! Ich will wissen, was es damit auf sich hat! Wo fangen wir an?',
      'audio': speech["player"]["d1-3"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d1-3': {
      'character': 'robot',
      'text': 'Erinnerst du dich an mein Multi-Mobil? Das hat doch einen Quanten-Zentrifugator-Schrumpfer! Damit können wir uns ganz klein machen.<br> Dann können wir direkt in den Wasserhahn hier schlüpfen und herausfinden, wo unser Trinkwasser herkommt. Bist du bereit?',
      'audio': speech["robot"]["d1-3"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'player-d1-4': {
      'character': 'player',
      'text': 'Auf jeden Fall! Los geht’s!',
      'audio': speech["player"]["d1-4"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },


    'player-d2-1': {
      'character': 'player',
      'text': 'Achtung! Flieg bloß vorsichtig! Wo sind wir denn jetzt?',
      'audio': speech["player"]["d2-1"],
      // no animations
    },

    'robot-d2-1': {
      'character': 'robot',
      'text': 'Zweifelst du etwa an meinen Fahrkünsten? Wir sind jetzt mitten in den Wasserrohren, die durch unser Haus führen. Schau mal da vorne!',
      'audio': speech["robot"]["d2-1"],
      // no animations
    },

    'player-d2-2': {
      'character': 'player',
      'text': 'Was ist das denn für ein riesiges Gerät?',
      'audio': speech["player"]["d2-2"],
      // no animations
    },

    'robot-d2-2': {
      'character': 'robot',
      'text': 'Wir sind doch geschrumpft, schon vergessen? Das ist der <strong>Wasserzähler</strong>. Immer wenn wir den Wasserhahn aufmachen oder das Klo spülen, dreht sich das kleine Zahnrädchen darin und zählt mit, wieviel Wasser wir im Haus benutzen.',
      'audio': speech["robot"]["d2-2"],
      // no animations
    },

    'robot-quiz-hint-1': {
      'character': 'robot',
      'text': 'Einmal die Toilette zu spülen, verbraucht 8 bis 14 Liter Wasser – je nachdem, welche Taste du drückst.',
      'audio': speech["robot"]["quiz-1-hint"],
      // no animations
    },

    'player-d3-1': {
      'character': 'player',
      'text': 'Puh, das ist ja eine ganze Menge. Damit kann man ja eine ganze Badewanne bis zum Rand füllen!',
      'audio': speech["player"]["d3-1"],
      // no animations
    },

    'robot-quiz-hint-2': {
      'character': 'robot',
      'text': 'Je länger Wasser fließt, desto mehr wird natürlich verbraucht!',
      'audio': speech["robot"]["quiz-2-hint"],
      // no animations
    },

    'robot-d4-1': {
      'character': 'robot',
      'text': 'Genau! Deswegen sollte man am besten nicht so oft baden – und stattdessen lieber duschen, um Wasser zu sparen.',
      'audio': speech["robot"]["d4-1"],
      // no animations
    },

    'robot-d4-2': {
      'character': 'robot',
      'text': 'Schau mal, je weiter wir vom Haus weg sind, desto dicker werden die Wasserrohre. Da kann ganz schön viel Trinkwasser am Tag durchfließen, so dass jeder im Haus gut versorgt wird.',
      'audio': speech["robot"]["d4-2"],
      // no animations
    },

    'robot-d4-3': {
      'character': 'robot',
      'text': 'Achtung – festhalten!',
      'audio': speech["robot"]["d4-3"],
      // no animations
    },

    'player-d5-1': {
      'character': 'player',
      'text': 'Autsch! Wo sind wir denn jetzt gelandet? Ist das hier ein Schwimmbecken?',
      'audio': speech["player"]["d5-1"],
      // no animations
    },

    'robot-d5-1': {
      'character': 'robot',
      'text': 'Aber nein, das ist ein sogenannter <strong>Hochbehälter</strong>. Aber zuerst: Wie wär’s, wenn ich uns mal wieder auf normale Größe bringe?',
      'audio': speech["robot"]["d5-1"],
      // no animations
    },

    'player-d5-2': {
      'character': 'player',
      'text': 'Oh ja, bitte! So klein zu sein, fühlt sich langsam komisch an!',
      'audio': speech["player"]["d5-2"],
      // no animations
    },

    'robot-d5-2': {
      'character': 'robot',
      'text': 'Alles klar! Achtung – ich beame uns mal wieder an Land.',
      'audio': speech["robot"]["d5-2"],
      // no animations
    },

    'player-d5-3': {
      'character': 'player',
      'text': 'Schon viel besser. Was ist denn aber jetzt ein Hochbehälter?',
      'audio': speech["player"]["d5-3"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d5-3': {
      'character': 'robot',
      'text': 'In erster Linie speichert er unser Trinkwasser. Aber er hat auch noch eine andere wichtige Funktion. Aber bevor ich das jetzt lange erkläre:<br> Ich habe einen richtigen Experten, nämlich Herrn Burkhardt, gefragt, wie das alles funktioniert. Er kann uns zeigen, wo wir hier genau sind. Hergeschaut!',
      'audio': speech["robot"]["d5-3"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'robot-quiz-hint-3': {
      'character': 'robot',
      'text': 'Im Hochbehälter wird Wasser aufbewahrt, bevor es über die Rohre weitergeleitet wird.',
      'audio': speech["robot"]["quiz-3-hint"],
      // no animations
    },

    'player-d6-1': {
      'character': 'player',
      'text': 'Ist ja super wichtig, so ein Hochbehälter! Und ich dachte das wär ein Schwimmbecken …',
      'audio': speech["player"]["d6-1"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d6-1': {
      'character': 'robot',
      'text': 'Jetzt weißt du mehr! Der Hochbehälter hat noch eine wichtige Aufgabe: Er sorgt auch für einen gleichmäßigen <strong>Wasserdruck</strong> in den Wasserrohren. Damit aus den Wasserhähnen im Haus immer gleich viel Wasser fließt.<br> Komm mal mit – es ist Zeit, etwas frische Luft zu schnappen! Wir machen uns mal auf den Weg zum <strong>Wasserwerk</strong>. Da kommt das Trinkwasser, welches im Hochbehälter gesammelt wird, nämlich her.',
      'audio': speech["robot"]["d6-1"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'player-d6-2': {
      'character': 'player',
      'text': 'Schön hier! Was bedeuten denn diese blauen Schilder?',
      'audio': speech["player"]["d6-2"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-quiz-hint-4': {
      'character': 'robot',
      'text': 'Quellen und Grundwasser rund um das Wasserwerk müssen besonders sauber gehalten werden.',
      'audio': speech["robot"]["quiz-4-hint"],
      // no animations
    },

    'robot-d7-1': {
      'character': 'robot',
      'text': 'Genau! Im <strong>Wasserschutzgebiet</strong> rund um das Wasserwerk sind die <strong>Brunnen</strong> und <strong>Quellen</strong>, aus denen unser Trinkwasser gewonnen wird. Damit das immer sauber bleibt, wird das Gebiet besonders geschützt.',
      'audio': speech["robot"]["d7-1"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'robot-d7-2': {
      'character': 'robot',
      'text': 'Du darfst dort zum Beispiel nicht grillen oder Müll wegwerfen. Und Landwirte dürfen kein Pflanzenschutzmittel verwenden. Eben alles, was unser Trinkwasser verschmutzen könnte.<br>   Aber das kann uns unser Experte Herr Burkhardt bestimmt noch besser erklären!',
      'audio': speech["robot"]["d7-2"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'player-d7-2': {
      'character': 'player',
      'text': 'Da bin ich mir sicher! Vielleicht nimmt er uns ja sogar auf eine Führung durch das ganze Wasserwerk mit?',
      'audio': speech["player"]["d7-2"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d7-3': {
      'character': 'robot',
      'text': 'Na, gut aufgepasst? <br>Woher kommt nochmal das Wasser, das hier verarbeitet wird?',
      'audio': speech["robot"]["d7-3"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'robot-quiz-hint-5': {
      'character': 'robot',
      'text': 'Im Meer gibt’s zwar eine Menge Wasser, aber trinken kannst du das wegen dem vielen Salz nicht.',
      'audio': speech["robot"]["quiz-5-hint"],
      // no animations
    },

    'robot-d8-1': {
      'character': 'robot',
      'text': 'Hier passiert also eine ganze Menge. All das Wasser, das wir benutzen, wird gefiltert und regelmäßig getestet, damit es auch ganz sicher sauber ist. Danke Herr Burkhardt – bis zum nächsten Mal!',
      'audio': speech["robot"]["d8-1"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'player-d8-1': {
      'character': 'player',
      'text': 'Auf Wiedersehen! Und Danke!',
      'audio': speech["player"]["d8-1"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d8-2': {
      'character': 'robot',
      'text': 'So! Was hältst du von einer kleinen Pause draußen im Grünen?',
      'audio': speech["robot"]["d8-2"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'player-d8-2': {
      'character': 'player',
      'text': 'Au ja! Eine Pause würde mir jetzt gut tun. Wir haben heute schon so viel erlebt – und gelernt natürlich!',
      'audio': speech["player"]["d8-2"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d8-3': {
      'character': 'robot',
      'text': 'Na, dann suchen wir uns mal ein schönes Plätzchen.',
      'audio': speech["robot"]["d8-3"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    }

  }




  let defaultQuizReactions = {
    'correct-star': {
      'robot': {
        'text': 'Richtige Antwort beim ersten Versuch! Dafür verdienst du einen Stern.',
            'animation': animations["robot"]["joy"],
            'animationAfter': animations["robot"]["idle"],
      },
      'player': {
        'animation': animations["player"]["joy"],
            'animationAfter': animations["player"]["idle"]
      }
    },
    'correct': {
      'robot': {
        'text': 'Richtige Antwort! Gut gemacht.',
            'animation': animations["robot"]["joy"],
            'animationAfter': animations["robot"]["idle"],
      },
      'player': {
        'animation': animations["player"]["joy"],
            'animationAfter': animations["player"]["idle"]
      }
    },
    'wrong': {
      'robot': {
        'text': 'Das war leider nicht richtig. Versuch’s einfach nochmal!',
      }
    }
  }







  // //// //////////
  // ////  STEPS
  // //// //////////

  return {
    ...baseConfig,

    'preload': {
      animations,
      sounds,
      speech,
      images
    },

    'beforeLevelEnter': function (game, next) {
      next();
    },

    'steps': [

      // Level Setup
      {
        'beforeEnter': function (game, next) {

          let animationCount = 0;
          game.startAnimation('player', animations['player']['idle'], true, false, ()=>{
            animationCount ++
            if (animationCount === 3){ next(); }
          });
          game.startAnimation('robot', animations['robot']['idle'], true, false, ()=>{
            animationCount ++
            if (animationCount === 3){ next(); }
          });
          game.startAnimation('background', animations['background']['kitchen'], true, false, ()=>{
            animationCount ++
            if (animationCount === 3){ next(); }
          });

          // game.startAudio('background-1', sounds['1-1-kuche-natur'], 'loop');
          game.startAudio('background-2', sounds['1-1-kuche-street'], 'loop');
          game.startAudio('background-3', sounds['1-1-kueche-musik'], 'loop');
        },

        // 'waitForUser': 'Level Starten'
      },

      {
        'type': 'tutorial'
      },

      // Dialog 1
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d1-1"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d1-1"],
        'waitForUser': 'Weiter'
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d1-2"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d1-2"],
        'waitForUser': 'Weiter'
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d1-3"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d1-3"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d1-4"],
        'waitForUser': 'Weiter'
      },

      // Scene Change
      {
        'afterEnter': function (game, next){
          game.clearDialogueTexts();

          game.startAnimation('player', animations['player']['beam-to-pipe'], false, false, ()=>{
            game.clearAnimationLayer('robot');
            game.startAudio('SFX', sounds['beaming'], false, false);
            setTimeout(()=>{
              game.startAudio('SFX', sounds['flying'], false, false);
            }, 2500);

            game.requestAnimationEnd('player', next);
          });
        }
      },

      // Scene Change
      {
        'afterEnter': function (game, next){
          game.clearDialogueTexts();

          game.startAudio('background-1', sounds['1-2-pipe-ambient'], true, false);
          game.startAudio('background-2', sounds['1-2-pipe-bubble'], true, false);
          game.requestAudioEnd('background-3', true);
          game.requestAudioEnd('SFX', true);

          game.clearAnimationLayer('player');
          game.clearAnimationLayer('robot');

          game.startAnimation('background', animations['player']['pipe-small'], true, false, ()=>{
            next();
          });
        },
        // 'blackout': true
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d2-1"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d2-1"],
      },
      {
        'afterEnter': function (game, next){
          game.startAnimation('background', animations['player']['pipe-clock'], true, true, ()=>{
            next();
          });
        },
        'waitForUser': 'Weiter'
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d2-2"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d2-2"],
        'waitForUser': 'Weiter'
      },

      // Quiz 1
      {
        'type': 'quiz',
        'quiz': {
          'title': 'Frage 1',
          'question': {
            'text': 'Was glaubst du: Wieviel Wasser verbrauchst du alleine an einem Tag?',
            'audio': speech['robot']["quiz-1"]
          },
          'options': [
            {
              'text': '20 Liter',
              'isCorrect': false
            },
            {
              'text': '50 Liter',
              'isCorrect': false
            },
            {
              'text': '123 Liter',
              'isCorrect': true
            }
          ],
          'hint': {
            'dialogue': dialogues['robot-quiz-hint-1']
          },
          'reactions': {
            'correct-star': {
              'robot': {
                'text': 'Richtige Antwort beim ersten Versuch! Dafür verdienst du einen Stern.',
              },
            },
            'correct': {
              // 'robot': {
              //   'text': '...',
              // },
            },
            'wrong': {
              // 'robot': {
              //   'text': '...',
              // }
            }
          }
        }
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d3-1"],
      },

      // Quiz 2
      {
        'type': 'quiz',
        'quiz': {
          'title': 'Frage 2',
          'question': {
            'text': 'Was denkst du: Für was brauchst du am meisten Wasser auf einmal?',
            'audio': speech['robot']["quiz-2"]
          },
          'options': [
            {
              'text': 'Duschen',
              'isCorrect': false
            },
            {
              'text': 'Toilettenspülung',
              'isCorrect': false
            },
            {
              'text': 'Baden',
              'isCorrect': true
            }
          ],
          'hint': {
            'dialogue': dialogues['robot-quiz-hint-2']
          },
          'reactions': {
            'correct-star': {
              'robot': {
                'text': 'Richtige Antwort beim ersten Versuch! Dafür verdienst du einen Stern.',
              },
            },
            'correct': {
              // 'robot': {
              //   'text': '...',
              // },
            },
            'wrong': {
              // 'robot': {
              //   'text': '...',
              // }
            }
          }
        }
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d4-1"],
        'waitForUser': 'Weiter'
      },

      // Background Change
      {
        'afterEnter': function (game, next){
          game.clearDialogueTexts();
          game.startAnimation('background', animations['player']['pipe-thick'], true, true);
          window.setTimeout(next, 100);
        },
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d4-2"],
        'waitForUser': 'Weiter'
      },

      // Background Change
      {
        'afterEnter': function (game, next){
          game.clearDialogueTexts();
          next();
        },
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d4-3"],
        // 'waitForUser': 'Weiter'
      },

      // Background Change
      {
        'beforeEnter': function (game, next){
          game.clearDialogueTexts();
          next();
        },
        'afterEnter': function (game, next){
          game.requestAnimationEnd('background', ()=>{
            game.requestAudioEnd('background-2', true);
            game.requestAudioEnd('background-3', true);

            game.startAudio('background-1', sounds['1-3-hochbehalter-ambient'], true, false);
            game.startAnimation('background', animations['player']['hochbehaelter-ankunft'], true, true, ()=>{
              next()
            });
            setTimeout(()=>{
              game.startAudio('SFX', sounds['1-3-hochbehalter-splash'], false, false);
            }, 1000)
          });
        },
        // 'waitForUser': 'Weiter',
        // 'blackout': true
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d5-1"]
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d5-1"],
        'waitForUser': 'Weiter'
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d5-2"]
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d5-2"],
        'waitForUser': 'Weiter'
      },

      // Beaming characters
      {
        'afterEnter': function (game, next){
          game.startAnimation('background', animations['player']['hochbehaelter-beaming'], true, true);

          setTimeout(()=>{

            game.startAnimation('player', animations['player']['beam-to-screen'], false, false, ()=>{
              game.requestAnimationEnd('player', ()=> {
                game.startAnimation('robot', animations['robot']['idle'], true, false);
                game.startAnimation('player', animations['player']['idle'], true, false);
              });

              game.startAudio('SFX', sounds['beaming'], false, false, ()=>{
                game.requestAudioEnd('SFX', false, ()=>{
                  next();
                });
              });
            });

          }, 1000);
        }
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d5-3"]
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d5-3"],
        'waitForUser': 'Weiter'
      },

      {
        'type': 'display',
        'content': [
          {
            'type': 'video',
            'videoSrc': '/video/Hochbehälter MQ final_1_Test_V1.mp4'
          }
        ],
        'animationAfter': animations['robot']['idle']
      },

      // Quiz 3
      {
        'type': 'quiz',
        'quiz': {
          'title': 'Frage 3',
          'question': {
            'text': 'Gut aufgepasst? <br> Welche Aufgaben hat ein Hochbehälter?',
            'audio': speech['robot']["quiz-3"]
          },
          'options': [
            {
              'text': 'Wasser speichern',
              'isCorrect': true
            },
            {
              'text': 'Wasser erhitzen',
              'isCorrect': false
            },
            {
              'text': 'Wasser reinigen',
              'isCorrect': false
            }
          ],
          'hint': {
            'dialogue': dialogues['robot-quiz-hint-3']
          },
          'reactions': defaultQuizReactions
        }
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d6-1"]
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d6-1"],
        'waitForUser': 'Weiter'
      },

      // Background Change
      {
        'afterEnter': function (game, next){
          game.clearDialogueTexts();
          game.startAnimation('background', animations['background']['wasserschutz'], true, false);
          game.startAudio('background-1', sounds['1-4-outdoor-ambient'], true, false);
          setTimeout(next, 500);
        },
        'blackout': true
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d6-2"],
        'waitForUser': 'Weiter'
      },

      // Quiz 4
      {
        'type': 'quiz',
        'quiz': {
          'title': 'Frage 4',
          'question': {
            'text': 'Was denkst du: Was bedeuten die blauen Schilder vor dem Wasserwerk?',
            'audio': speech['robot']["quiz-4"]
          },
          'options': [
            {
              'text': 'Hier dürfen LKWs fahren',
              'isCorrect': false
            },
            {
              'text': 'Achtung, Flutgefahr',
              'isCorrect': false
            },
            {
              'text': 'Wasserschutzgebiet',
              'isCorrect': true
            }
          ],
          'hint': {
            'dialogue': dialogues['robot-quiz-hint-4']
          },
          'reactions': defaultQuizReactions
        }
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d7-1"]
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d7-2"],
        'waitForUser': 'Weiter'
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d7-2"],
        'waitForUser': 'Weiter'
      },

      {
        'type': 'display',
        'content': [
          {
            'type': 'video',
            'videoSrc': '/video/Wasserwerkfilm 1 MQ final_1_Test_V1.mp4'
          }
        ],
        'animationAfter': animations['robot']['idle']
      },

      // Quiz 5
      {
        'type': 'quiz',
        'quiz': {
          'title': 'Frage 5',
          'question': {
            'text': 'Na, gut aufgepasst? Woher kommt nochmal das Wasser, das hier verwendet wird?',
            'audio': speech['robot']["quiz-5"]
          },
          'options': [
            {
              'text': 'Aus der Pegnitz',
              'isCorrect': false
            },
            {
              'text': 'Aus Brunnen und Quellen',
              'isCorrect': true
            },
            {
              'text': 'Aus dem Meer',
              'isCorrect': false
            }
          ],
          'hint': {
            'dialogue': dialogues['robot-quiz-hint-5']
          },
          'reactions': defaultQuizReactions
        }
      },

      {
        'type': 'display',
        'content': [
          {
            'type': 'video',
            'videoSrc': '/video/Wasserfilm 2 MQ final_1_Test_V1.mp4'
          }
        ],
        'animationAfter': animations['robot']['idle'],
        'afterEnter': function (game, next){
          setTimeout(()=>{
            game.startAnimation('background', animations['background']['maschinenraum'], true, false);
          }, 3000);
          next();
        }
      },

      {
        'beforeEnter': function (game, next){
          game.startAudio('background-1', sounds['1-5-wasserwerk-ambient'], true, false);
          next();
        }
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d8-1"],
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d8-1"],
        'waitForUser': 'Weiter'
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d8-2"],
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d8-2"],
        'waitForUser': 'Weiter'
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d8-3"],
      },

    ],

    // ///// End of level
    'summary':{
      'reactions': {
        'background': animations['background']['maschinenraum-level-end'],
        'player': animations['player']['joy'],
        'robot': animations['robot']['joy']
      }
    },

    'beforeLevelLeave': function (game, next) {
      next();
    },
  }
}