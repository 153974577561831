<template>
  <div id="app" :class="viewportOrientation"
       :style="'--view-height:'+ viewHeight + 'px;' + '--view-width:'+ viewWidth + 'px;' + '--view-scale:'+ viewScale + ';'">

    <div id="game-container" v-if="preloadReady">
      <router-view :level-config="selectedLevelConfig" @request-level="levelRequest" @level-end="onLevelEnd"
                   :key="((selectedLevelConfig && selectedLevelConfig.id) || 'dashboard') + uniqueResetKey"/>
    </div>

    <transition name="fade">
      <LoadingScreen v-if="!preloadReady" :percentage="loadingPercentage"></LoadingScreen>
    </transition>

    <div id="footer">
      <a href="https://www.n-ergie.de" target="_blank">Zur N-ERGIE</a>
      <a href="#impress" @click.prevent="showImpress = true">Impressum</a>
      <a href="#datenschutz" @click.prevent="showDatenschutz = true">Datenschutz</a>
<!--      <a href="javascript:void(0);" onclick="UC_UI.showSecondLayer();">Privatsphäre Einstellungen</a>-->
      <a href="#privatsphäre" @click.prevent="showUserCentricsLayer()">Privatsphäre Einstellungen</a>
      <a href="#contact" @click.prevent="showContact = true">Kontakt</a>
      <a href="#bildnachweis" @click.prevent="showPictureProof = true">Bildnachweis</a>
    </div>

    <div id="view-to-small" v-if="viewIsToSmall">
      <logo class="logo"></logo>
      <Modal class="modal">
        <h2>Achtung</h2>
        <p class="text">
          Das Spiel &bdquo;Hallo, Wasserwissen!&rdquo; ist nicht für mobile Geräte optimiert.
          Bitte rufe die Seite auf einem Tablet oder Desktop-Computer mit größerem Bildschirm auf.
        </p>
        <btn @click="viewIsToSmall = false" variant="primary"> OK</btn>
      </Modal>
    </div>

    <Modal id="impress" v-if="showImpress" @close="showImpress = false">
      <h2>Angaben gemäß § 5 TMG:</h2>
      <p><span class="no-break">N-ERGIE</span>&nbsp;Aktiengesellschaft<br>
        Am Plärrer 43<br>
        90429 Nürnberg</p>
      <h2>Vertretungsberechtigter Vorstand:</h2>
      <p>Maik Render<br>
        Magdalena Weigel</p>
      <h2>Vorsitzender des Aufsichtsrates:</h2>
      <p>Marcus König, Oberbürgermeister</p>
      <h2>Kontakt:</h2>
      <p>Telefon:&nbsp;<a href="tel:+4991180201">0911 802-01</a><br>
        E-Mail:&nbsp;<a href="mailto:dialog@n-ergie.de">dialog@n-ergie.de</a><br>
        Postanschrift:&nbsp;<span class="no-break">N-ERGIE</span>&nbsp;Aktiengesellschaft, 90338 Nürnberg</p>
      <h2>Registereintrag:</h2>
      <p>Eintragung im Handelsregister des AG Nürnberg; Registernummer: HR B 17412</p>
      <h2>Umsatzsteuer-IdNr.:</h2>
      <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE 209536298</p>
      <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h2>
      <p>Heiko Linder, c/o <span class="no-break">N-ERGIE</span>&nbsp;Aktiengesellschaft, Am Plärrer 43, 90429 Nürnberg</p>
      <h2>Execution/Production:</h2>
      <br>
      <img src="/images/arsmedium.png" alt="Logo von arsmedium">
      <p>
        <a href="mailto:info@arsmedium.com">info@arsmedium.com</a>
        <br>
        <a href="https://www.arsmedium.com" target="_blank">www.arsmedium.com</a>
      </p>
    </Modal>

    <Modal id="datenschutz" v-show="showDatenschutz" @close="showDatenschutz = false">
      <h1>Datenschutzerklärung</h1>
      <p>Wir freuen uns über Ihren Besuch auf unserer Homepage und Ihr Interesse an unserem Unternehmen. Der Schutz
        Ihrer personenbezogenen Daten anlässlich Ihres Besuchs auf unserer Homepage ist uns ein wichtiges Anliegen. Ihre
        Daten werden vertraulich und entsprechend den gesetzlichen Datenschutzbestimmungen, insbesondere der
        EU-Datenschutz-Grundverordnung (DS-GVO), sowie der nachfolgenden Datenschutzerklärung erfasst und genutzt.</p>
      <h2>1 Verantwortliche Stelle</h2>
      <p><span class="no-break">N-ERGIE</span> Aktiengesellschaft</p>
      <p>Vorstand:</p>
      <p>Maik Render<br>
        Magdalena Weigel</p>
      <p>Am Plärrer 43<br>
        90429 Nürnberg</p>
      <h2>2 Personenbezogene Daten</h2>
      <p>Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare
        natürliche Person beziehen. Dazu gehören insbesondere Name, Geburtsdatum, Adresse, Telefonnummer,
        E-Mail-Adresse, aber auch Ihre IP-Adresse. Anonyme Daten liegen vor, wenn keinerlei Personenbezug zum Nutzer
        hergestellt werden kann.</p>
      <h2>3 Besuch der Website</h2>
      <p>Wenn Sie unsere Webseiten besuchen, speichert unser Webserver standardmäßig einzelne personenbezogene Daten. Es
        werden automatisch Informationen allgemeiner Natur in sogenannten Server-Log-Files erfasst. Diese Datensätze
        beinhalten die Seite, von der aus die Datei angefordert wurde, den Namen der Datei, das Datum und die Uhrzeit
        der Anforderung, die übertragene Datenmenge und den Zugriffsstatus, eine Beschreibung des Typs des verwendeten
        Webbrowsers und des Betriebssystems sowie die IP-Adresse des anfordernden Rechners. Diese Daten werden
        ausschließlich zu statistischen Zwecken bei der <span class="tw-nobr"><span
            class="no-break">N-ERGIE</span> </span>Aktiengesellschaft ausgewertet.</p>
      <p>Mittels der Besucheranalyse mit etracker werden Daten zu Marketing- und Optimierungszwecken gesammelt und
        gespeichert. Sie haben jederzeit die Möglichkeit, sich mit Wirkung für die Zukunft, von der etracker Zählung
        auszuschließen (siehe unter 8.2).</p>
      <p>Weitergehende personenbezogene Daten werden nur erhoben und erfasst, wenn Sie diese Angaben freiwillig, etwa im
        Rahmen einer Anfrage oder Registrierung, machen.</p>
      <h2>4 Weitergabe an Dritte</h2>
      <p>Wir werden Ihre Daten nur im Rahmen der gesetzlichen Bestimmungen oder bei entsprechender Einwilligung an
        Dritte weitergeben. Ansonsten erfolgt eine Weitergabe an Dritte nicht, es sei denn wir sind aufgrund zwingender
        Rechtsvorschriften dazu verpflichtet (Weitergabe an externe Stellen wie z. B. Aufsichtsbehörden oder
        Strafverfolgungsbehörden).</p>
      <h2>5 Empfänger der Daten</h2>
      <p>Innerhalb unseres Unternehmens stellen wir sicher, dass nur diejenigen Personen Ihre Daten erhalten, die diese
        zur Erfüllung der vertraglichen und gesetzlichen Pflichten benötigen. In vielen Fällen unterstützen
        Dienstleister unsere Fachabteilungen bei der Erfüllung ihrer Aufgaben. Mit allen Dienstleistern wurde das
        notwendige datenschutzrechtliche Vertragswerk abgeschlossen.</p>
      <h2>6 Drittlandübermittlung</h2>
      <p>Wir übermitteln Ihre Daten nicht an Länder außerhalb der Europäischen Union (EU) oder des Europäischen
        Wirtschaftsraumes (EWR). Die <span class="tw-nobr"> <span class="no-break">N-ERGIE</span></span>
        Aktiengesellschaft garantiert Ihnen, dass Ihre Daten weder an Dritte verkauft noch anderweitig vermarktet
        werden.</p>
      <h2>7 Anwendungen auf der Website</h2>
      <p>Die von Ihnen zur Verfügung gestellten personenbezogenen Daten verwenden wir ausschließlich zur Beantwortung
        Ihrer Anfragen, zur Bearbeitung Ihrer Servicedienstleistungen und für die technische Administration.</p>
      <p>Um sicherzugehen, dass Ihre Daten bei der Übermittlung nicht von Unbefugten gelesen werden, verschlüsseln wir
        unsere kompletten Webseiten nach aktuellem Stand der Technik. Die aktive Verschlüsselung erkennen Sie am „https“
        in der Adresszeile Ihres Browsers.</p>
      <p>Ihre personenbezogenen Daten werden an Dritte nur weitergegeben oder sonst übermittelt, wenn dies zur
        Begründung, Durchführung oder Beendigung eines rechtsgeschäftlichen oder rechtsgeschäftsähnlichen
        Schuldverhältnisses erforderlich ist oder Sie zuvor eingewilligt haben.</p>
      <p>Für den Betrieb der Webseiten oder für die auf der Website angebotenen Dienstleistungen (u. a. Newsletter)
        können wir technische Dienstleister im Wege der Auftragsverarbeitung (Art. 28 DS-GVO) einsetzen.</p>
      <h3>7.1 Anwendungen: Formulare ohne Registrierung (Art. 6 Abs. 1 a, b DS-GVO)</h3>
      <p>Bei diesen Anwendungen und Formularen ist keine Registrierung notwendig. Ihre Angaben sind freiwillig. Bei den
        mit einem Stern (*) gekennzeichneten Feldern handelt es sich um Pflichtfelder, deren Inhalt wir benötigen, um
        Ihnen Auskunft zu geben bzw. Ihre Anfrage zu Ihrer Zufriedenheit zu beantworten.</p>
      <h3>7.2 Nutzung von Daten zu Werbezwecken (Art. 6 Abs. 1 a, f DS-GVO)</h3>
      <p>Im Falle Ihrer ausdrücklichen vorherigen Zustimmung oder auf der Grundlage datenschutzrechtlicher
        Erlaubnisnormen nutzen wir die Daten auch zu Zwecken der Werbung für eigene Produkte und Dienstleistungen. Zu
        diesem Zweck kann es auch erforderlich sein, dass wir Ihre Daten an Konzern-Unternehmen (verbundene Unternehmen
        i. S. von § 15 AktG) oder Dienstleister im Rahmen der Auftragsverarbeitung (Art. 28 DS-GVO) weitergeben. Die
        <span class="no-break">N-ERGIE</span> verpflichtet die Konzern-Unternehmen oder die Dienstleister in diesem Fall
        zur Einhaltung der Datenschutzbestimmungen der EU-Datenschutz-Grundverordnung. Sie können Ihre Einwilligung zur
        Nutzung Ihrer personenbezogenen Daten zu Zwecken der Werbung (z. B. im Rahmen von Produktinformationen), der
        Markt- und Meinungsforschung sowie zur Verbesserung unseres Services jederzeit per Telefon, E-Mail oder auf dem
        Postweg widerrufen.</p>
      <h3>7.3 Anwendung: Gewinnspiele (Art. 6 Abs. 1 a, b DS-GVO)</h3>
      <p>Die
        <nobr>N-ERGIE</nobr>
        bietet anlassbezogen Gewinnspiele per Gewinnspielkarte oder online an. Die Teilnahme an diesen Gewinnspielen und
        die Durchführung richten sich nach den jeweiligen Teilnahmebedingungen.<br>
        Um an einem Gewinnspiel teilzunehmen, ist es notwendig, sich zu registrieren. Die Teilnahme am Gewinnspiel ist
        freiwillig. Durch die Registrierung erklärt sich der Teilnehmer ausdrücklich damit einverstanden, dass die
        N-ERGIE die Daten speichert und für die Durchführung des Gewinnspieles und im Rahmen der gesetzlich zulässigen
        Zwecke verwendet.<br>
        Ihre Daten werden nur an Dritte weitergegeben, wenn dies für die Durchführung des Gewinnspieles im Sinne des
        Datenschutzgesetzes erforderlich ist. Die Einwilligung zur Speicherung und Auswertung der erhobenen Daten kann
        durch den Teilnehmer jederzeit widerrufen werden.
      </p>
      <h2>8 Eingesetzte Cookies und Analysedienste</h2>
      <h3>8.1 Cookies</h3>
      <p>Für die Website werden <strong>bei Bedarf</strong> sogenannte Cookies verwendet. Cookies sind kleine
        Textdateien, die über Ihren Browser von der Website auf Ihrem Endgerät gespeichert werden. Sie dienen dazu, die
        Nutzung der Website für Sie einfacher zu gestalten.</p>
      <p>Bei den für die Website verwendeten Cookies handelt es sich regelmäßig um lediglich für die Dauer Ihres Besuchs
        gültige Cookies („Session Cookies“). Diese Session Cookies werden automatisch mit dem Verlassen der Website
        wieder von Ihrem Endgerät gelöscht. Sofern Ihnen auf der Website die Möglichkeit zur Nutzung eines persönlichen
        Benutzerzugangs angeboten wird, verwenden wir zusätzlich ein über die Dauer Ihres jeweiligen Besuchs
        hinausgehendes Cookie, in dem die zur Anmeldung auf der Website erforderlichen Informationen gespeichert werden,
        um spätere Zugriffe zu erleichtern. Dieses Cookie hat eine Lebensdauer von neunzig Tagen und wird anschließend
        automatisch gelöscht.</p>
      <p>Die für diese Website verwendeten Cookies enthalten keine Viren und richten auf Ihrem Endgerät keinen Schaden
        an. Personenbezogene Daten werden in den für diese Website verwendeten Cookies nicht gespeichert. Sie können die
        Verwendung von Cookies mit einer entsprechenden Einstellung in Ihrem Browser verhindern. Bitte verwenden Sie
        hierzu die Hilfefunktion Ihres Internet Browsers. Wir weisen allerdings darauf hin, dass bei einem Verbot von
        Cookies möglicherweise nicht alle auf der Website angebotenen Funktionen genutzt werden können.</p>
      <h3>8.2 etracker (Besucheranalyse)</h3>
      <p>Der Anbieter dieser Website verwendet Dienste der etracker GmbH aus Hamburg, Deutschland (<a
          href="http://www.etracker.de/" target="_blank" rel="noopener noreferrer">www.etracker.de</a>) zur Analyse von
        Nutzungsdaten. Es werden dabei Cookies eingesetzt, die eine statistische Analyse der Nutzung dieser Website
        durch ihre Besucher sowie die Anzeige nutzungsbezogener Inhalte oder Werbung ermöglichen. Cookies sind kleine
        Textdateien, die vom Internet Browser auf dem Endgerät des Nutzers gespeichert werden. etracker Cookies
        enthalten keine Informationen, die eine Identifikation eines Nutzers ermöglichen.</p>
      <p>Die mit etracker erzeugten Daten werden im Auftrag des Anbieters dieser Website von etracker ausschließlich in
        Deutschland verarbeitet und gespeichert und unterliegen damit den strengen deutschen und europäischen
        Datenschutzgesetzen und -standards. etracker wurde diesbezüglich unabhängig geprüft, zertifiziert und mit dem
        Datenschutz-Gütesiegel <a href="https://www.eprivacy.eu/kunden/vergebene-siegel/firma/etracker-gmbh/"
                                  target="_blank" rel="noopener noreferrer">ePrivacyseal</a> ausgezeichnet.</p>
      <p>Die Datenverarbeitung erfolgt auf der Rechtsgrundlage des Art. 6 Abs. 1 lit f (berechtigtes Interesse) der
        EU-Datenschutzgrundverordnung (EU-DSGVO). Unser berechtigtes Interesse besteht in der Optimierung unseres
        Online-Angebotes und unseres Webauftritts. Da uns die Privatsphäre unserer Besucher besonders wichtig ist, wird
        die IP-Adresse bei etracker frühestmöglich anonymisiert. Anmelde- oder Gerätekennungen werden bei etracker zu
        einem eindeutigen, aber nicht einer Person zugeordneten Schlüssel umgewandelt. Eine andere Verwendung,
        Zusammenführung mit anderen Daten oder eine Weitergabe an Dritte erfolgt durch etracker nicht.</p>
      <p>Sie können der vorbeschriebenen Datenverarbeitung jederzeit widersprechen, soweit sie personenbezogen erfolgt.
        Ihr Widerspruch hat für Sie keine nachteiligen Folgen.</p>
      <p>Weitere Informationen zum Datenschutz bei etracker finden Sie <a href="https://www.etracker.com/datenschutz/"
                                                                          target="_blank"
                                                                          rel="noopener noreferrer">hier</a>.</p>
      <div class="uc-embed" uc-layout="1" uc-data="all"></div>

      <h2>9 Sicherheit</h2>
      <p>Wir haben technische und administrative Sicherheitsvorkehrungen getroffen, um Ihre personenbezogenen Daten
        gegen Verlust, Zerstörung, Manipulation und unautorisierten Zugriff zu schützen. All unsere Mitarbeiter sowie
        für uns tätige Dienstleister sind auf die gültigen Datenschutzgesetze verpflichtet.</p>
      <p>Wann immer wir personenbezogene Daten sammeln und verarbeiten, werden diese verschlüsselt bevor sie übertragen
        werden. Das heißt, dass Ihre Daten nicht von Dritten missbraucht werden können. Unsere Sicherheitsvorkehrungen
        unterliegen dabei einem ständigen Verbesserungsprozess und unsere Datenschutzerklärungen werden ständig
        überarbeitet. Bitte stellen Sie sicher, dass Ihnen die aktuellste Version vorliegt.</p>
      <h2>10 Betroffenenrechte</h2>
      <p>Bitte kontaktieren Sie uns jederzeit, wenn Sie sich informieren möchten, welche personenbezogenen Daten wir
        über Sie speichern (Art. 15 DS-GVO) bzw. wenn Sie diese berichtigen (Art. 16 DS-GVO) oder löschen (Art. 17
        DS-GVO) lassen wollen. Des Weiteren haben Sie das Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO), ein
        Widerspruchsrecht gegen die Verarbeitung (Art. 21 DSGVO) sowie das Recht auf Datenübertragbarkeit (Art. 20
        DSGVO).</p>
      <p>Bitte senden Sie uns hierfür eine E-Mail an: <a href="mailto:dialog@n-ergie.de">dialog@n-ergie.de</a> oder
        wenden Sie sich schriftlich an: N-ERGIE Aktiengesellschaft, Am Plärrer 43, 90429 Nürnberg.</p>
      <p>Gemäß Art. 77 DS-GVO haben Sie ferner ein Recht auf Beschwerde bei der für Ihr Bundesland zuständigen
        Aufsichtsbehörde.</p>
      <p>Widerspruchsrecht <br> Sofern wir eine Verarbeitung von Daten zur Wahrung unserer berechtigten Interessen
        vornehmen, haben Sie aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit das Recht, gegen
        diese Verarbeitung Widerspruch einzulegen. Das umfasst auch das Recht Widerspruch gegen die Verarbeitung zu
        Werbezwecken einzulegen.</p>
      <h2>11 Änderung der Datenschutzbestimmungen</h2>
      <p>Wir behalten uns das Recht vor, unsere Datenschutzerklärungen zu ändern, falls dies aufgrund neuer Technologien
        notwendig sein sollte. Bitte stellen Sie sicher, dass Ihnen die aktuellste Version vorliegt. Werden an dieser
        Datenschutzerklärung grundlegende Änderungen vorgenommen, geben wir diese auf unserer Webseite bekannt.</p>
      <h2>12 Datenschutzbeauftragter</h2>
      <p>Haben Sie Fragen zum Datenschutz? Dann kontaktieren Sie uns gerne.</p>
      <p>
        N-ERGIE Aktiengesellschaft<br>
        Datenschutzbeauftragter<br>
        Am Plärrer 43<br>
        90429 Nürnberg<br>
        Telefon: 0911 802-01<br>
        Telefax: 0911 802-3780<br>
        E-Mail: <a href="mailto:datenschutz@n-ergie.de">datenschutz@n-ergie.de</a>
      </p>
      <p>Mit den Sicherheitszertifikaten wird das Verschlüsseln und digitale Signieren von Nachrichten und damit ein
        sicheres Austauschen von Informationen ermöglicht. Für die Nutzung muss keine spezielle Software installiert
        werden.</p>
    </Modal>

    <Modal id="contact" v-if="showContact" @close="showContact = false">
      <h2>Kontakt</h2>
      <p>Sie haben Fragen, Anregungen oder Ideen zu unserem Spiel &bdquo;Hallo, Wasserwissen!&rdquo;?</p>
      <p>Wir freuen uns über Ihre Nachricht:</p>
      <p><a href="mailto:schulinformation@n-ergie.de">schulinformation@n-ergie.de</a></p>
      <p>
        Auf unserer Internetseite der Schulinformation können Sie weiteres Unterrichtsmaterial zum Thema Wasser herunterladen. Besuchen Sie uns dazu unter folgendem
        <a href="https://www.n-ergie.de/n-ergie/schulinformation/unterrichtsmaterial/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zifT2dDQyd_A18_Q3cjQwczd0CLUxdLYxMA0z0w_EpcLcw1Y8iRr8BCnA0cAoycjI2MHD3NyJHP7JJxOnHoyAKv_Hh-lH4rHC3NCWgwN-MgAILmAn4AomQMwtyQ0NDIwwyPTMdFQEBxoi8/dz/d5/L2dBISEvZ0FBIS9nQSEh/" target="_blank">Link</a>
        .
      </p>
    </Modal>

    <Modal id="bildnachweis" v-if="showPictureProof" @close="showPictureProof = false">
        <PictureProofList></PictureProofList>
    </Modal>
  </div>
</template>

<script>
import Preloader from "@/script/preload";
import basicAssets from "@/assets/basicAssets";
import LoadingScreen from "@/components/LoadingScreen";
import Modal from "@/components/Modal";
import Logo from "@/components/interface/logo";
import btn from "@/components/interface/btn"
import PictureProofList from "@/components/PictureProofList";

export default {
  'components': {
    PictureProofList,
    Logo,
    LoadingScreen,
    Modal,
    btn
  },
  data: () => {
    return {
      'viewHeight': 924,
      'viewWidth': 1440,
      'viewIsToSmall': false,
      'viewScale': 'unset',
      'viewportOrientation': 'unset',
      'selectedLevel': null,
      'loadingPercentage': 0,
      'preloadReady': false,
      'showImpress': false,
      'showDatenschutz': false,
      'showContact': false,
      'showPictureProof': false,
      'uniqueResetKey': 0,
    }
  },
  computed: {
    selectedPlayer() {
      return this.$store.getters.selectedPlayer;
    },
    selectedLevelConfig() {
      if (this.selectedLevel && this.selectedPlayer) {
        let levelConfig = this.selectedLevel(this.selectedPlayer);
        // console.log('LEVEL CONFIG | ', levelConfig);
        return levelConfig;
      }
      return null
    }
  },
  watch: {
    $route(newRoute) {
      this.enterLevelByRoute(newRoute);
    }
  },
  methods: {
    levelRequest(levelId) {
      if (this.$store.getters.availableLevels[levelId]) {
        this.startLevel(levelId);
      }
    },

    startLevel(levelId) {
      this.uniqueResetKey = parseInt(Math.random() * 100000);
      this.selectedLevel = this.$store.getters.availableLevels[levelId];
      this.$router.push({'name': 'Level'});
    },

    onLevelEnd(nextLevelId) {
      if (nextLevelId === 'END_OF_GAME' || this.$store.getters.allLevelsFinished) {
        if (this.$store.getters.allLevelsFinished) {
          this.$router.push({'name': 'Summary'});
        } else {
          this.$router.push({
            'name': 'Dashboard',
            'query': {'levelselect': this.$store.getters.firstUnfinishedLevelIndex}
          });
        }
      } else {
        this.startLevel(nextLevelId);
      }
    },

    preloadAssets() {
      Preloader(basicAssets, (percentage) => {
        this.loadingPercentage = percentage;
        if (percentage === 1) {
          this.preloadReady = true;
        }
      });
    },
    setResizeListener() {
      // debounce will run, after having stopped resizing for a while
      // throttle will run while resizing, but only every now and then
      let resizeDebounce = 0;
      let resizeThrottle = 0;
      window.addEventListener('resize', () => {
        let now = (new Date()).getTime();
        clearTimeout(resizeDebounce);
        resizeDebounce = setTimeout(this.onResize.bind(this), 250);
        if (now - resizeThrottle > 100) {
          resizeThrottle = now;
          this.onResize.call(this);
        }
      });
    },
    onResize() {
      this.checkViewportSize();
      this.updateViewScale();
      this.updateOrientation();
    },
    checkViewportSize() {
      this.viewIsToSmall = (window.innerWidth < this.viewWidth / 2) || (window.innerHeight < this.viewHeight / 2);
    },
    updateViewScale() {
      this.viewScale = Math.min(window.innerWidth / this.viewWidth, window.innerHeight / this.viewHeight);
    },
    updateOrientation() {
      this.viewportOrientation = (window.innerWidth / window.innerHeight) < 1 ? 'orientation-portrait' : 'orientation-landscape';
    },
    enterLevelByRoute(route) {
      // console.log('ROUTE update |', newRoute);
      let dev_level = route.query['l'];
      let dev_player = route.query['p'];
      let full_score = route.query['a'];

      if (dev_level && dev_player) {
        console.log('DEV SETUP | Level:', dev_level, 'Player:', dev_player);
        let availableLevels = this.$store.getters.availableLevels;
        this.$store.commit('selectPlayer', dev_player);
        this.selectedLevel = availableLevels[dev_level];
      }

      if (full_score) {
        Object.values(this.$store.getters.availableLevels).forEach((levelConfig) => {
          levelConfig = levelConfig();

          this.$store.commit('setLevelProgress', {
            'levelId': levelConfig.id,
            'status': 'finished',
            'score': levelConfig.availableScore
          });
        });
      }
    },

    showUserCentricsLayer(){
      try {
        window.UC_UI.showSecondLayer();
      } catch (e) {
        //
      }
    },
  },

  beforeCreate() {
    // console.log('APP | beforeCreate');
  },

  created() {
    this.preloadAssets();
    this.$store.commit('loadPersistedGame');
    this.enterLevelByRoute(this.$route);
  },

  mounted() {
    this.setResizeListener();
    this.onResize(); //call once
  }
}
</script>

<style lang="scss">
@import "~normalize.css/normalize";
@import "style/variables";
@import "style/mixins";
@import "style/font";
@import "style/icon";
@import "style/animations";

// small reset
* {
  box-sizing: border-box;
}

*, h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6, strong {
  font-family: $font-bold;
  font-weight: normal;
}

a {
  text-decoration: none;
  color: $text-color-dark;
}

body {
  overflow: hidden;
  height: 100%;
  background: #000;
  font-family: $font-regular;
  font-weight: normal;
  line-height: 1.5;
}

#app {
  overflow: hidden;
  background: #757575;
  color: #fff;

  height: var(--view-height);
  width: var(--view-width);

  position: absolute;
  left: 50%;
  top: 50%;

  transform: scale(var(--view-scale)) translate(-50%, -50%);
  transform-origin: left top;

  //&.orientation-landscape{
  //}

  //&.orientation-portrait{
  //}

  strong {
    font-family: $font-bold;
    font-weight: normal;
  }
}

#game-container {
  position: relative;
  z-index: 0;
  height: calc(100% - 24px);
}

#footer {
  position: relative;
  z-index: 2000;
  font-style: $font-regular;
  height: 24px;
  background: #FFF;
  font-size: 12px;
  padding: 2px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a:hover {
    color: #F00000;
  }
}

#impress,
#datenschutz,
#contact {
  a {
    text-decoration: underline;
    color: $color-red;
  }
}

#impress,
#datenschutz {
  p {
    margin: 1em 0;
  }
}

#contact {
  h2 {
    text-align: center;
  }

  p {
    margin: 1em 0;
  }
}

#bildnachweis {
  .modal-dialog{
    width: 1000px;
  }

}

#cookie-layer {
  position: fixed;
  z-index: 2900;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #fff;
  padding: 40px 34% 24px 40px;
  color: $text-color-dark;
  font-family: $font-bold;

  a {
    display: block;
    margin-top: 1em;
    text-transform: uppercase;
  }

  .button {
    position: absolute;
    right: 40px;
    min-width: 400px;

    &:first-of-type {
      top: 40px;
    }
    &:last-of-type {
      bottom: 40px;
    }
  }
}

#view-to-small {
  position: absolute;
  z-index: 2000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;

  .logo {
    position: absolute;
    left: 0;
    top: 15px;
    right: 0;
    z-index: 2001;
  }

  .modal {
    .modal-dialog {
      width: 400px;
      text-align: center;
      padding: 30px;

      .close {
        display: none;
      }

      h2 {
        font-size: 35px;
        margin-bottom: 20px;
      }

      .text {
        font-family: $font-bold;
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }

  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.9)
  }
}
</style>
