<template>
  <transition-group tag="div" class="dialogue-text-container" :class="'layout--'+layoutConditioned" name="dialogue-text-transition" @after-leave="onClearEnd">
    <div class="dialogue-text" v-for="(dialogueText, index) in dialogueTexts" v-html="dialogueText.text" :key="index" :class="'dialogue-text--'+dialogueText.character"></div>
  </transition-group>
</template>

<script>
  export default {
    name: 'Dialogue',
    props: {
      'dialogueTexts': Array,
      'layout': {
        'type': String,
        'default': 'centered' // alt: 'sides'
      }
    },
    data: () => {
      return {
        'isClearing': false,
        'layoutConditioned': 'centered'
      }
    },
    computed: {

    },
    watch: {
      dialogueTexts(newTexts, oldTexts){
        if (oldTexts.length > 0 && newTexts.length === 0){
          this.isClearing = true;
        }
      },
      layout(newLayout){
        if (!this.isClearing){
          this.layoutConditioned = newLayout;
        }
      }
    },
    methods: {
      onClearEnd(){
        this.isClearing = false;
        this.layoutConditioned = this.layout;
      }
    },
    created(){
      this.layoutConditioned = this.layout;
    }
  }
</script>

<style lang="scss" scoped>
@import "src/style/variables";
@import "src/style/mixins";

.dialogue-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;

  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 170px;
  padding-top: 64px;
}

.dialogue-text {
  position: relative;
  width: 468px;
  max-height: 333px;
  padding: 24px 32px;
  border-radius: 12px;
  box-shadow: 0 9px 10px 2px rgba(0,0,0,0.27);

  transition:
      max-height $transition-duration ease-in-out,
      margin-top $transition-duration ease-in-out,
      margin-bottom $transition-duration ease-in-out,
      margin-left $transition-duration ease-in-out (2 * $transition-duration),
      margin-right $transition-duration ease-in-out (2 * $transition-duration),
      padding $transition-duration ease-in-out,
      opacity $transition-duration ease-out $transition-duration;

  .layout--sides & {
    width: 370px;
    text-align: center;
  }

  &:after {
    content: '';
    height: 24px;
    width: 48px;
    position: absolute;
    bottom: -22px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center top;
  }

  &.dialogue-text--player {
    background-color: $color-red-dark;
    color: #fff;
    margin-left: 95px;
    border-bottom-right-radius: 0;

    &:after {
      right: -6px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjU5IiB2aWV3Qm94PSIwIDAgMTAxIDU5IiB3aWR0aD0iMTAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48ZmlsdGVyIGlkPSJhIiBoZWlnaHQ9IjIwMS42JSIgd2lkdGg9IjE3My45JSIgeD0iLTQzLjYlIiB5PSItNDguOCUiPjxmZU1vcnBob2xvZ3kgaW49IlNvdXJjZUFscGhhIiBvcGVyYXRvcj0iZGlsYXRlIiByYWRpdXM9IjEuNSIgcmVzdWx0PSJzaGFkb3dTcHJlYWRPdXRlcjEiLz48ZmVPZmZzZXQgZHg9IjAiIGR5PSItMSIgaW49InNoYWRvd1NwcmVhZE91dGVyMSIgcmVzdWx0PSJzaGFkb3dPZmZzZXRPdXRlcjEiLz48ZmVHYXVzc2lhbkJsdXIgaW49InNoYWRvd09mZnNldE91dGVyMSIgcmVzdWx0PSJzaGFkb3dCbHVyT3V0ZXIxIiBzdGREZXZpYXRpb249IjUiLz48ZmVDb2xvck1hdHJpeCBpbj0ic2hhZG93Qmx1ck91dGVyMSIgdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAgIDAgMCAwIDAgMCAgIDAgMCAwIDAgMCAgMCAwIDAgMC4zOTg5MDE4NzkgMCIvPjwvZmlsdGVyPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMikiPjxwYXRoIGQ9Im04NiAxOS40MjI3MTA5djI3LjU3NzI4OTFoLTY2bDU4LjYwNTczOTMtMzEuOTY2NzY2OWMyLjQyNDI0MTctMS4zMjIzMTM3IDUuNDYxNDI0OS0uNDI5MDI0NSA2Ljc4MzczODYgMS45OTUyMTcyLjQwMDYwNTIuNzM0NDQzLjYxMDUyMjEgMS41NTc2NjU3LjYxMDUyMjEgMi4zOTQyNjA2eiIgZmlsbD0iIzAwMCIgZmlsdGVyPSJ1cmwoI2EpIiB0cmFuc2Zvcm09Im1hdHJpeCgxIDAgMCAtMSAwIDU4KSIvPjxwYXRoIGQ9Im04NiA4LjQzMDUyOTE4djM4LjU2OTQ3MDgyaC04Nmw3OC42MDIxNjU2LTQyLjk1Njk5NzUxYzIuNDIzMTY0MS0xLjMyNDI4NzM0IDUuNDYxMDczNy0uNDMzNDcxNzQgNi43ODUzNjExIDEuOTg5NjkyMzQuNDAxODY0My43MzUzMjYyOC42MTI0NzMzIDEuNTU5ODYwNzguNjEyNDczMyAyLjM5NzgzNDM1eiIgZmlsbD0iIzc1MTkzNyIgdHJhbnNmb3JtPSJtYXRyaXgoMSAwIDAgLTEgMCA0NykiLz48L2c+PC9zdmc+");
    }

    .layout--sides & {
      margin-left: 1030px;
      border-bottom-right-radius: 12px;

      &:after {
        content: none;
      }
    }
  }

  &.dialogue-text--robot {
    background-color: #fff;
    color: $color-blue-dark;
    margin-right: 95px;
    border-bottom-left-radius: 0;

    &:after {
      left: -6px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjU5IiB2aWV3Qm94PSIwIDAgMTAxIDU5IiB3aWR0aD0iMTAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48ZmlsdGVyIGlkPSJhIiBoZWlnaHQ9IjIwMS42JSIgd2lkdGg9IjE3My45JSIgeD0iLTQzLjYlIiB5PSItNDguOCUiPjxmZU1vcnBob2xvZ3kgaW49IlNvdXJjZUFscGhhIiBvcGVyYXRvcj0iZGlsYXRlIiByYWRpdXM9IjEuNSIgcmVzdWx0PSJzaGFkb3dTcHJlYWRPdXRlcjEiLz48ZmVPZmZzZXQgZHg9IjAiIGR5PSItMSIgaW49InNoYWRvd1NwcmVhZE91dGVyMSIgcmVzdWx0PSJzaGFkb3dPZmZzZXRPdXRlcjEiLz48ZmVHYXVzc2lhbkJsdXIgaW49InNoYWRvd09mZnNldE91dGVyMSIgcmVzdWx0PSJzaGFkb3dCbHVyT3V0ZXIxIiBzdGREZXZpYXRpb249IjUiLz48ZmVDb2xvck1hdHJpeCBpbj0ic2hhZG93Qmx1ck91dGVyMSIgdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAgIDAgMCAwIDAgMCAgIDAgMCAwIDAgMCAgMCAwIDAgMC4zOTg5MDE4NzkgMCIvPjwvZmlsdGVyPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgOTkgMCkiPjxwYXRoIGQ9Im04NiAxOS40MjI3MTA5djI3LjU3NzI4OTFoLTY2bDU4LjYwNTczOTMtMzEuOTY2NzY2OWMyLjQyNDI0MTctMS4zMjIzMTM3IDUuNDYxNDI0OS0uNDI5MDI0NSA2Ljc4MzczODYgMS45OTUyMTcyLjQwMDYwNTIuNzM0NDQzLjYxMDUyMjEgMS41NTc2NjU3LjYxMDUyMjEgMi4zOTQyNjA2eiIgZmlsbD0iIzAwMCIgZmlsdGVyPSJ1cmwoI2EpIiB0cmFuc2Zvcm09Im1hdHJpeCgxIDAgMCAtMSAwIDU4KSIvPjxwYXRoIGQ9Im04NiA4LjQzMDUyOTE4djM4LjU2OTQ3MDgyaC04Nmw3OC42MDIxNjU2LTQyLjk1Njk5NzUxYzIuNDIzMTY0MS0xLjMyNDI4NzM0IDUuNDYxMDczNy0uNDMzNDcxNzQgNi43ODUzNjExIDEuOTg5NjkyMzQuNDAxODY0My43MzUzMjYyOC42MTI0NzMzIDEuNTU5ODYwNzguNjEyNDczMyAyLjM5NzgzNDM1eiIgZmlsbD0iI2ZmZiIgdHJhbnNmb3JtPSJtYXRyaXgoMSAwIDAgLTEgMCA0NykiLz48L2c+PC9zdmc+");
    }

    .layout--sides & {
      margin-right: 1030px;
      border-bottom-left-radius: 12px;


      &:after {
        content: none;
      }
    }
  }

  &:not(:last-child){
    margin-bottom: 20px;
  }

  &:not(:nth-last-child(1)):not(:nth-last-child(2)){
    max-height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;

    transition-delay: 0s;
  }
}

.dialogue-text-transition-enter {
  max-height: 0;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}

.dialogue-text-transition-leave-to {
  opacity: 0;
}

</style>