const Preloader = function (sources, callback) {
  // console.log('PRELOADER | Requested:', sources);
  let totalCount = 0;
  let loadedCount = 0;

  load();

  function load(){

    Object.values(sources).forEach((assetCategory)=>{
      Object.values(assetCategory).forEach((path)=>{
        getRessource(path);
      });
    });

  }

  function getRessource(path){
    totalCount++;
    let request = new XMLHttpRequest();
    request.open('GET', path, true);
    request.onreadystatechange = () => {
      if (request.readyState === XMLHttpRequest.DONE) {
        onSourceLoad();
      }
      // TODO: what if something goes wrong?
    }
    request.send();
    // console.log('PRELOADER | Getting:', path);
  }

  function onSourceLoad(){
    loadedCount++;
    // console.log('PRELOADER | Source loaded:', loadedCount, 'of', totalCount);
    let percentage = loadedCount / totalCount;
    callback(percentage);

    // if (loadedCount === totalCount){
    //   callback();
    // }
  }
};

export default Preloader;