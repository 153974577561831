import levelAchievements from "@/levels/levelAchievements";
import basicLevelAssets from "@/assets/basicLevelAssets";

let baseConfig = {
    'id': 'Level_3',
    'title': 'Level 3',
    'subtitle': 'Unser Wetter',
    'description': 'Wasserkreislauf',
    'levelSliderBackground': basicLevelAssets['images']['levelSliderBackground-level_3'],
    'availableScore': 3,
    'achievement': levelAchievements['backpack'],
    'nextLevelId': 'Level_4'
};

export const config = baseConfig;

export default (selectedPlayer = 'player-nici') => {

    let sounds = {
        'beaming': '/sounds/Menu_General/0-Multi-Mobil-Einsteigen-Beamen-208533__slag777__space-sweep.mp3',
        'ambient': '/sounds/Level_3/3-0-Backgroundambience-150174__deleted-user-229898__wind-in-the-trees_01.mp3',
        'rain': '/sounds/Level_3/3-1-Wetterwechsel-Rain-Sequence-545250__legnalegna55__rain-sound.mp3',
        'flying': '/sounds/Menu_General/0-Multi-Mobil-Flug-Start-Flight-Space-Earth__awr1001__jet-airplane.mp3',
    }

    let images = {
        'experiment-3-1': '/images/experiments/ex-3-1.jpg',
        'experiment-3-2': '/images/experiments/ex-3-2.jpg',
        'experiment-3-3': '/images/experiments/ex-3-3.jpg',
        'experiment-3-4': '/images/experiments/ex-3-4.jpg',
        'experiment-3-5': '/images/experiments/ex-3-5.jpg',
    }

    let animations = {
        'background': {

            // Nick und Rosa stehen auf dem Gipfel des Hügels und sehen auf die Naturszenerie unter ihnen.
            'mountain': {
                'path': '/animations/level-3/Berg/Berg.json',
                'loopStartFrame': 0,
                'loopEndFrame': (24 * 1) - 1
            },

            // In der Landschaft wird verdunstendes Wasser angedeutet.
            'steam': {
                'path': '/animations/level-3/Berg/Berg.json',
                'loopStartFrame': (24 * 1),
                'loopEndFrame': (24 * 3) - 1
            },

            // In der Landschaft bilden sich Wolken.
            'cloud': {
                'path': '/animations/level-3/Berg/Berg.json',
                'startFrame': (24 * 3),
                'loopStartFrame': (24 * 4),
                'loopEndFrame': (24 * 5) - 1
            },

            // Aus den Wolken regnet es, die Wolken lösen sich auf.
            'rain': {
                'path': '/animations/level-3/Berg/Berg.json',
                'loopStartFrame': (24 * 5) - 1,
                'loopEndFrame': (24 * 6) - 1
            },

            'sunny': {
                'path': '/animations/level-3/Berg/Berg.json',
                'startFrame': (24 * 6),
                'loopStartFrame': (24 * 7),
                'loopEndFrame': (24 * 8) -1,
            },

            // Der Wasserkreislauf wird im Loop dargestellt.
            'loop': {
                'path': '/animations/level-3/Berg/Berg.json',
                'loopStartFrame': 0,
                'loopEndFrame': (24 * 7) - 1,
            },


            'sunny-2': {
                'path': '/animations/level-3/Berg/Berg.json',
                'loopStartFrame': (24 * 7),
                'loopEndFrame': (24 * 8) -1,
            },

            'level-end': {
                'path': '/animations/level-3/Berg/Berg.json',
                'startFrame': (24 * 8),
                'loopStartFrame': (24 * 9),
                'loopEndFrame': (24 * 10) - 1
            },
        },
        'robot': {
            'idle': {
                'path': '/animations/robot/Rosa-default/Rosa-default.json',
                'startFrame': 0,
                'endFrame': (24 * 2) - 1
            },

            'talk': {
                'path': '/animations/robot/Rosa-sprechen/Rosa-sprechen.json',
                'loopStartFrame': 0,
                'loopEndFrame': (24 * 2) - 1,
            },

            'joy': {
                'path': '/animations/robot/Rosa-Freude/Rosa-freude.json',
                'startFrame': 0,
                'loopStartFrame': (24 * 2) - 1,
                'loopEndFrame': (24 * 4) - 1,
                'endFrame': (24 * 6) - 1,
            },

        },
        'player': {
            'player-nici': {
                'idle': {
                    'path': '/animations/player-nici/Nici-default/Nici-default.json'
                },

                'talk': {
                    'path': '/animations/player-nici/Nici-sprechen/Nici-sprechen.json'
                },

                'joy': {
                    'path': '/animations/player-nici/Nici-freude-full/Nici-freude-full.json',
                    'startFrame': 0,
                    'loopStartFrame': (24 * 2) - 1,
                    'loopEndFrame': (24 * 4) - 1,
                    'endFrame': (24 * 5) - 1,
                },
                'beam-to-space': {
                    'path': '/animations/player-nici/Abflug-multimobil-Nici/Abfahrt-multimobil-Nici.json',
                }

            },
            'player-nick': {
                'idle': {
                    'path': '/animations/player-nick/Nick-default/Nick-default.json'
                },

                'talk': {
                    'path': '/animations/player-nick/Nick-sprechen/Nick-sprechen.json'
                },

                'joy': {
                    'path': '/animations/player-nick/Nick-freude-full/Nick-freude-full.json',
                    'startFrame': 0,
                    'loopStartFrame': (24 * 2) - 1,
                    'loopEndFrame': (24 * 4) - 1,
                    'endFrame': (24 * 5) - 1,
                },
                'beam-to-space': {
                    'path': '/animations/player-nick/Abflug-multimobil-Nick/Abfahrt-multimobil-Nick.json',
                }
            }
        }
    }
    animations["player"] = animations["player"][selectedPlayer];

    let speech = {
        'robot': {
            'd1-1': '/speech/robot/RoSa 38.mp3',
            'd1-2': '/speech/robot/RoSa 39.mp3',
            'd1-3': '/speech/robot/RoSa 40.mp3',
            'd1-4': '/speech/robot/RoSa 41.mp3',
            'quiz-1': '/speech/robot/RoSa 42-1.mp3',
            'quiz-1-hint': '/speech/robot/RoSa 42-2.mp3',
            'd2-1': '/speech/robot/RoSa 43.mp3',
            'd2-2': '/speech/robot/RoSa 44.mp3',
            'quiz-2': '/speech/robot/RoSa 45-1.mp3',
            'quiz-2-hint': '/speech/robot/RoSa 45-2.mp3',
            'd3-1': '/speech/robot/RoSa 46.mp3',
            'd3-2': '/speech/robot/RoSa 47.mp3',
            'quiz-3': '/speech/robot/RoSa 48-1.mp3',
            'quiz-3-hint': '/speech/robot/RoSa 48-2.mp3',
            'd4-1': '/speech/robot/RoSa 49.mp3',
            'd4-2': '/speech/robot/RoSa 50.mp3',
            'd4-3': '/speech/robot/RoSa 51.mp3',
            'd4-4': '/speech/robot/RoSa 52.mp3',
            'd4-5': '/speech/robot/RoSa 53.mp3',
        },
        'player': {
            'player-nici': {
                'd1-1': '/speech/nici/Julie 25.mp3',
                'd1-2': '/speech/nici/Julie 26.mp3',
                'd1-3': '/speech/nici/Julie 27.mp3',
                'd1-4': '/speech/nici/Julie 28.mp3',
                'd2-1': '/speech/nici/Julie 29.mp3',
                'd2-2': '/speech/nici/Julie 30.mp3',
                'd3-1': '/speech/nici/Julie 31.mp3',
                'd4-1': '/speech/nici/Julie 32.mp3',
                'd4-2': '/speech/nici/Julie 33.mp3',
                'd4-3': '/speech/nici/Julie 34.mp3',
            },
            'player-nick': {
                'd1-1': '/speech/nick/Nils 25.mp3',
                'd1-2': '/speech/nick/Nils 26.mp3',
                'd1-3': '/speech/nick/Nils 27.mp3',
                'd1-4': '/speech/nick/Nils 28.mp3',
                'd2-1': '/speech/nick/Nils 29.mp3',
                'd2-2': '/speech/nick/Nils 30.mp3',
                'd3-1': '/speech/nick/Nils 31.mp3',
                'd4-1': '/speech/nick/Nils 32.mp3',
                'd4-2': '/speech/nick/Nils 33.mp3',
                'd4-3': '/speech/nick/Nils 34.mp3',
            }
        }
    }
    speech["player"] = speech["player"][selectedPlayer];

    let dialogues = {

        'player-d1-1': {
            'character': 'player',
            'text': 'Wow, das ist mal eine tolle Aussicht!',
            'audio': speech["player"]["d1-1"],
            'animation': animations["player"]["talk"],
            'animationAfter': animations["player"]["idle"]
        },
        'robot-d1-1': {
            'character': 'robot',
            'text': 'Nicht nur das. Von hier oben können wir genau sehen, wie das mit dem <strong>Wasserkreislauf</strong> funktioniert. Simulation … starten!',
            'audio': speech["robot"]["d1-1"],
            'animation': animations["robot"]["talk"],
            'animationAfter': animations["robot"]["idle"]
        },
        'robot-d1-2': {
            'character': 'robot',
            'text': 'Weißt du noch, was hier passiert?',
            'audio': speech["robot"]["d1-2"],
            'animation': animations["robot"]["talk"],
            'animationAfter': animations["robot"]["idle"]
        },
        'player-d1-2': {
            'character': 'player',
            'text': 'Klar, haben wir ja gerade gelernt: Das Wasser erhitzt sich durch die Wärme der Sonne und verdunstet. Es wird gasförmig und steigt als <strong>Wasserdampf</strong> nach oben.',
            'audio': speech["player"]["d1-2"],
            'animation': animations["player"]["talk"],
            'animationAfter': animations["player"]["idle"]
        },
        'player-d1-3': {
            'character': 'player',
            'text': 'Aber was dann? Verschwindet es dann nicht im Himmel? Oder im Universum?!',
            'audio': speech["player"]["d1-3"],
            'animation': animations["player"]["talk"],
            'animationAfter': animations["player"]["idle"]
        },
        'robot-d1-3': {
            'character': 'robot',
            'text': 'Zum Glück eben nicht! In einer bestimmten Höhe über der Erde ist die Luft so kalt, dass der Wasserdampf abkühlt und kondensiert – sich also wieder zu Wassertröpfchen verdichtet. Wie an der Plastikfolie auf der Schale.',
            'audio': speech["robot"]["d1-3"],
            'animation': animations["robot"]["talk"],
            'animationAfter': animations["robot"]["idle"]
        },
        'robot-d1-4': {
            'character': 'robot',
            'text': 'So entstehen dann die <strong>Wolken</strong>, die wir sehen können.',
            'audio': speech["robot"]["d1-4"],
            'animation': animations["robot"]["talk"],
            'animationAfter': animations["robot"]["idle"]
        },
        'player-d1-4': {
            'character': 'player',
            'text': 'Verstehe! Das Wasser bleibt in der <strong>Atmosphäre</strong> um die Erde, weil es oben wieder abkühlt. Was passiert denn dann mit den Wolken?',
            'audio': speech["player"]["d1-4"],
            'animation': animations["player"]["talk"],
            'animationAfter': animations["player"]["idle"]
        },
        'robot-quiz-hint-1': {
            'character': 'robot',
            'text': 'Erinnerst du dich an den Versuch mit der Frischhaltefolie? Wenn sich Wassertropfen an der Folie gebildet hatten, dann sind sie wieder heruntergetropft.',
            'audio': speech["robot"]["quiz-1-hint"],
            // no animations
        },
        'robot-d2-1': {
            'character': 'robot',
            'text': 'Es sammeln sich immer mehr winzige Tröpfchen in der Wolke, bis sie das ganze Wasser nicht mehr halten kann. Dann regnet das Wasser aus der Wolke wieder auf die Erde zurück.',
            'audio': speech["robot"]["d2-1"],
            'animation': animations["robot"]["talk"],
            'animationAfter': animations["robot"]["idle"]
        },
        'player-d2-1': {
            'character': 'player',
            'text': 'Ah, so entsteht also <strong>Regen</strong> – wenn die Wolken zu schwer geworden sind...',
            'audio': speech["player"]["d2-1"],
            'animation': animations["player"]["talk"],
            'animationAfter': animations["player"]["idle"]
        },
        'robot-d2-2': {
            'character': 'robot',
            'text': 'Nicht nur Regen – je nach Temperatur und Wetter entsteht so <strong>Regen, Schnee</strong> oder auch <strong>Hagel</strong>. Und alles drei nennt man <strong>„Niederschlag“</strong>.',
            'audio': speech["robot"]["d2-2"],
            'animation': animations["robot"]["talk"],
            'animationAfter': animations["robot"]["idle"]
        },
        'player-d2-2': {
            'character': 'player',
            'text': 'Und durch den Niederschlag fällt das Wasser also wieder auf die Erde!',
            'audio': speech["player"]["d2-2"],
            'animation': animations["player"]["talk"],
            'animationAfter': animations["player"]["idle"]
        },
        'robot-quiz-hint-2': {
            'character': 'robot',
            'text': 'Zuerst wird Wasser durch Wärme gasförmig, dann in den Wolken wieder flüssig, dann fällt es als Niederschlag zurück auf die Erde.',
            'audio': speech["robot"]["quiz-2-hint"],
            // no animations
        },
        'robot-d3-1': {
            'character': 'robot',
            'text': 'Stimmt genau! Zusammengefasst: Das Wasser verdunstet durch die Sonne und steigt nach oben. Dort bilden sich Wolken, weil das Wasser kondensiert. Durch den Niederschlag kommt das Wasser wieder auf die Erde.<br> Dann beginnt der Kreislauf wieder von vorne.',
            'audio': speech["robot"]["d3-1"],
            'animation': animations["robot"]["talk"],
            'animationAfter': animations["robot"]["idle"]
        },
        'robot-d3-2': {
            'character': 'robot',
            'text': 'Der Niederschlag, welcher nicht in Flüssen, Seen und im Meer landet, versickert dann im Erdboden und durchläuft verschiedene <strong>Erdschichten</strong>. Dadurch wird das Wasser <strong>gesäubert</strong>. So entsteht das <strong>„Grundwasser“</strong> in der Erde.<br> Wie das geht, zeig ich dir am besten in einem Versuch.',
            'audio': speech["robot"]["d3-2"],
            'animation': animations["robot"]["talk"],
            'animationAfter': animations["robot"]["idle"]
        },
        'player-d3-1': {
            'character': 'player',
            'text': 'Na dann leg mal los!',
            'audio': speech["player"]["d3-1"],
            'animation': animations["player"]["talk"],
            'animationAfter': animations["player"]["idle"]
        },
        'robot-quiz-hint-3': {
            'character': 'robot',
            'text': 'Ton und Mehl bilden eine dichtere Schicht als Kies.',
            'audio': speech["robot"]["quiz-3-hint"],
            // no animations
        },
        'robot-d4-1': {
            'character': 'robot',
            'text': 'Genau! Oberhalb der Tonschicht kann das Wasser am besten fließen.<br> <strong>Ton</strong> ist eine <strong>wasserundurchlässige Schicht</strong>, so dass kein Wasser durchfließen kann. In der Natur bildet sich oberhalb von Tonschichten das Grundwasser. Kommt das Wasser auf natürlichen Weg aus der Erde, nennt man es <strong>„Quelle“</strong>.<br> Und damit ihr Menschen es auch zu Hause benutzen könnt, wird das Grundwasser aus der Erde mit <strong>Brunnen</strong> nach oben befördert. Das passiert, wie wir schon gesehen haben, im <strong>Wasserwerk</strong>.',
            'audio': speech["robot"]["d4-1"],
            'animation': animations["robot"]["talk"],
            'animationAfter': animations["robot"]["idle"]
        },
        'robot-d4-2': {
            'character': 'robot',
            'text': 'Das versickerte Grundwasser fließt nach einiger Zeit wieder in tiefer gelegene Seen, Flüsse und das Meer – wo es wieder verdunsten kann. So schließt sich der Wasserkreislauf!',
            'audio': speech["robot"]["d4-2"],
            'animation': animations["robot"]["talk"],
            'animationAfter': animations["robot"]["idle"]
        },
        'player-d4-1': {
            'character': 'player',
            'text': 'Wow, da passiert ja eine ganze Menge einfach so von alleine.',
            'audio': speech["player"]["d4-1"],
            'animation': animations["player"]["talk"],
            'animationAfter': animations["player"]["idle"]
        },
        'robot-d4-3': {
            'character': 'robot',
            'text': 'Zum Glück. Sonst gäb’s die Erde so wie wir sie kennen gar nicht.',
            'audio': speech["robot"]["d4-3"],
            'animation': animations["robot"]["talk"],
            'animationAfter': animations["robot"]["idle"]
        },
        'player-d4-2': {
            'character': 'player',
            'text': 'Wie meinst du das?',
            'audio': speech["player"]["d4-2"],
            'animation': animations["player"]["talk"],
            'animationAfter': animations["player"]["idle"]
        },
        'robot-d4-4': {
            'character': 'robot',
            'text': 'Um das herauszufinden hab ich was ganz Besonderes vor! Einen Ausflug ins Weltall!',
            'audio': speech["robot"]["d4-4"],
            'animation': animations["robot"]["talk"],
            'animationAfter': animations["robot"]["idle"]
        },
        'player-d4-3': {
            'character': 'player',
            'text': 'Ins Weltall?! Kann das dein Multi-Mobil etwa auch? Unbedingt! Ich wollte schon immer mal Astronaut sein!',
            'audio': speech["player"]["d4-3"],
            'animation': animations["player"]["talk"],
            'animationAfter': animations["player"]["idle"]
        },
        'robot-d4-5': {
            'character': 'robot',
            'text': 'Na klar kann es das! Es ist ja schließlich ein Multi-Mobil! Los geht\'s.',
            'audio': speech["robot"]["d4-5"],
            'animation': animations["robot"]["talk"],
            'animationAfter': animations["robot"]["idle"]
        },
    }

    // console.log(Object.values(dialogues).reduce((accumulator, currentValue) => accumulator + '\n' + currentValue.text, ''));

    let defaultQuizReactions = {
        'correct-star': {
            'robot': {
                'text': 'Richtige Antwort beim ersten Versuch! Dafür verdienst du einen Stern.',
                'animation': animations["robot"]["joy"],
                'animationAfter': animations["robot"]["idle"],
            },
            'player': {
                'animation': animations["player"]["joy"],
                'animationAfter': animations["player"]["idle"]
            }
        },
        'correct': {
            'robot': {
                'text': 'Richtige Antwort! Gut gemacht.',
                'animation': animations["robot"]["joy"],
                'animationAfter': animations["robot"]["idle"],
            },
            'player': {
                'animation': animations["player"]["joy"],
                'animationAfter': animations["player"]["idle"]
            }
        },
        'wrong': {
            'robot': {
                'text': 'Das war leider nicht richtig. Versuch’s einfach nochmal!',
            }
        }
    }


    // //// //////////
    // ////  STEPS
    // //// //////////

    return {
        ...baseConfig,

        'preload': {
            animations,
            sounds,
            speech,
            images
        },

        'beforeLevelEnter': function (game, next) {
            next();
        },

        'steps': [
            // Level Setup
            {
                'beforeEnter': function (game, next) {

                    let animationCount = 0;
                    game.startAnimation('player', animations['player']['idle'], true, false, () => {
                        animationCount++
                        if (animationCount === 3) {
                            next();
                        }
                    });
                    game.startAnimation('robot', animations['robot']['idle'], true, false, () => {
                        animationCount++
                        if (animationCount === 3) {
                            next();
                        }
                    });
                    game.startAnimation('background', animations['background']['mountain'], true, false, () => {
                        animationCount++
                        if (animationCount === 3) {
                            next();
                        }
                    });

                    game.startAudio('background', sounds['ambient'], true);
                },

                // 'waitForUser': 'Level Starten'
            },
            {
                'type': 'tutorial'
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["player-d1-1"],
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["robot-d1-1"],
                'waitForUser': 'Weiter'
            },
            {
                'afterEnter': function (game, next) {
                    game.startAnimation('background', animations['background']['steam'], true, true, next);
                }
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["robot-d1-2"],
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["player-d1-2"],
                'waitForUser': 'Weiter'
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["player-d1-3"],
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["robot-d1-3"],
                'waitForUser': 'Weiter'
            },
            {
                'afterEnter': function (game, next) {
                    game.startAnimation('background', animations['background']['cloud'], true, true);
                    next();
                }
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["robot-d1-4"],
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["player-d1-4"],
                'waitForUser': 'Weiter'
            },
            // Quiz 1
            {
                'type': 'quiz',
                'quiz': {
                    'title': 'Frage 1',
                    'question': {
                        'text': 'Was passiert mit Wolken, wenn sich eine große Menge Wasser angesammelt hat?',
                        'audio': speech['robot']["quiz-1"]
                    },
                    'options': [
                        {
                            'text': 'Die Wolke friert ein',
                            'isCorrect': false
                        },
                        {
                            'text': 'Die Wolke löst sich auf',
                            'isCorrect': false
                        },
                        {
                            'text': 'Die Wolke regnet herunter',
                            'isCorrect': true
                        }
                    ],
                    'hint': {
                        'dialogue': dialogues['robot-quiz-hint-1']
                    },
                    'reactions': defaultQuizReactions
                }
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["robot-d2-1"],
            },
            {
                'afterEnter': function (game, next) {
                    game.startAnimation('background', animations['background']['rain'], true, true, ()=>{
                        game.startAudio('SFX', sounds['rain'], true, false);
                        next();
                    });
                }
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["player-d2-1"],
                'waitForUser': 'Weiter'
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["robot-d2-2"],
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["player-d2-2"],
                'waitForUser': 'Weiter'
            },
            // Quiz 2
            {
                'type': 'quiz',
                'quiz': {
                    'title': 'Frage 2',
                    'question': {
                        'text': 'In welcher Reihenfolge laufen die Prozesse des Wasserkreislaufs ab?',
                        'audio': speech['robot']["quiz-2"]
                    },
                    'options': [
                        {
                            'text': 'Kondensation, Verdunstung, Niederschlag',
                            'isCorrect': false
                        },
                        {
                            'text': 'Verdunstung, Kondensation, Niederschlag',
                            'isCorrect': true
                        },
                        {
                            'text': 'Niederschlag, Kondensation, Verdunstung',
                            'isCorrect': false
                        }
                    ],
                    'hint': {
                        'dialogue': dialogues['robot-quiz-hint-2']
                    },
                    'reactions': defaultQuizReactions
                }
            },
            {
                'afterEnter': function (game, next) {
                    game.requestAudioEnd('SFX', true); //end rain
                    game.startAnimation('background', animations['background']['sunny'], true, true, ()=>{
                        game.requestAnimationEnd('background', ()=>{
                            game.startAnimation('background', animations['background']['loop'], true, true, next);
                        })
                    });
                },
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["robot-d3-1"],
                'waitForUser': 'Weiter'
            },
            {
                'afterEnter': function (game, next) {
                    game.clearDialogueTexts();
                    game.startAnimation('background', animations['background']['sunny-2'], true, true);
                    next();
                },
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["robot-d3-2"],
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["player-d3-1"],
                'waitForUser': 'Weiter'
            },
            {
                'type': 'display',
                'content': [
                    {
                        'type': 'image',
                        'imageSrc': images["experiment-3-1"],
                        'caption':
                            '<strong>Das benötigst du:</strong>' +
                            '<br>2 Plastikbecher, einen halben Liter Wasser, einen Korkenzieher oder Schere. Und dazu eine handvoll Erde, Sand, Kies, und entweder Mehl oder Ton.',
                    },
                    {
                        'type': 'image',
                        'imageSrc': images["experiment-3-2"],
                        'caption':
                            'Schneide oder bohre mit Schere oder Korkenzieher ein Loch in jeden Becher. Ein Loch sollte' +
                            '<br>weiter unten auf der Seite des Bechers sein, das andere weiter oben.'
                    },
                    {
                        'type': 'image',
                        'imageSrc': images["experiment-3-3"],
                        'caption':
                            'Jetzt stellen wir die Erdschichten unseres Bodens in den Plastikbechern nach:' +
                            '<br>Fülle dazu zuerst eine Schicht Kies oder kleine Steine in die Becher...'
                    },
                    {
                        'type': 'image',
                        'imageSrc': images["experiment-3-4"],
                        'caption':
                            '... dann eine Schicht Mehl oder Ton. Achte dabei darauf, dass das höhere Loch über der Mehl- oder Tonschicht liegt.' +
                            '<br>Darauf kommt eine Schicht Sand und zum Schluss Erde.'
                    },
                    {
                        'type': 'image',
                        'imageSrc': images["experiment-3-5"],
                        'caption':
                            'Gieße jetzt ungefähr die selbe Menge Wasser in beide Plastikbecher.' +
                            '<br>Am besten stellst du etwas darunter, damit kein Wasser ausläuft. Dann warte einfach ein paar Minuten.'
                    }
                ]
            },
            // Quiz 3
            {
                'type': 'quiz',
                'quiz': {
                    'title': 'Frage 3',
                    'question': {
                        'text': 'Was vermutest du, wird passieren?',
                        'audio': speech['robot']["quiz-3"]
                    },
                    'options': [
                        {
                            'text': 'Wasser fließt am leichtesten aus dem Becher unter der Mehl/Tonschicht',
                            'isCorrect': false
                        },
                        {
                            'text': ' Wasser fließt am leichtesten über der Mehl/Tonschicht aus dem Becher',
                            'isCorrect': true
                        },
                        {
                            'text': 'Wasser fließt gar nicht aus dem Becher',
                            'isCorrect': false
                        }
                    ],
                    'hint': {
                        'dialogue': dialogues['robot-quiz-hint-3']
                    },
                    'reactions': defaultQuizReactions
                }
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["robot-d4-1"],
                'waitForUser': 'Weiter'
            },
            {
                'afterEnter': function (game, next) {
                    game.clearDialogueTexts();
                    next();
                },
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["robot-d4-2"],
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["player-d4-1"],
                'waitForUser': 'Weiter'
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["robot-d4-3"],
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["player-d4-2"],
                'waitForUser': 'Weiter'
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["robot-d4-4"],
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["player-d4-3"],
            },
            {
                'type': 'dialogue',
                'dialogue': dialogues["robot-d4-5"],
                'waitForUser': 'Weiter'
            },
            {
                'afterEnter': function (game, next) {
                    game.clearDialogueTexts();

                    game.startAnimation('player', animations['player']['beam-to-space'], false, false, () => {
                        game.clearAnimationLayer('robot');
                        game.startAudio('SFX', sounds['beaming'], false, false);
                        setTimeout(() => {
                            game.startAudio('SFX', sounds['flying'], false, false);
                        }, 2500);

                        game.requestAnimationEnd('player', next);
                    });
                }
            },
        ],

        // ///// End of level
        'summary': {
            'reactions': {
                'background': animations['background']['level-end'],
                // 'player': animations['player']['joy'],
                // 'robot': animations['robot']['joy']
            }
        },

        'beforeLevelLeave': function (game, next) {
            next();
        },
    }
}