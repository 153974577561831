<template>
  <div class="level-summary">
    <Audio ref="summary-sounds"></Audio>
    <div class="level-summary_step level-summary_step--score" v-if="summaryStep === 'score'">
      <p class="level-summary_overline">{{(hasFullScore ? 'Ausgezeichnet!' : 'Glückwunsch!')}}</p>
      <p class="level-summary_headline">Du hast {{levelConfig.title}} <br> erfolgreich beendet.</p>
      <div class="level-summary_score">
        <p class="level-summary_score_title">Gesammelte Sterne:</p>
        <Rating :count="levelScore.scored" :total="levelScore.available"></Rating>
        <p class="level-summary_score_values">
          <span>{{ levelScore.scored }}</span>
          <span>/</span>
          <span>{{ levelScore.available }}</span>
        </p>
        <p class="level-summary_copy" v-if="hasFullScore">
          <strong>Perfekt gespielt:</strong>
          <br>
          Du hast alle Sterne in diesem Level gesammelt. Glückwunsch!
        </p>
        <p class="level-summary_copy" v-if="!hasFullScore">
          <strong>Tipp:</strong>
          <br>
          Du kannst das Level noch einmal spielen, um alle Sterne zu sammeln und eine Belohnung freizuschalten.
        </p>
      </div>
      <div class="level-summary_buttons" v-if="hasFullScore">
        <Btn icon="right" @click="nextStep">Weiter</Btn>
      </div>
      <div class="level-summary_buttons" v-if="!hasFullScore">
        <Btn variant="invert" icon="reload" @click="restartLevel">Nochmal</Btn>
        <Btn icon="right" @click="nextLevel">Weiter</Btn>
      </div>
    </div>

    <div class="level-summary_step level-summary_step--achievement" v-if="summaryStep === 'achievement'">
      <Animation id="achievement-animation" ref="achievement-animation" :animation-layers="['achievement']"></Animation>

      <p class="level-summary_overline">Tolle Leistung!</p>
      <p class="level-summary_headline">Du hast eine neue Belohnung freigeschalten.</p>
      <p class="level-summary_copy">
        Mit deiner Quiz-Leistung hast du alle Sterne im Level gesammelt.
        <br>
        <strong>Als Belohnung erhältst du:</strong>
      </p>
      <div class="level-summary_achievement-title">
        {{levelConfig.achievement.title}}
      </div>
      <div class="level-summary_buttons">
        <Btn icon="right" @click="nextLevel">Zum nächsten Level</Btn>
      </div>
    </div>

  </div>
</template>

<script>
import basicLevelAssets from "@/assets/basicLevelAssets";
import Btn from "@/components/interface/btn";
import Rating from "@/components/Rating";
import Animation from "@/components/Animation";
import Audio from "@/components/Audio";

export default {
  'name': 'LevelSummary',
  'components': {
    Btn,
    Rating,
    Animation,
    Audio
  },
  'props': {
    'levelConfig': Object
  },
  'data': ()=>{
    return {
      'summaryStep': 'score'
    }
  },
  'computed': {
    levelScore(){
      return this.$store.getters.levelScore(this.levelConfig.id);
    },
    hasFullScore(){
      return this.levelScore.scored >= this.levelScore.available;
    }
  },

  'watch': {
    summaryStep(newStep){
      if (newStep === 'achievement'){
        this.$nextTick(()=>{
          if (this.$refs['achievement-animation']) {
            this.$refs['achievement-animation'].startAnimation('achievement', this.levelConfig.achievement.animation, true, false, () => {
              if (this.$refs['summary-sounds']) {
                this.$refs['summary-sounds'].startAudio('achievement', basicLevelAssets['sounds']['basic-level-achievement-unlocked'], false, false);
              } else {
                console.warn('Error in summary step: sound node undef.');
              }
            });
          }
        });
      }
    }
  },

  'methods': {
    nextStep(){
      this.summaryStep = 'achievement';
    },
    restartLevel() {
      this.$emit('restart-level');
    },
    exitLevel() {
      this.$emit('exit-level');
    },
    nextLevel() {
      this.$emit('next-level');
    }
  },
}
</script>

<style lang="scss">
@import "src/style/variables";
@import "src/style/mixins";
@import "src/style/animations";

  .level-summary {
    @extend %strech;
    padding: 170px 380px 0;
    //background-color: rgba(123,234,012, 0.4);
    color: $text-color-dark;
    text-align: center;
    font-size: 18px;
    line-height: 1.2;

    .level-summary_overline {
      text-transform: uppercase;
    }

    .level-summary_headline {
      max-width: 500px;
      margin: 28px auto 0;
      font-size: 35px;
      font-family: $font-bold;
    }

    .level-summary_score {
      margin-top: 48px;

      .level-summary_score_title {
        text-transform: uppercase;
        margin-bottom: 30px;
      }

      .level-summary_score_values {
        font-size: 45px;
        line-height: 1;
        font-family: $font-bold;
        margin-top: 42px;
      }
    }

    .level-summary_copy {
      max-width: 540px;
      margin: 0 auto;
    }

    .level-summary_step--score .level-summary_copy {
      margin-top: 60px;
    }

    #achievement-animation {
      z-index: 0;
    }

    .level-summary_step--achievement > *:not(#achievement-animation):not(.level-summary_buttons) {
      position: relative;
      z-index: 10;
    }

    .level-summary_step--achievement .level-summary_copy {
      margin-top: 34px;
    }

    .level-summary_achievement-title {
      margin-top: 300px;
      font-size: 24px;
      font-family: $font-bold;
      text-transform: uppercase;
    }

    .level-summary_buttons {
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translate(-50%, 0);

      > * + * {
        margin-left: 18px;
      }
    }
  }
</style>