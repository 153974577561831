<template>
  <component :is="type"
             :href="href"
             class="button"
             :class="'button--'+variant +' '+ 'button--icon-'+icon" :disabled="disabled"
             @click="onClick()">
    <span class="text"><slot/></span>
    <icon :icon-name="icon"></icon>
    <Audio ref="audio"></Audio>
  </component>
</template>

<script>
import Icon from '@/components/interface/icon.vue';
import Audio from '@/components/Audio';
import basicAssets from "@/assets/basicAssets";

export default {
  name: "button",
  'components': {
    Icon,
    Audio
  },
  props: {
    'variant': {
      type: String,
      default: 'primary'
    },
    'icon': {
      type: String,
      default: 'none'
    },
    'disabled': {
      type: Boolean,
      default: false
    },
    'href': {
      type: String,
      default: null
    },
    'to': {
      type: String,
      default: null
    }
  },
  computed: {
    type() {
      if (this.href) {
        return 'a'
      } else {
        return 'button'
      }
    }
  },
  methods: {
    onClick(){
      if (!this.disabled){
        this.$emit('click');
        if (this.$refs['audio']){
          this.$refs['audio'].startAudio('button', basicAssets['sounds']['basic-button-click']);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/style/variables";
@import "src/style/mixins";

.button {
  position: relative;
  min-width: 320px;
  height: 56px;
  padding: 0 62px;
  border-radius: 40px;
  color: #FFF;
  font-size: 24px;
  font-family: $font-bold;
  letter-spacing: 1.27px;
  text-decoration: none;
  text-transform: uppercase;
  outline: none;
  transition:
      background-color $transition-duration ease-in-out,
      opacity $transition-duration ease-in-out;

  &:not([disabled]) {
    cursor: pointer;
  }

  &[disabled] {
    pointer-events: none;
    cursor: default;
  }

  &.button--primary {
    position: relative;
    border-radius: 41.6px;
    background: linear-gradient(180deg, #FF7F7F 0%, #F00000 100%);
    box-shadow: 0 4px 10px -1px rgba(0,0,0,0.3);
    border: none;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      border: 3px solid rgba(255,255,255,0.34);
      border-radius: 41.6px;
    }

    &:hover {
      background: linear-gradient(180deg, #f00000 0%, #f00000 100%);
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  &.button--secondary {
    position: relative;
    border-radius: 41.6px;
    background: linear-gradient(180deg, #7BB8FD 0%, #035DC3 100%);
    box-shadow: 0 4px 10px -1px rgba(0,0,0,0.3);
    border: none;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      border: 3px solid rgba(255,255,255,0.34);
      border-radius: 41.6px;
    }

    &:hover {
      background: linear-gradient(180deg, #035dc3 0%, #035dc3 100%);
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  &.button--invert {
    color: #FFF;
    border: 3px solid #FFF;
    background-color: transparent;

    &:hover {
      background-color: rgba(255,255,255,0.1);
    }
  }


  &.button--secondary-invert {
    color: #035dc3;
    border: 3px solid #035dc3;
    background-color: transparent;

    &:hover {
      background-color: rgba(255,255,255,0.1);
    }
  }

  &.button--invert-red {
    color: $color-red-bright;
    border: 3px solid $color-red-bright;
    background-color: transparent;

    &:hover {
      background-color: $color-red-bright-light;
    }
  }

  &.button--primary-round {
    @extend .button--primary;

    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0;
    width: 66px;
    height: 66px;
    padding: 0;
  }

  &.button--invert-round {
    @extend .button--invert;

    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0;
    width: 66px;
    height: 66px;
    padding: 0;
  }

  &.button--blank-round {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0;
    width: 66px;
    height: 66px;
    padding: 0;
    border: none;
    background-color: transparent;
  }

  .icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  &:not([class*="round"]) .icon {
    right: 12px;
  }

  &.button--icon-left {
    &:not([class*="round"]) .icon {
      left: 12px;
      right: auto;
    }
  }
}
</style>