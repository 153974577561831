<template>
  <div class="menu">
    <btn class="burger" v-if="menu !== true" @click="menu = true" variant="blank-round" :icon="'menu'">&nbsp;</btn>
    <div v-if="menu === true">
      <div class="menu-wrapper">
        <div class="menu-content">
          <btn class="closing-tag" @click="menu = false" variant="blank-round" :icon="'wrong'">&nbsp;</btn>
          <div class="menu-selection">
            <btn class="menu-button" v-if="hasHome" @click="reloadPage" variant="primary" :icon="'home'"> Zum Start</btn>
            <btn class="menu-button" v-if="hasLevelSelection" @click="exitToSlider" variant="primary" :icon="'home'"> Zur Levelauswahl</btn>
            <btn class="menu-button" v-if="hasHelp" @click="help = true" variant="primary" :icon="'help'"> Anleitung</btn>
          </div>
        </div>
      </div>
    </div>
    <Modal v-if="help !== false" @close="help = false">
      <div class="help">
        <p class="headline"> Spielanleitung </p>
        <p class="subline">Quiz- und Abenteuer-Spiel der N-ERGIE</p>
        <ul>
          <li class="list-item">
            Vor dem Spielstart kannst du auswählen, welches der 5 Level du spielen möchtest.
            Spiele am besten ein Level nach dem anderen, um der Geschichte unserer Helden folgen zu können.
          </li>
          <li class="list-item">
            Über den <strong>„Weiter“-Knopf</strong> kannst du im Spiel immer fortfahren.
          </li>
          <li class="list-item">
            Wenn du in einer der Quiz-Fragen nicht weiter weißt, klicke einfach das <strong>Fragezeichen über Ro-Sa</strong> für einen Hinweis an.
          </li>
          <li class="list-item">
            <strong>Keine Angst vor Fehlern</strong>: Nach einer falschen Antwort kannst du es so lange versuchen, bis du richtig liegst.
          </li>
          <li class="list-item">
            Wenn du eine Frage schon beim <strong>ersten Versuch</strong> richtig beantwortest, <strong>erhältst du einen Stern</strong>.
          </li>
          <li class="list-item">
            Wenn du <strong>jeden Stern eines Levels</strong> gesammelt hast, erhältst du eine <strong>Belohnung</strong> in Form eines Gegenstandes.
            Diese findest du immer oben rechts am Bildschirm. Es gibt für jedes Level eine andere Belohnung.
            Am Ende des Spiels erhältst du eine <strong>Sonderauszeichnung</strong>.
          </li>
          <li class="list-item">
            Wenn du das Spiel verlässt, kannst du jederzeit wieder weiterspielen. <strong>Deine gesammelten Sterne und Belohnungen werden gespeichert</strong>.
            Aber <strong>Vorsicht:</strong> Wenn du ein Level nicht bis zum Ende spielst und von neuem beginnst, musst du die Sterne in diesem Level noch einmal einsammeln.
          </li>
        </ul>
      </div>
    </Modal>
  </div>
</template>

<script>
import btn from "@/components/interface/btn";
import Modal from "@/components/Modal";

export default {
  name: "Menu",
  'components': {
    btn,
    Modal
  },
  'props': {
    'hasHelp': {
      'type': Boolean,
      'default': true
    },
    'hasHome': {
      'type': Boolean,
      'default': false
    },
    'hasLevelSelection': {
      'type': Boolean,
      'default': false
    }
  },
  'data'() {
    return {
      'menu': false,
      'help': false,
    }
  },
  methods: {
    reloadPage() {
      this.$store.commit('persistSavegame');
      this.$router.push({
        'name': 'Dashboard'
      });
      window.location.reload();
    },
    exitToSlider() {
      this.$router.push({
        'name': 'Dashboard',
        'query': { 'levelselect': this.$store.getters.firstUnfinishedLevelIndex }
      });
    },
  }
}
</script>

<style lang="scss">
@import "src/style/variables";
@import "src/style/mixins";

.menu {
  .burger {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    z-index: 99;
  }

  .menu-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;

    .menu-content {

      .closing-tag {
        position: absolute;
        top: 30px;
        right: 30px;
      }

      .menu-selection {
        position: absolute;
        top: 100px;
        right: 30px;
        display: flex;
        flex-direction: column;

        .menu-button {
          margin: 20px auto;
        }

      }
    }
  }
  .help{
    color: $color-blue-dark;
    .headline{
      font-family: $font-bold;
      font-size: 35px;
      letter-spacing: 0;
      text-align: center;
      margin-bottom: 24px;
    }
    .subline{
      font-family: $font-bold;
      font-size: 18px;
      letter-spacing: 0;
    }

      .list-item{
        margin:20px 0 20px 0;
        text-align: left;
        font-style: $font-regular;
        font-size: 15px;
        letter-spacing: 0;
      }

  }
}
</style>