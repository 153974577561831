export default {
  'sounds': {
    'tutorial-slide-1-text-1': '/speech/robot/RoSa Tutorial 01.mp3', // Hallo
    'tutorial-slide-1-text-2': '/speech/robot/RoSa Tutorial 06.mp3', // Während werde ich dir immer wieder
    'tutorial-slide-1-text-3': '/speech/robot/RoSa Tutorial 07.mp3', // Hier siehst du wie viele
    'tutorial-slide-2-text-1': '/speech/robot/RoSa Tutorial 08.mp3', // Hier steht
    'tutorial-slide-2-text-2': '/speech/robot/RoSa Tutorial 09.mp3', // Das ist die geheime
    'tutorial-slide-3-text-1': '/speech/robot/RoSa Tutorial 14.mp3', // Falls du Mal nicht
    'tutorial-slide-3-text-2': '/speech/robot/RoSa Tutorial 15.mp3', // Jetzt wünsche ich dir
  },

  'images': {
    'tutorial-slide-1-img-1': '/images/tutorial/Toutorial-Screen1-Grafik1.png',
    'tutorial-slide-2-img-1': '/images/tutorial/Toutorial-Screen2-Grafik1.png',
    'tutorial-slide-2-img-2': '/images/tutorial/Toutorial-Screen2-Grafik2.png',
    'tutorial-slide-3-img-1': '/images/tutorial/Toutorial-Screen3-Grafik1.png'
  }
}