<template>
  <span class="icon" :class="'icon-'+iconName"></span>
</template>

<script>
export default {
  'name': 'Icon',
  'props': {
    'iconName': {
      'type': String,
      'default': 'none'
    }
  }
}
</script>

<style lang="scss">
  // styles are global in ./style/_icon.scss
</style>