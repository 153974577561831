<template>
  <div class="logo">
    <img src="@/assets/Logo/Logo.png" alt="N-ERGIE Logo">
  </div>
</template>

<script>
export default {
  'name': 'Logo'
}
</script>

<style lang="scss">
.logo {
  display: inline-block;
  position: relative;

  //&:after {
  //  content: '';
  //  background-color: rgba(255,000,255, 0.4);
  //  height: 5px;
  //  width: 165px;
  //  position: absolute;
  //  left: 50%;
  //  transform: translate(-50%, 0);
  //  top: 50%;
  //}

  img {
    height: auto;
    width: 224px;
  }
}
</style>
