import levelAchievements from "@/levels/levelAchievements";
import basicLevelAssets from "@/assets/basicLevelAssets";

let baseConfig = {
  'id': 'Level_2',
  'title': 'Level 2',
  'subtitle': 'Wasser-Experimente',
  'description': 'Aggregatzustände',
  'levelSliderBackground': basicLevelAssets['images']['levelSliderBackground-level_2'],
  'availableScore': 3,
  'achievement': levelAchievements['atom'],
  'nextLevelId': 'Level_3'
};

export const config = baseConfig;

export default (selectedPlayer = 'player-nici') => {

  let sounds = {
    'ambient': '/sounds/Level_2/2-0-Pond-ambience364300__maphill__pond-frog-bird-cleaned.mp3',
    'swoosh': '/sounds/Level_2/2-1-Saisonwechsel-Swoosh-379464__vandale__wind1.mp3'
  }

  let images = {
    'experiment-1-1' : '/images/experiments/ex-1-1.jpg',
    'experiment-1-2' : '/images/experiments/ex-1-2.jpg',
    'experiment-1-3' : '/images/experiments/ex-1-3.jpg',
    'experiment-2-1' : '/images/experiments/ex-2-1.jpg',
    'experiment-2-2' : '/images/experiments/ex-2-2.jpg',
    'experiment-2-3' : '/images/experiments/ex-2-3.jpg',
  }

  let animations = {
    'background': {
      'summer' : {
        'path': '/animations/level-2/Jahreszeiten/Jahreszeiten.json',
        'startFrame': 0,
        'endFrame': (24 * 1) -1
      },
      'winter' : {
        'path': '/animations/level-2/Jahreszeiten/Jahreszeiten.json',
        'startFrame': (24 * 1),
        'loopStartFrame': (24 * 2),
        'endFrame': (24 * 3) -1
      },
      'summer-2' : {
        'path': '/animations/level-2/Jahreszeiten/Jahreszeiten.json',
        'startFrame': (24 * 3),
        'loopStartFrame': (24 * 4),
        'endFrame': (24 * 5) -1
      },
      'fall' : {
        'path': '/animations/level-2/Jahreszeiten/Jahreszeiten.json',
        'startFrame': (24 * 5),
        'loopStartFrame': (24 * 6),
        'endFrame': (24 * 7) -1
      },
      'summer-3' : {
        'path': '/animations/level-2/Jahreszeiten/Jahreszeiten.json',
        'startFrame': (24 * 7),
        'loopStartFrame': (24 * 8),
        'endFrame': (24 * 9) -1
      },
      'level-end' : {
        'path': '/animations/level-2/Jahreszeiten/Jahreszeiten.json',
        'startFrame': (24 * 9),
        'loopStartFrame': (24 * 10),
        'endFrame': (24 * 11) -1
      }
    },

    'robot': {
      'idle': {
        'path': '/animations/robot/Rosa-default/Rosa-default.json',
        // 'startFrame': 0,
        // 'endFrame': (24 * 2) -1
      },

      'talk': {
        'path': '/animations/robot/Rosa-sprechen/Rosa-sprechen.json',
        // 'loopStartFrame': 0,
        // 'loopEndFrame': (24 * 2) -1,
      },

      'joy':  {
        'path': '/animations/robot/Rosa-Freude/Rosa-freude.json',
        'startFrame': 0,
        'loopStartFrame': (24 * 2) -1,
        'loopEndFrame': (24 * 4) -1,
        'endFrame': (24 * 6) -1,
      },
    },

    'player': {
      'player-nici': {
        'idle': {
          'path': '/animations/player-nici/Nici-default/Nici-default.json'
        },

        'talk': {
          'path': '/animations/player-nici/Nici-sprechen/Nici-sprechen.json'
        },

        'joy':  {
          'path': '/animations/player-nici/Nici-freude-full/Nici-freude-full.json',
          'startFrame': 0,
          'loopStartFrame': (24 * 2) -1,
          'loopEndFrame': (24 * 4) -1,
          'endFrame': (24 * 5) -1,
        },
      },

      'player-nick': {
        'idle': {
          'path': '/animations/player-nick/Nick-default/Nick-default.json'
        },

        'talk': {
          'path': '/animations/player-nick/Nick-sprechen/Nick-sprechen.json'
        },

        'joy':  {
          'path': '/animations/player-nick/Nick-freude-full/Nick-freude-full.json',
          'startFrame': 0,
          'loopStartFrame': (24 * 2) -1,
          'loopEndFrame': (24 * 4) -1,
          'endFrame': (24 * 5) -1,
        },
      }
    }
  }
  animations["player"] = animations["player"][selectedPlayer];

  let speech = {
    'robot': {
      // 'd1-1': '/speech/robot/RoSa 24.mp3',
      'd1-2': '/speech/robot/RoSa 25.mp3',
      'd1-3': '/speech/robot/RoSa 26.mp3',
      'd1-4': '/speech/robot/RoSa 27.mp3',
      'quiz-1': '/speech/robot/RoSa 28-01.mp3',
      'quiz-1-hint': '/speech/robot/RoSa 28-02.mp3',
      'd2-1': '/speech/robot/RoSa 29.mp3',
      'd2-2': '/speech/robot/RoSa 30.mp3',
      'quiz-2': '/speech/robot/RoSa 31-1.mp3',
      'quiz-2-hint': '/speech/robot/RoSa 31-2.mp3',
      'd3-1': '/speech/robot/RoSa 32.mp3',
      'd3-2': '/speech/robot/RoSa 33.mp3',
      'quiz-3': '/speech/robot/RoSa 34-1.mp3',
      'quiz-3-hint': '/speech/robot/RoSa 34-2.mp3',
      'd4-1': '/speech/robot/RoSa 35.mp3',
      'd4-2': '/speech/robot/RoSa 36.mp3',
      'd4-3': '/speech/robot/RoSa 37.mp3',
    },

    'player': {
      'player-nici': {
        // 'd1-1': '/speech/nici/Julie 16.mp3',
        'd1-2': '/speech/nici/Julie 17.mp3',
        'd1-3': '/speech/nici/Julie 18.mp3',
        'd1-4': '/speech/nici/Julie 19.mp3',
        'd2-1': '/speech/nici/Julie 20.mp3',
        'd3-1': '/speech/nici/Julie 21.mp3',
        'd4-1': '/speech/nici/Julie 22.mp3',
        'd4-2': '/speech/nici/Julie 23.mp3',
        'd4-3': '/speech/nici/Julie 24.mp3',
      },

      'player-nick': {
        // 'd1-1': '/speech/nick/Nils 16.mp3',
        'd1-2': '/speech/nick/Nils 17.mp3',
        'd1-3': '/speech/nick/Nils 18.mp3',
        'd1-4': '/speech/nick/Nils 19.mp3',
        'd2-1': '/speech/nick/Nils 20.mp3',
        'd3-1': '/speech/nick/Nils 21.mp3',
        'd4-1': '/speech/nick/Nils 22.mp3',
        'd4-2': '/speech/nick/Nils 23.mp3',
        'd4-3': '/speech/nick/Nils 24.mp3',
      }
    }
  }
  speech["player"] = speech["player"][selectedPlayer];

  let dialogues = {

    // 'player-d1-1': {
    //   'character': 'player',
    //   'text': 'Hier sieht’s doch gut aus, setzen wir uns mal für eine Weile.',
    //   'audio': speech["player"]["d1-1"],
    //   'animation': animations["player"]["talk"],
    //   'animationAfter': animations["player"]["idle"]
    // },

    // 'robot-d1-1': {
    //   'character': 'robot',
    //   'text': 'Na gut. Sind deine Batterien wohl schon leer? Meine stehen noch auf 60 % Ladung.',
    //   'audio': speech["robot"]["d1-1"],
    //   'animation': animations["robot"]["talk"],
    //   'animationAfter': animations["robot"]["idle"]
    // },

    'player-d1-2': {
      'character': 'player',
      'text': 'Hach, hier gefällts mir … Schau mal, wie schön das Wasser in der Sonne glitzert!',
      'audio': speech["player"]["d1-2"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d1-2': {
      'character': 'robot',
      'text': 'Stimmt. Wasser reflektiert am meisten Licht wenn es <strong>flüssig</strong> ist. Kennst du auch die anderen Formen, die Wasser haben kann?',
      'audio': speech["robot"]["d1-2"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'player-d1-3': {
      'character': 'player',
      'text': 'Mhh, lass mich nachdenken … Na klar, Eis gibt’s ja auch noch! Das ist dann <strong>festes</strong> Wasser.',
      'audio': speech["player"]["d1-3"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d1-3': {
      'character': 'robot',
      'text': 'Genau. Flüssig und fest sind zwei Formen von Wasser. Die nennt man auch <strong>Aggregatzustände</strong>. Aber es gibt auch noch einen dritten.',
      'audio': speech["robot"]["d1-3"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'player-d1-4': {
      'character': 'player',
      'text': 'Ähhm … den muss ich wohl gerade vergessen haben. Hilf mir mal auf die Sprünge!',
      'audio': speech["player"]["d1-4"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d1-4': {
      'character': 'robot',
      'text': '<strong>Gasförmig</strong> natürlich!',
      'audio': speech["robot"]["d1-4"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'robot-quiz-hint-1': {
      'character': 'robot',
      'text': 'Gasförmiges Wasser ist so kleinteilig, dass es sich in der Luft verteilen kann.',
      'audio': speech["robot"]["quiz-1-hint"],
      // no animations
    },

    'robot-d2-1': {
      'character': 'robot',
      'text': 'Richtig. Als <strong>Nebel</strong> oder <strong>Dampf</strong> ist Wasser gasförmig.',
      'audio': speech["robot"]["d2-1"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'player-d2-1': {
      'character': 'player',
      'text': 'Wie wird Wasser denn eigentlich so gasförmig? Und wieso überhaupt?',
      'audio': speech["player"]["d2-1"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d2-2': {
      'character': 'robot',
      'text': 'Das zeig ich dir am besten mit einem Versuch. Schau her – ich zeig dir, wie’s geht!',
      'audio': speech["robot"]["d2-2"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'robot-quiz-hint-2': {
      'character': 'robot',
      'text': 'In der warmen Sonne erhitzt sich das Wasser mit der Zeit. Dadurch wird es gasförmig.',
      'audio': speech["robot"]["quiz-2-hint"],
      // no animations
    },

    'robot-d3-1': {
      'character': 'robot',
      'text': 'Genau, das Wasser „verschwindet“ durch die Wärme der Sonne. Das nennt man <strong>„Verdunstung“</strong> – wenn Wasser vom flüssigen zum gasförmigen Zustand übergeht.',
      'audio': speech["robot"]["d3-1"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'player-d3-1': {
      'character': 'player',
      'text': 'Aber wenn Wasser immer dann verdunstet, wenn es warm ist … dann haben wir doch bald gar kein Wasser mehr auf der Erde, oder nicht?!',
      'audio': speech["player"]["d3-1"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d3-2': {
      'character': 'robot',
      'text': 'Na glücklicherweise ist das nicht so. Ich zeig dir noch einen anderen Versuch. Da siehst du, was mit dem verdunstetem Wasser passiert.',
      'audio': speech["robot"]["d3-2"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'robot-quiz-hint-3': {
      'character': 'robot',
      'text': 'Das Wasser wird durch die Wärme der Sonne wieder gasförmig. Wo wird sich das verdunstete Wasser wohl ansammeln?',
      'audio': speech["robot"]["quiz-3-hint"],
      // no animations
    },

    'robot-d4-1': {
      'character': 'robot',
      'text': 'Durch die <strong>Sonne verdunstet</strong> also das Wasser. Aber wie du siehst, verschwindet es nicht einfach. Es bilden sich kleine Tropfen an der Frischhaltefolie.',
      'audio': speech["robot"]["d4-1"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'robot-d4-2': {
      'character': 'robot',
      'text': 'Das gasförmige Wasser wird also wieder flüssig. Diesen Vorgang nennt man <strong>„Kondensation“</strong>.',
      'audio': speech["robot"]["d4-2"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'player-d4-1': {
      'character': 'player',
      'text': 'Ich verstehe! Das Wasser wird also nicht weniger – es wechselt nur seinen Zustand und wird dann wieder flüssig. Aber ist das auf der Erde auch so mit dem Wasser aus <strong>Flüssen, Meeren</strong> und <strong>Seen</strong>?',
      'audio': speech["player"]["d4-1"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'player-d4-2': {
      'character': 'player',
      'text': 'Um die Erdkugel ist ja schließlich keine Frischhaltefolie gewickelt, an der sich das Wasser wieder sammeln kann!',
      'audio': speech["player"]["d4-2"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d4-3': {
      'character': 'robot',
      'text': 'Hattest du denn genug Pause? Wie wär’s dann mit einem Ausflug auf den Hügel dort drüben? Von da oben aus kann ich dir genau zeigen, wie das mit dem <strong>Wasserkreislauf</strong> auf der Erde funktioniert!',
      'audio': speech["robot"]["d4-3"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'player-d4-3': {
      'character': 'player',
      'text': 'Klar, ich bin bereit. Machen wir uns auf den Weg!',
      'audio': speech["player"]["d4-3"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

  }

  let defaultQuizReactions = {
    'correct-star': {
      'robot': {
        'text': 'Richtige Antwort beim ersten Versuch! Dafür verdienst du einen Stern.',
        'animation': animations["robot"]["joy"],
        'animationAfter': animations["robot"]["idle"],
      },
      'player': {
        'animation': animations["player"]["joy"],
        'animationAfter': animations["player"]["idle"]
      }
    },
    'correct': {
      'robot': {
        'text': 'Richtige Antwort! Gut gemacht.',
        'animation': animations["robot"]["joy"],
        'animationAfter': animations["robot"]["idle"],
      },
      'player': {
        'animation': animations["player"]["joy"],
        'animationAfter': animations["player"]["idle"]
      }
    },
    'wrong': {
      'robot': {
        'text': 'Das war leider nicht richtig. Versuch’s einfach nochmal!',
      }
    }
  }







  // //// //////////
  // ////  STEPS
  // //// //////////

  return {
    ... baseConfig,

    'preload': {
      animations,
      sounds,
      speech,
      images
    },

    'beforeLevelEnter': function (game, next){
      next();
    },

    'steps': [
      // Level Setup
      {
        'beforeEnter': function (game, next) {

          let animationCount = 0;
          game.startAnimation('player', animations['player']['idle'], true, false, ()=>{
            animationCount ++
            if (animationCount === 3){ next(); }
          });
          game.startAnimation('robot', animations['robot']['idle'], true, false, ()=>{
            animationCount ++
            if (animationCount === 3){ next(); }
          });
          game.startAnimation('background', animations['background']['summer'], true, false, ()=>{
            animationCount ++
            if (animationCount === 3){ next(); }
          });

          game.startAudio('background', sounds['ambient'], true);
        },

        // 'waitForUser': 'Level Starten'
      },

      {
        'type': 'tutorial'
      },

      // {
      //   'type': 'dialogue',
      //   'dialogue': dialogues["player-d1-1"],
      // },
      // {
      //   'type': 'dialogue',
      //   'dialogue': dialogues["robot-d1-1"],
      //   'waitForUser': 'Weiter'
      // },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d1-2"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d1-2"],
        'waitForUser': 'Weiter'
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d1-3"],
      },

      {
        'afterEnter': function (game, next){
          game.startAudio('SFX', sounds['swoosh'], false, false, ()=>{
            game.requestAudioEnd('SFX', false, next);
            setTimeout(()=>{
              game.requestAudioEnd('background', true)
              game.startAnimation('background', animations['background']['winter'], true, false);
            }, 600)
          });
        }
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d1-3"],
        'waitForUser': 'Weiter'
      },

      {
        'afterEnter': function (game, next){
          game.startAudio('SFX', sounds['swoosh'], false, false, ()=>{
            game.requestAudioEnd('SFX', false, next);
            setTimeout(()=>{
              game.startAudio('background', sounds['ambient'], true);
              game.startAnimation('background', animations['background']['summer-2'], true, false);
            }, 600)
          });
        }
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d1-4"],
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d1-4"],
        'waitForUser': 'Weiter'
      },

      // Quiz 1
      {
        'type': 'quiz',
        'quiz': {
          'title': 'Frage 1',
          'question': {
            'text': 'In welcher Form kommt Wasser als Gas vor?',
            'audio': speech['robot']["quiz-1"]
          },
          'options': [
            {
              'text': 'Nebel und Dampf',
              'isCorrect': true
            },
            {
              'text': 'Geschmolzenes Eis',
              'isCorrect': false
            },
            {
              'text': 'Wassertropfen',
              'isCorrect': false
            }
          ],
          'hint': {
            'dialogue': dialogues['robot-quiz-hint-1']
          },
          'reactions': defaultQuizReactions
        }
      },

      {
        'afterEnter': function (game, next){
          game.startAudio('SFX', sounds['swoosh'], false, false, ()=>{
            game.requestAudioEnd('SFX', false, next);
            setTimeout(()=>{
              game.startAnimation('background', animations['background']['fall'], true, false);
            }, 600)
          });
        }
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d2-1"],
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d2-1"],
        'waitForUser': 'Weiter'
      },

      {
        'afterEnter': function (game, next){
          game.startAudio('SFX', sounds['swoosh'], false, false, ()=>{
            game.requestAudioEnd('SFX', false, next);
            setTimeout(()=>{
              game.startAnimation('background', animations['background']['summer-3'], true, false);
            }, 600)
          });
        }
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d2-2"],
        'waitForUser': 'Weiter'
      },

      {
        'type': 'display',
        'content': [
          {
            'type': 'image',
            'imageSrc': images["experiment-1-1"],
            'caption':
                '<strong>Das benötigst du:</strong>' +
                '<br>Eine flache Schale, einen halben Liter Wasser, einen Filzstift und einen sonnigen Platz.',
          },
          {
            'type': 'image',
            'imageSrc': images["experiment-1-2"],
            'caption':
                'Fülle zuerst das Wasser in die Schale.' +
                '<br>Die Schale muss nicht unbedingt bis zum Rand mit Wasser gefüllt sein.'
          },
          {
            'type': 'image',
            'imageSrc': images["experiment-1-3"],
            'caption':
                'Jetzt markiere den Wasserstand in der Schale mit einem löslichen Filzstift.' +
                '<br>Dann stelle die Schale einige Stunden an einen sonnigen oder warmen Platz.'
          }
        ],
        'animationAfter': animations['robot']['idle']
      },

      // Quiz 2
      {
        'type': 'quiz',
        'quiz': {
          'title': 'Frage 2',
          'question': {
            'text': 'Was denkst du, wird passieren?',
            'audio': speech['robot']["quiz-2"]
          },
          'options': [
            {
              'text': 'Das Wasser wird mehr',
              'isCorrect': false
            },
            {
              'text': 'Es passiert gar nichts',
              'isCorrect': false
            },
            {
              'text': 'Das Wasser wird weniger',
              'isCorrect': true
            }
          ],
          'hint': {
            'dialogue': dialogues['robot-quiz-hint-2']
          },
          'reactions': defaultQuizReactions
        }
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d3-1"],
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d3-1"],
        'waitForUser': 'Weiter'
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d3-2"],
        'waitForUser': 'Weiter'
      },

      {
        'type': 'display',
        'content': [
          {
            'type': 'image',
            'imageSrc': images["experiment-2-1"],
            'caption':
                '<strong>Das benötigst du:</strong>' +
                '<br>Eine flache Schale, einen halben Liter Wasser, einen Filzstift, Frischhaltefolie und einen sonnigen Platz.',
          },
          {
            'type': 'image',
            'imageSrc': images["experiment-2-2"],
            'caption':
                'Fülle zuerst das Wasser in die Schale und markiere den Wasserstand mit dem Filzstift.' +
                '<br>Auch diesmal muss die Schale nicht komplett mit Wasser gefüllt sein.'
          },
          {
            'type': 'image',
            'imageSrc': images["experiment-2-3"],
            'caption':
                'Bedecke die Öffnung mit Frischhaltefolie und drücke sie am Rand der Schale fest, damit sie ganz verschlossen ist. Stelle die Schale dann einige Stunden an einen sonnigen Platz.'
          }
        ],
        'animationAfter': animations['robot']['idle'],
      },

      // Quiz 3
      {
        'type': 'quiz',
        'quiz': {
          'title': 'Frage 3',
          'question': {
            'text': 'Was denkst du, wird passieren?',
            'audio': speech['robot']["quiz-3"]
          },
          'options': [
            {
              'text': 'Es bilden sich kleine Tropfen an der inneren Seite der Folie',
              'isCorrect': true
            },
            {
              'text': 'Das Wasser verdunstet durch die Folie hindurch',
              'isCorrect': false
            },
            {
              'text': 'Die Folie platzt nach einiger Zeit',
              'isCorrect': false
            }
          ],
          'hint': {
            'dialogue': dialogues['robot-quiz-hint-3']
          },
          'reactions': defaultQuizReactions
        }
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d4-1"],
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d4-2"],
        'waitForUser': 'Weiter'
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d4-1"],
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d4-2"],
        'waitForUser': 'Weiter'
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d4-3"],
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d4-3"],
        'waitForUser': 'Weiter'
      },

    ],

    // ///// End of level
    'summary':{
      'reactions': {
        'background': animations['background']['level-end'],
        'player': animations['player']['joy'],
        'robot': animations['robot']['joy']
      }
    },

    'beforeLevelLeave': function (game, next){
      next();
    },
  }
}