import levelAchievements from "@/levels/levelAchievements";
import basicLevelAssets from "@/assets/basicLevelAssets";

let baseConfig = {
  'id': 'Level_4',
  'title': 'Level 4',
  'subtitle': 'Ausflug ins Weltall',
  'description': 'Blauer Planet und Wasserschutz',
  'levelSliderBackground': basicLevelAssets['images']['levelSliderBackground-level_4'],
  'availableScore': 5,
  'achievement': levelAchievements['globe'],
  'nextLevelId': 'Level_5'
};

export const config = baseConfig;

export default (selectedPlayer = 'player-nici') => {

  let sounds = {
    'ambient': '/sounds/Level_4/4-0-Backgroundmusic-Screen Saver.mp3',
    'beep': '/sounds/Level_4/4-1-Warning-Beep-384967__bajko__sfx-seatbelt-warning-auto.mp3'
  }

  let images = {}

  let animations = {
    'background': {
      'space': {
        'path': '/animations/level-4/Weltraum/Weltraum.json',
        'loopStartFrame': 0,
        'loopEndFrame': (24 * 4) - 1
      },
      'level-end': {
        'path': '/animations/level-4/Weltraum/Weltraum.json',
        'startFrame': (24 * 4),
        'loopStartFrame': (24 * 5),
        'loopEndFrame': (24 * 6) - 1
      }
    },
    'robot': {
      'idle': {
        'path': '/animations/robot/Rosa-default-astronaut/Rosa-default-astronaut.json',
        'startFrame': 0,
        'endFrame': (24 * 2) -1
      },

      'talk': {
        'path': '/animations/robot/Rosa-sprechen-astronaut/Rosa-sprechen-astronaut.json',
        'loopStartFrame': 0,
        'loopEndFrame': (24 * 2) -1,
      },

      'joy':  {
        'path': '/animations/robot/Rosa-freude-astronaut/Rosa-freude-astronaut.json',
        'startFrame': 0,
        'loopStartFrame': (24 * 2) -1,
        'loopEndFrame': (24 * 4) -1,
        'endFrame': (24 * 6) -1,
      },

      'zoomIn': {
        'path': '/animations/robot/Rosa-zoom-astronaut/Rosa-zoom-astronaut.json',
        'startFrame': 0,
        'loopStartFrame': (24 * 2),
        'loopEndFrame': (24 * 3) - 1
      },

      'zoomOut': {
        'path': '/animations/robot/Rosa-zoom-astronaut/Rosa-zoom-astronaut.json',
        'startFrame': (24 * 3)
      },

      'lowBattery': {
        'path': '/animations/robot/Rosa-blinken-astronaut/Rosa-blinken-astronaut.json'
      }
    },
    'player': {
      'player-nici': {
        'idle': {
          'path': '/animations/player-nici/Nici-Astronaut-default/Nici-Astronaut-default.json'
        },

        'talk': {
          'path': '/animations/player-nici/Nici-Astronaut-sprechen/Nici-Astronaut-sprechen.json'
        },

        'joy':  {
          'path': '/animations/player-nici/Nici-Astronaut-freude/Nici-Astronaut-freude.json',
          'startFrame': 0,
          'loopStartFrame': (24 * 2) -1,
          'loopEndFrame': (24 * 4) -1,
          'endFrame': (24 * 5) -1,
        },
      },
      'player-nick': {
        'idle': {
          'path': '/animations/player-nick/Nick-Astronaut-default/Nick-Astronaut-default.json'
        },

        'talk': {
          'path': '/animations/player-nick/Nick-Astronaut-sprechen/Nick-Astronaut-sprechen.json'
        },

        'joy':  {
          'path': '/animations/player-nick/Nick-Astronaut-freude/Nick-Astronaut-freude.json',
          'startFrame': 0,
          'loopStartFrame': (24 * 2) -1,
          'loopEndFrame': (24 * 4) -1,
          'endFrame': (24 * 5) -1,
        },
      }
    }
  }
  animations["player"] = animations["player"][selectedPlayer];

  let speech = {
    'robot': {
      'd1-1': '/speech/robot/RoSa 54.mp3',
      'd1-2': '/speech/robot/RoSa 55.mp3',
      'd1-3': '/speech/robot/RoSa 56.mp3',
      'd1-4': '/speech/robot/RoSa 57.mp3',
      'quiz-1': '/speech/robot/RoSa 58-1.mp3',
      'quiz-1-hint': '/speech/robot/RoSa 58-2.mp3',
      'd2-1': '/speech/robot/RoSa 59.mp3',
      'quiz-2': '/speech/robot/RoSa 60-1.mp3',
      'quiz-2-hint': '/speech/robot/RoSa 60-2.mp3',
      'd3-1': '/speech/robot/RoSa 61.mp3',
      'd3-2': '/speech/robot/RoSa 62.mp3',
      'quiz-3': '/speech/robot/RoSa 63-1.mp3',
      'quiz-3-hint': '/speech/robot/RoSa 63-2.mp3',
      'd4-1': '/speech/robot/RoSa 64.mp3',
      'd4-2': '/speech/robot/RoSa 65.mp3',
      'quiz-4': '/speech/robot/RoSa 66-1.mp3',
      'quiz-4-hint': '/speech/robot/RoSa 66-2.mp3',
      'd5-1': '/speech/robot/RoSa 67.mp3',
      'd5-2': '/speech/robot/RoSa 68.mp3',
      // 'd5-3': '/speech/robot/RoSa 69.mp3',
      'quiz-5': '/speech/robot/RoSa 70-1.mp3',
      'quiz-5-hint': '/speech/robot/RoSa 70-2.mp3',
      'd6-1': '/speech/robot/RoSa 71.mp3',
      'd6-2': '/speech/robot/RoSa 72.mp3',
    },
    'player': {
      'player-nici': {
        'd1-1': '/speech/nici/Julie 35.mp3',
        'd1-2': '/speech/nici/Julie 36.mp3',
        'd1-3': '/speech/nici/Julie 37.mp3',
        'd1-4': '/speech/nici/Julie 38.mp3',
        'd2-1': '/speech/nici/Julie 39.mp3',
        'd3-1': '/speech/nici/Julie 40.mp3',
        'd4-1': '/speech/nici/Julie 41.mp3',
        'd5-1': '/speech/nici/Julie 42.mp3',
        'd5-2': '/speech/nici/Julie 43.mp3',
        'd6-1': '/speech/nici/Julie 44.mp3',
        'd6-2': '/speech/nici/Julie 45.mp3',
      },
      'player-nick': {
        'd1-1': '/speech/nick/Nils 35.mp3',
        'd1-2': '/speech/nick/Nils 36.mp3',
        'd1-3': '/speech/nick/Nils 37.mp3',
        'd1-4': '/speech/nick/Nils 38.mp3',
        'd2-1': '/speech/nick/Nils 39.mp3',
        'd3-1': '/speech/nick/Nils 40.mp3',
        'd4-1': '/speech/nick/Nils 41.mp3',
        'd5-1': '/speech/nick/Nils 42.mp3',
        'd5-2': '/speech/nick/Nils 43.mp3',
        'd6-1': '/speech/nick/Nils 44.mp3',
        'd6-2': '/speech/nick/Nils 45.mp3',
      }
    }
  }
  speech["player"] = speech["player"][selectedPlayer];

  let dialogues = {
    'player-d1-1': {
      'character': 'player',
      'text': 'Unglaublich. Ich war noch nie im Weltall!',
      'audio': speech["player"]["d1-1"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d1-1': {
      'character': 'robot',
      'text': 'Achja, ihr Menschen reist selten so weit nach oben in den Weltraum, oder? Für mich ist das der 52.496te Weltraum-Ausflug.',
      'audio': speech["robot"]["d1-1"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'player-d1-2': {
      'character': 'player',
      'text': 'Du hast ja mal ein Gedächtnis. Ich kann mich kaum an die Mathestunde von gestern erinnern.',
      'audio': speech["player"]["d1-2"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d1-2': {
      'character': 'robot',
      'text': 'Ich hab immer alles gespeichert. Aber … worum ging es gerade nochmal?',
      'audio': speech["robot"]["d1-2"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'player-d1-3': {
      'character': 'player',
      'text': 'Na wir haben über den <strong>Wasserkreislauf</strong> geredet – und darüber, dass es die Erde ohne ihn gar nicht geben würde.',
      'audio': speech["player"]["d1-3"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d1-3': {
      'character': 'robot',
      'text': 'Ah, genau … Den natürlichen Wasserkreislauf gibt es schon immer auf unserer Welt. Deshalb ist Leben auf unserer Erde überhaupt erst möglich. Das Wasser geht nicht verloren.',
      'audio': speech["robot"]["d1-3"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'player-d1-4': {
      'character': 'player',
      'text': 'Ich war mir gar nicht bewusst, dass Wasser eine so große Rolle für uns spielt.',
      'audio': speech["player"]["d1-4"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d1-4': {
      'character': 'robot',
      'text': 'Die Erde nennt man ja nicht ohne Grund den <strong>„blauen Planeten“</strong>. Da die Erdoberfläche mit soviel Wasser bedeckt ist.',
      'audio': speech["robot"]["d1-4"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'robot-quiz-hint-1': {
      'character': 'robot',
      'text': 'Vielleicht hilft es, dir die Erdkugel unter uns anzuschauen, um besser schätzen zu können.',
      'audio': speech["robot"]["quiz-1-hint"],
      // no animations
    },

    'player-d2-1': {
      'character': 'player',
      'text': 'Wow, dann haben wir ja unglaublich viel Trinkwasser auf der Welt!',
      'audio': speech["player"]["d2-1"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d2-1': {
      'character': 'robot',
      'text': 'Mhh, bist du dir da sicher?',
      'audio': speech["robot"]["d2-1"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'robot-quiz-hint-2': {
      'character': 'robot',
      'text': 'Hast du schon einmal salziges Meerwasser verschluckt?',
      'audio': speech["robot"]["quiz-2-hint"],
      // no animations
    },

    'robot-d3-1': {
      'character': 'robot',
      'text': 'Genau. <strong>Meerwasser</strong> kannst Du nicht trinken, da das Salz darin den Körper mehr austrocknet als den Durst löscht. Man kann zwar jedes Wasser zu Trinkwasser aufbereiten, aber das ist sehr aufwendig.<br> Wirklich sauberes und sicheres Trinkwasser gibt es nur direkt an geschützten <strong>Quellen</strong> und aus dem <strong>Wasserhahn</strong>.',
      'audio': speech["robot"]["d3-1"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'player-d3-1': {
      'character': 'player',
      'text': 'Mh, das ist dann natürlich schon viel weniger Trinkwasser.',
      'audio': speech["player"]["d3-1"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d3-2': {
      'character': 'robot',
      'text': 'Eben. Und jetzt rate mal, zu wie viel Prozent du selber aus Wasser bestehst!',
      'audio': speech["robot"]["d3-2"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'robot-quiz-hint-3': {
      'character': 'robot',
      'text': 'Wenn jemand 40 kg wiegt, sind davon etwa 28 kg Flüssigkeit.',
      'audio': speech["robot"]["quiz-3-hint"],
      // no animations
    },

    'player-d4-1': {
      'character': 'player',
      'text': 'Oha, ich besteh zu mehr als der Hälfte aus Wasser?!',
      'audio': speech["player"]["d4-1"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d4-1': {
      'character': 'robot',
      'text': 'Nicht nur du. Auch Tiere und sogar viele Lebensmittel bestehen aus ganz viel Wasser. Gurken zum Beispiel bestehen fast komplett aus Wasser – nämlich bis zu 90 %!',
      'audio': speech["robot"]["d4-1"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'robot-d4-2': {
      'character': 'robot',
      'text': 'Der menschliche Körper braucht viel Wasser um zu funktionieren. Für deine Organe, dein Blut und vieles mehr ist es wichtig, immer genug zu trinken.',
      'audio': speech["robot"]["d4-2"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'robot-quiz-hint-4': {
      'character': 'robot',
      'text': 'Zwei große Wasserflaschen sollten reichen, am besten mit unserem guten Trinkwasser aus dem Wasserhahn abgefüllt.',
      'audio': speech["robot"]["quiz-4-hint"],
      // no animations
    },

    'player-d5-1': {
      'character': 'player',
      'text': 'Das ist schon eine ganze Menge. Vor allem, wenn man bedenkt, wie viele Menschen es auf der Welt gibt – fast 8 Milliarden!',
      'audio': speech["player"]["d5-1"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d5-1': {
      'character': 'robot',
      'text': 'Darum ist es auch so wichtig, sorgsam mit unserem Wasser umzugehen und es zu schützen. Wasser ist ein kostbarer Schatz. Auch wenn wir das nicht immer so wahrnehmen.',
      'audio': speech["robot"]["d5-1"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'robot-d5-2': {
      'character': 'robot',
      'text': 'Weißt Du eigentlich, dass es auf unserer Erde Menschen gibt, die oft tagelang kein sauberes Trinkwasser haben oder weit laufen müssen, bis sie zu einem Brunnen kommen?',
      'audio': speech["robot"]["d5-2"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    // 'robot-d5-3': {
    //   'character': 'robot',
    //   'text': 'Ich zeig dir dazu am besten mal einen Film...',
    //   'audio': speech["robot"]["d5-3"],
    //   'animation': animations["robot"]["talk"],
    //   'animationAfter': animations["robot"]["idle"]
    // },

    'player-d5-2': {
      'character': 'player',
      'text': 'Das war mir gar nicht so bewusst. Wasser ist so wichtig. Ich will selber mehr tun, um es zu schützen und sparsam damit umzugehen. Aber wie mach ich das denn?',
      'audio': speech["player"]["d5-2"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-quiz-hint-5': {
      'character': 'robot',
      'text': 'Viele Reinigungsmittel belasten die Qualität von unserem Trinkwasser.',
      'audio': speech["robot"]["quiz-5-hint"],
      // no animations
    },

    'robot-d6-1': {
      'character': 'robot',
      'text': 'Genau. Durch Abfälle gelangt zusätzlicher Schmutz ins Wasser. Daher: Immer besser den Mülleimer benutzen. Ich zeig dir noch ein paar Tipps, wie man Wasser schützen kann.',
      'audio': speech["robot"]["d6-1"],
      'animation': animations["robot"]["talk"],
      'animationAfter': animations["robot"]["idle"]
    },

    'player-d6-1': {
      'character': 'player',
      'text': 'Das ist ja gar nicht so schwierig – lässt sich doch alles machen. Vielleicht rede ich auch mal mit meinen Eltern darüber, was wir besser machen könnten. Hilft es denn auch, etwas weniger Wasser zu verwenden?',
      'audio': speech["player"]["d6-1"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },

    'robot-d6-2': {
      'character': 'robot',
      'text': 'Das hilft auf jeden Fall! … Ohje, ich sehe gerade, dass uns der Sauerstoff langsam ausgeht. Wird wohl Zeit für uns, zurück nach Hause zu reisen. Daheim können wir dann in Ruhe weiterreden.',
      'audio': speech["robot"]["d6-2"],
      'animation': animations["robot"]["lowBattery"],
      'animationAfter': animations["robot"]["lowBattery"]
    },

    'player-d6-2': {
      'character': 'player',
      'text': 'Na gut, wir sind ja auch schon ganz schön lang unterwegs – und langsam hab ich auch Kohldampf! Dann flieg uns mal nach Hause!',
      'audio': speech["player"]["d6-2"],
      'animation': animations["player"]["talk"],
      'animationAfter': animations["player"]["idle"]
    },
  }

  let defaultQuizReactions = {
    'correct-star': {
      'robot': {
        'text': 'Richtige Antwort beim ersten Versuch! Dafür verdienst du einen Stern.',
        'animation': animations["robot"]["joy"],
        'animationAfter': animations["robot"]["idle"],
      },
      'player': {
        'animation': animations["player"]["joy"],
        'animationAfter': animations["player"]["idle"]
      }
    },
    'correct': {
      'robot': {
        'text': 'Richtige Antwort! Gut gemacht.',
        'animation': animations["robot"]["joy"],
        'animationAfter': animations["robot"]["idle"],
      },
      'player': {
        'animation': animations["player"]["joy"],
        'animationAfter': animations["player"]["idle"]
      }
    },
    'wrong': {
      'robot': {
        'text': 'Das war leider nicht richtig. Versuch’s einfach nochmal!',
      }
    }
  }







  // //// //////////
  // ////  STEPS
  // //// //////////

  return {
    ... baseConfig,

    'preload': {
      animations,
      sounds,
      speech,
      images
    },

    'beforeLevelEnter': function (game, next){
      next();
    },

    'steps': [
      // Level Setup
      {
        'beforeEnter': function (game, next) {

          let animationCount = 0;
          game.startAnimation('player', animations['player']['idle'], true, false, ()=>{
            animationCount ++
            if (animationCount === 3){ next(); }
          });
          game.startAnimation('robot', animations['robot']['idle'], true, false, ()=>{
            animationCount ++
            if (animationCount === 3){ next(); }
          });
          game.startAnimation('background', animations['background']['space'], true, false, ()=>{
            animationCount ++
            if (animationCount === 3){ next(); }
          });

          game.startAudio('background', sounds['ambient'], true);
        },

        // 'waitForUser': 'Level Starten'
      },
      {
        'type': 'tutorial'
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d1-1"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d1-1"],
        'waitForUser': 'Weiter'
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d1-2"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d1-2"],
        'waitForUser': 'Weiter'
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d1-3"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d1-3"],
        'waitForUser': 'Weiter'
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d1-4"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d1-4"],
        'waitForUser': 'Weiter'
      },

      // Quiz 1
      {
        'type': 'quiz',
        'quiz': {
          'title': 'Frage 1',
          'question': {
            'text': 'Was denkst du, wieviel Prozent der Erde von Wasser bedeckt sind?',
            'audio': speech['robot']["quiz-1"]
          },
          'options': [
            {
              'text': '14 %',
              'isCorrect': false
            },
            {
              'text': '55 %',
              'isCorrect': false
            },
            {
              'text': '70 %',
              'isCorrect': true
            }
          ],
          'hint': {
            'dialogue': dialogues['robot-quiz-hint-1']
          },
          'reactions': defaultQuizReactions
        }
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d2-1"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d2-1"],
        'waitForUser': 'Weiter'
      },

      // Quiz 2
      {
        'type': 'quiz',
        'quiz': {
          'title': 'Frage 2',
          'question': {
            'text': 'Welches Wasser können wir jederzeit trinken?',
            'audio': speech['robot']["quiz-2"]
          },
          'options': [
            {
              'text': 'Jedes Wasser',
              'isCorrect': false
            },
            {
              'text': 'Wasser aus Quellen und dem Wasserhahn',
              'isCorrect': true
            },
            {
              'text': 'Wasser aus Meeren und Ozeanen',
              'isCorrect': false
            }
          ],
          'hint': {
            'dialogue': dialogues['robot-quiz-hint-2']
          },
          'reactions': defaultQuizReactions
        }
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d3-1"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d3-1"],
        'waitForUser': 'Weiter'
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d3-2"],
      },

      // Quiz 3
      {
        'type': 'quiz',
        'quiz': {
          'title': 'Frage 3',
          'question': {
            'text': 'Zu wieviel Prozent besteht der menschliche Körper aus Flüssigkeit?',
            'audio': speech['robot']["quiz-3"]
          },
          'options': [
            {
              'text': '70 %',
              'isCorrect': true
            },
            {
              'text': '50 %',
              'isCorrect': false
            },
            {
              'text': '30 %',
              'isCorrect': false
            }
          ],
          'hint': {
            'dialogue': dialogues['robot-quiz-hint-3']
          },
          'reactions': defaultQuizReactions
        }
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d4-1"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d4-1"],
        'waitForUser': 'Weiter'
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d4-2"],
        'waitForUser': 'Weiter'
      },

      // Quiz 4
      {
        'type': 'quiz',
        'quiz': {
          'title': 'Frage 4',
          'question': {
            'text': 'Wieviel Flüssigkeit solltest du täglich am besten trinken, um dich gesund zu ernähren?',
            'audio': speech['robot']["quiz-4"]
          },
          'options': [
            {
              'text': '1-2 Liter',
              'isCorrect': false
            },
            {
              'text': '2-3 Liter',
              'isCorrect': true
            },
            {
              'text': '4-5 Liter',
              'isCorrect': false
            }
          ],
          'hint': {
            'dialogue': dialogues['robot-quiz-hint-4']
          },
          'reactions': defaultQuizReactions
        }
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d5-1"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d5-1"],
        'waitForUser': 'Weiter'
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d5-2"],
      },

      // {
      //   'type': 'dialogue',
      //   'dialogue': dialogues["robot-d5-3"],
      //   'waitForUser': 'Weiter'
      // },

      // {
      //   'type': 'display',
      //   'content': [
      //     {
      //       'type': 'video',
      //       'videoSrc': '/video/Erlauf - 29235.mp4'
      //     }
      //   ],
      //   'startAnimation': animations['robot']['zoomIn'],
      //   'endAnimation': animations['robot']['zoomOut'],
      //   'animationAfter': animations['robot']['idle']
      // },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d5-2"],
        'waitForUser': 'Weiter'
      },

      // Quiz 5
      {
        'type': 'quiz',
        'quiz': {
          'title': 'Frage 5',
          'question': {
            'text': 'Wie können wir unser Wasser schützen?',
            'audio': speech['robot']["quiz-5"]
          },
          'options': [
            {
              'text': 'Speisereste und anderen Müll nicht im Abfluss oder der Toilette herunterspülen',
              'isCorrect': true
            },
            {
              'text': 'Wasserleitungen öfter mit Chemikalien reinigen',
              'isCorrect': false
            },
            {
              'text': 'Häufiger duschen',
              'isCorrect': false
            }
          ],
          'hint': {
            'dialogue': dialogues['robot-quiz-hint-5']
          },
          'reactions': defaultQuizReactions
        }
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d6-1"],
        'waitForUser': 'Weiter'
      },

      {
        'type': 'display',
        'content': [
          {
            'type': 'text',
            'htmlText': '' +
                '<h3>So kannst du Wasser im Alltag schützen</h3>' +
                '<p><strong>Toilette nicht als Mülleimer verwenden</strong>' +
                'Das sorgt sonst nur für zusätzliche Verschmutzung im Abwasser, das wieder gereinigt werden muss.</p>' +
                '<p><strong>Möglichst auf Plastikprodukte verzichten</strong>' +
                'Plastik löst sich ganz schwer auf und kann so früher oder später in unseren Flüssen und Meeren landen – gesund ist das auf keinen Fall.</p>' +
                '<p><strong>Auf Chemie verzichten</strong>' +
                'Chemische Reinigungsmittel oder Pflanzenschutzmittel und Unkrautvernichter im Garten belasten das Wasser auch mit ihren Inhaltsstoffen – besser: Öko-Produkte verwenden, die aus natürlichen Wirkstoffen hergestellt werden.</p>'
          }
        ],
        'startAnimation': animations['robot']['zoomIn'],
        'endAnimation': animations['robot']['zoomOut'],
        'animationAfter': animations['robot']['idle']
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d6-1"],
        'waitForUser': 'Weiter'
      },

      {
        'afterEnter': function (game, next){
          // game.startAnimation('robot', animations['robot']['blink'], true, false);
          game.startAudio('SFX', sounds['beep'], true, false);
          next();
        }
      },

      {
        'type': 'dialogue',
        'dialogue': dialogues["robot-d6-2"],
      },
      {
        'type': 'dialogue',
        'dialogue': dialogues["player-d6-2"],
        'waitForUser': 'Weiter'
      },

      {
        'afterEnter': function (game, next){
          game.requestAudioEnd('SFX', false);
          next();
        }
      },

    ],

    // ///// End of level
    'summary':{
      'reactions': {
        'background': animations['background']['level-end'],
        'player': animations['player']['joy'],
        'robot': animations['robot']['joy']
      }
    },

    'beforeLevelLeave': function (game, next){
      next();
    },
  }
}