<template>
  <div class="animation-layer-container">
    <div v-for="layer in animationLayers" class="animation-layer" :class="'animation-layer--'+layer" :ref="'animation-layer-'+layer" :key="layer"></div>
  </div>
</template>

<script>
  import Lottie from "lottie-web";

  export default {
    'name': 'Animation',
    'props': {
      'animationLayers': Array
    },
    'data': ()=>{
      return {
        'activeAnimations': {},
        'focusOnGame': true,
        'destroyCleanupStuff': {}
      }
    },
    'methods': {
      getAnimationLayerId(layerName){
        // layerName == 'background' / 'player' / 'robot'
        return 'animation-layer-' + layerName
      },

      getActiveAnimation(layerName){
        let layerId = this.getAnimationLayerId(layerName);
        return this.activeAnimations[layerId];
      },

      startAnimation(layerName, animation, loop = false, endPreviousAnimation = true, callback = ()=>{}) {
        let layerId = this.getAnimationLayerId(layerName);
        let waitForPreviousToEnd = (endPreviousAnimation === true); // (typeof endPreviousAnimation === "boolean")
        let startOnFrameOfPrevious = (endPreviousAnimation === 'swapOnFrame');
        let skipLoop = (loop === 'skipLoop');
        let showLoop = loop && !skipLoop;
        animation.id = layerName +'-'+[animation.startFrame, animation.loopStartFrame, animation.loopEndFrame, animation.endFrame].join('-');

        // console.log('ANIM |', layerName, 'Start requested', layerName, waitForPreviousToEnd, startOnFrameOfPrevious);

        let lottieAnimation = Lottie.loadAnimation({
          name: animation.id,
          container: this.$refs[layerId][0],
          path: animation.path,
          animationData: animation.animationData,
          renderer: 'svg',
          autoplay: false, // will be done manually
          loop: false // will be done manually for reasons
        });

        lottieAnimation.setSubframe(false);

        if (animation.animationData){
          onConfigReady();
        }

        if (animation.path){
          lottieAnimation.addEventListener('config_ready', ()=>{
            onConfigReady.call(this);
          });
        }

        function onConfigReady(){
          animation.startFrame = (typeof (animation.startFrame) === 'number') ? animation.startFrame : (animation.loopStartFrame || 0);
          animation.endFrame = animation.endFrame || animation.loopEndFrame || (lottieAnimation.totalFrames - 1);

          animation.loopStartFrame = animation.loopStartFrame || animation.startFrame;
          animation.loopEndFrame = animation.loopEndFrame || animation.endFrame;

          animation.initialStartFrame = animation.startFrame;
          animation.initialLoopStartFrame = animation.loopStartFrame;

          if (lottieAnimation.totalFrames < animation.endFrame){
            console.warn('ANIM |', layerName, 'ANIMATION END OUT OF REACH', lottieAnimation.totalFrames, '<', animation.endFrame);
            animation.endFrame = lottieAnimation.totalFrames;
          }

          if (lottieAnimation.totalFrames < animation.loopEndFrame){
            console.warn('ANIM |', layerName, 'ANIMATION LOOP END OUT OF REACH', lottieAnimation.totalFrames, '<', animation.loopEndFrame);
            animation.loopEndFrame = lottieAnimation.totalFrames;
          }

          if (startOnFrameOfPrevious){
            let previousAnimation = this.getActiveAnimation(layerName);
            let previousAnimationCurrentFrame = (previousAnimation && previousAnimation['lottieAnimation'].currentFrame) || animation.initialStartFrame;
            animation.initialStartFrame = previousAnimationCurrentFrame;
            animation.initialLoopStartFrame = (animation.startFrame < animation.loopStartFrame) ? animation.loopStartFrame : animation.initialStartFrame;
          }

          // console.log('DIALOGUE |', 'animation.initialFrame', animation.initialStartFrame);

          lottieAnimation.hide();
          lottieAnimation.goToAndStop(animation.initialStartFrame, true, lottieAnimation.name);
        }

        lottieAnimation.addEventListener('data_ready', ()=>{
          // currently not in use anymore
        });

        lottieAnimation.addEventListener('DOMLoaded', ()=>{
          if (waitForPreviousToEnd){
            this.requestAnimationEnd(layerName, start.bind(this));
          } else {
            start.call(this);
          }
        });

        function start() {
          this.clearAnimationLayer(layerName);

          // console.log('ANIM |', layerName, 'Starting animation');

          let activeAnimation = {
            'lottieAnimation': lottieAnimation,
            'config': animation,
            'loop': showLoop,
            'skipLoop': skipLoop,
            'hasIntro': animation.startFrame < animation.loopStartFrame,
            'hasOutro': animation.loopEndFrame < animation.endFrame,
            'status': 'fresh',
            'animationEndCallback': null
          }

          this.activeAnimations[layerId] = activeAnimation;

          lottieAnimation.show();

          if (!activeAnimation.loop && !activeAnimation.skipLoop){
            // just play some part and then stop
            // console.log('ANIM |', layerName, 'Animation does not loop');
            initSegment();
            callCallback();
          }

          if ((activeAnimation.loop || activeAnimation.skipLoop) && activeAnimation.hasIntro){
            // play intro segment and then start loop
            // console.log('ANIM |', layerName, 'Animation loops with intro');
            initIntro();
          }

          if (activeAnimation.loop && !activeAnimation.hasIntro) {
            // just loop the segment
            // console.log('ANIM |', layerName, 'Animation loops without intro');
            initLoops();
            callCallback();
          }

          // //// end of running code

          function initSegment(){
            // play without looping
            // console.log('ANIM |', layerName, 'Starting segment on:', activeAnimation.config.startFrame, 'and stopping on:', activeAnimation.config.endFrame);

            activeAnimation.status = 'playing';
            lottieAnimation.goToAndPlay(activeAnimation.config.initialStartFrame, true, lottieAnimation.name);

            // playing from start to end can be seen as outro, so use this
            initOutro();
          }

          function initIntro(){
            // play intro, then init loop
            // console.log('ANIM |', layerName, 'Starting intro on:', activeAnimation.config.startFrame, 'and stopping on:', activeAnimation.config.loopStartFrame);

            activeAnimation.status = 'intro';
            lottieAnimation.goToAndPlay(activeAnimation.config.initialStartFrame, true, lottieAnimation.name);

            let enterFrameCallback = enterFrameIntro.bind(this);
            lottieAnimation.addEventListener('enterFrame', enterFrameCallback);

            function enterFrameIntro(){
              if (activeAnimation.status === 'intro' || activeAnimation.status === 'ending'){
                if (lottieAnimation.currentFrame >= activeAnimation.config.loopStartFrame - 1){
                  // console.log('ANIM |', layerName, 'Intro done, starting loop');
                  lottieAnimation.removeEventListener('enterFrame', enterFrameCallback)

                  if (activeAnimation.loop){
                    initLoops();
                    callCallback();
                  }

                  if (activeAnimation.skipLoop){
                    lottieAnimation.goToAndPlay(activeAnimation.config.loopEndFrame, true, lottieAnimation.name);
                    initOutro();
                    callCallback();
                  }
                }
              }
            }
          }

          function initLoops(){
            // loop everything
            // console.log('ANIM |', layerName, 'Starting loop from:', activeAnimation.config.loopStartFrame, 'to:', activeAnimation.config.loopEndFrame);

            if (activeAnimation.status !== 'ending'){
              activeAnimation.status = 'looping';
              lottieAnimation.goToAndPlay(activeAnimation.config.initialLoopStartFrame, true, lottieAnimation.name);
            }

            let enterFrameCallback = enterFrameLoop.bind(this);
            lottieAnimation.addEventListener('enterFrame', enterFrameCallback);

            function enterFrameLoop(){
              if (lottieAnimation.currentFrame >= (activeAnimation.config.loopEndFrame - 1)){
                if (activeAnimation.status === 'looping') {
                  // console.log('ANIM |', layerName, 'Restarting loop from frame', activeAnimation.config.loopStartFrame);
                  lottieAnimation.goToAndPlay(activeAnimation.config.loopStartFrame, true, lottieAnimation.name);
                }

                if (activeAnimation.status === 'ending'){
                  lottieAnimation.removeEventListener('enterFrame', enterFrameCallback);

                  if (activeAnimation.hasOutro){
                    initOutro();
                  } else {
                    activeAnimation.status = 'ended';
                    activeAnimation.animationEndCallback && activeAnimation.animationEndCallback();
                    activeAnimation.animationEndCallback = null;
                  }
                }
              }
            }
          }

          function initOutro(){
            activeAnimation.status = 'outro';
            // console.log('ANIM |', layerName, 'Outro initializing', activeAnimation.hasOutro);
            let enterFrameCallback = enterFrameOutro.bind(this);

            if (activeAnimation.hasOutro){
              lottieAnimation.addEventListener('enterFrame', enterFrameCallback);
            } else {
              // let play till end
              lottieAnimation.addEventListener('complete', ()=>{
                if (activeAnimation.status !== 'ending') return;
                activeAnimation.status = 'ended';
                activeAnimation.animationEndCallback && activeAnimation.animationEndCallback();
                activeAnimation.animationEndCallback = null;
              });
            }

            function enterFrameOutro (){
              if (activeAnimation.status === 'outro' || activeAnimation.status === 'ending'){
                if (lottieAnimation.currentFrame >= activeAnimation.config.endFrame - 1){
                  lottieAnimation.removeEventListener('enterFrame', enterFrameCallback);
                  // console.log('ANIM |', layerName, 'Outro done');
                  activeAnimation.status = 'ended';
                  activeAnimation.animationEndCallback && activeAnimation.animationEndCallback();
                  activeAnimation.animationEndCallback = null;
                }
              }
            }
          }

          function callCallback(){
            try{
              callback();
              callback = function (){}; // switch to empty, to not call multiple times
            } catch (error){
              console.warn('Had error in anim-callback', error);
            }
          }
        }
      },

      // ends loops and executes callback as soon as the animation or loop has finished
      requestAnimationEnd(layerName, callback){
        let activeAnimation = this.getActiveAnimation(layerName);

        if (activeAnimation && activeAnimation.status !== 'ended'){
          // console.log('ANIM |', layerName, 'Ending animation');
          activeAnimation.status = 'ending';

          // console.log('ANIM | setting end callback from', activeAnimation.animationEndCallback, 'to', callback);
          activeAnimation.animationEndCallback = ()=>{
            // will be called if animation plays till segment end
            // console.log('ANIM |', layerName, 'END animationEndCallback | Animation has ended');
            try {
              callback();
            } catch (error){
              console.warn('Had error in anim-callback', error);
            }
          }

        } else {
          try {
            callback();
          } catch (error){
            console.warn('Had error in anim-callback', error);
          }
        }
      },

      clearAnimationLayer(layerName){
        // console.log('ANIM |', layerName, 'Clearing layer...');
        let activeAnimation = this.getActiveAnimation(layerName);
        if (activeAnimation){
          activeAnimation.lottieAnimation.hide();
          activeAnimation.lottieAnimation.stop();

          // activeAnimation.lottieAnimation.destroy();

          // try {
            // delete activeAnimation.lottieAnimation;
            // delete activeAnimation;
          // } catch (e){
          //   console.error('ANIM |', layerName, 'Try something different', e);
          // }
        }
      }
    },
    created() {
      Lottie.setQuality('low');

      this.destroyCleanupStuff['blurListener'] = (event) => {
        // console.log('ANIM | Focus out, pause animations');
        this.focusOnGame = false;
        Lottie.pause();
      }

      window.addEventListener("blur", this.destroyCleanupStuff['blurListener']);

      this.destroyCleanupStuff['focusListener'] = (event) => {
        // console.log('ANIM | Focus back, resuming animations');
        this.focusOnGame = true;
        Lottie.play();
      }

      window.addEventListener("focus", this.destroyCleanupStuff['focusListener']);

      // sometimes lottie pauses the animations for no obvious reason... this is my quick fix.
      this.destroyCleanupStuff['freezeInterval'] = setInterval(()=>{
        if (this.focusOnGame){
          Lottie.play();
        }
      }, 500);
    },

    beforeDestroy() {
      window.removeEventListener('blur', this.destroyCleanupStuff['blurListener']);
      window.removeEventListener('focus', this.destroyCleanupStuff['focusListener']);
      clearInterval(this.destroyCleanupStuff['freezeInterval']);

      // TODO: stop all animations on all layers
    }
  }
</script>

<style lang="scss">
@import "src/style/variables";
@import "src/style/mixins";

.animation-layer-container {
  @extend %strech;
}

.animation-layer {
  @extend %strech;

  > * {
    @extend %strech;

    //&:not(:last-child) {
    //  display: none;
    //}
  }
}
</style>