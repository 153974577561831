<template>
  <audio>
    <!-- Audio Component -->
  </audio>
</template>
<script>
  export default {
    'name': 'Audio',

    'data': ()=>{
      return {
        'activeAudio': {},
        'focusOnGame': true
      }
    },

    'methods': {
      startAudio(audioId, path, loop = false, waitForPrevious = false, callback = ()=>{}){
        // console.log('AUDIO | Requested:', audioId, path);
        let audio = new Audio(path);
        audio.loop = loop;

        if (waitForPrevious){
          // console.log('AUDIO | Waiting for previous to end on:', audioId);

          this.requestAudioEnd(audioId, false, ()=>{
            this.activeAudio[audioId] = audio;
            this.playAudioWithCatch(audio, callback);
          });

        } else {
          // console.log('AUDIO | Not waiting for previous to end', audioId);
          if (this.activeAudio[audioId]){
            this.activeAudio[audioId].pause();
          }

          this.activeAudio[audioId] = audio;
          this.playAudioWithCatch(audio, callback);
        }
      },

      playAudioWithCatch(audio, callback){
        audio.play().then(()=>{
          // console.log('AUDIO | Started');
          callback();
        }).catch((e)=>{
          console.error('AUDIO | NOT PLAYING', e);
          callback();
        });
      },

      requestAudioEnd(audioId, hard = false, callback = ()=>{}) {
        let activeAudio = this.activeAudio[audioId];
        // console.log('AUDIO | End requested:', audioId, activeAudio);

        if (!activeAudio){
          callback();
          callback = ()=>{};
        }

        if (activeAudio && (activeAudio.paused || activeAudio.ended)){
          callback();
          callback = ()=>{};
        }

        if (activeAudio && !activeAudio.ended && !activeAudio.paused){
          if (hard){
            activeAudio.pause();
            callback();
            callback = ()=>{};

          } else {
            activeAudio.loop = false;

            activeAudio.endedListener = ()=> {
              // console.log('AUDIO | ENDED', audioId);
              callback();
              callback = ()=>{};
              activeAudio.removeEventListener('pause', activeAudio.pauseListener);
              activeAudio.removeEventListener('ended', activeAudio.endedListener);
              document.body.removeEventListener('click', activeAudio.skipListener);
            }

            activeAudio.pauseListener = ()=> {
              // console.log('AUDIO | PAUSED', audioId);
              callback();
              callback = ()=>{};
              activeAudio.removeEventListener('pause', activeAudio.pauseListener);
              activeAudio.removeEventListener('ended', activeAudio.endedListener);
              document.body.removeEventListener('click', activeAudio.skipListener);
            }

            activeAudio.skipListener = (e)=>{
              if (e.altKey && !activeAudio.ended && !activeAudio.paused){
                console.log('AUDIO | GAH!');
                activeAudio.pause();
                callback();
                callback = ()=>{};
              }
            }

            activeAudio.addEventListener('ended', activeAudio.endedListener);
            activeAudio.addEventListener('pause', activeAudio.pauseListener);
            document.body.addEventListener('click', activeAudio.skipListener);
          }
        } else {
          // callback();
        }
      },
      muteAll(){
        this.setMuteOnAll(true);
      },
      unmuteAll(){
        this.setMuteOnAll(false);
      },
      setMuteOnAll(mute){
        Object.values(this.activeAudio).forEach((audio)=>{
          audio.muted = mute;
        });
      },
      stopAll(){
        Object.values(this.activeAudio).forEach((audio)=>{
          audio.pause();
        });
      }
    },

    beforeDestroy(){
      for (let id in this.activeAudio){
        this.requestAudioEnd(id);

        // let audio = this.activeAudio[id];
        // if (audio.loop){
        //   audio.pause();
        // }
      }
    }
  }
</script>