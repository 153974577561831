import basicAssets from "@/assets/basicAssets";

export default {
  'sounds': {
    ...basicAssets['sounds'],
    'basic-level-achievement-unlocked': '/sounds/Menu_General/0-Level-Belohnung-freigeschalten-funwithsound__success-fanfare-trumpets.mp3',
    'basic-level-ended': '/sounds/Menu_General/0-Level-Ende-505717__scratchers__cheer.mp3',
    'basic-quiz-selection': '/sounds/Menu_General/0-Quiz-Antwort-Auswahl-511484__mattleschuck__success-bell.mp3',
    'basic-quiz-wrong': '/sounds/Menu_General/0-Quiz-Antwort-falsch-173958__leszek-szary__failure.mp3',
    'basic-quiz-right': '/sounds/Menu_General/0-Quiz-Antwort-richtig-leszek-szary__success-2.mp3',
    'basic-robot-display-zoom': '/sounds/Menu_General/0-Ro-Sa-Display-Zoom-Experimente-Videos-space-swoosh-brighter.mp3',
  },

  'animations': {
    ...basicAssets['animations'],
    'robot-display-zoom' : '/animations/robot/Rosa-Zoom/Rosa-Zoom.json',
  },

  'images': {
    ...basicAssets["images"]
  }
}