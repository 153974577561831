<template>
  <div class="pictureProofList">
    <h1>Bildnachweis</h1>

    <table cellspacing="0">
      <tr>
        <td>
          <div>
            &nbsp;
          </div>
        </td>
        <td>
          <div class="margin-left">
            <p>
              <strong>Anbieter</strong>
            </p>
          </div>
        </td>
        <td>
          <div class="margin-left">
            <p>
              <strong>Quelle</strong>
            </p>
          </div>
        </td>
        <td>
          <div class="margin-left">
            <p>
              <strong>Bildnachweis</strong>
            </p>
          </div>
        </td>
        <td>
          <div class="margin-left">
            <p>
              <strong>Verwendung</strong>
            </p>
          </div>
        </td>
      </tr>

      <tr class="round-corners " v-for="item in items" :key="item.id">
        <td>
          <div class="img-wrapper">
            <img :src=" item.image " alt="">
          </div>
        </td>
        <td>
          <div class="margin-left">
            <p>{{ item.provider }}</p>
          </div>
        </td>
        <td>
          <div class="margin-left">
            <a :href="item.linkSource" target="_blank">{{ item.LinkType }}</a> {{ item.textSource }}
          </div>
        </td>
        <td>
          <div class="margin-left">
            <p>{{ item.proof }}</p>
          </div>
        </td>
        <td>
          <div class="margin-left">
            <p>{{ item.usage }}</p>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>


export default {
  name: "PictureProofList",
  data: () => {
    return {
      items: [
        {
          id: 1,
          image: 'images/pictureProof/1.jpg',
          provider: 'Unsplash',
          LinkType: 'Quelle',
          linkSource: 'https://unsplash.com/photos/aRya3uMiNIA',
          textSource: '',
          proof: 'Aaron Burden',
          usage: 'Hintergrund (Spielstart)'
        },
        {
          id: 2,
          image: 'images/pictureProof/2.jpg',
          provider: 'Pexels',
          LinkType: 'Quelle',
          linkSource: 'https://www.pexels.com/de-de/foto/hochwinkelfotografie-des-dorfes-280221/',
          textSource: '',
          proof: 'Pixabay',
          usage: 'Levelauswahl (Level 1/5)'
        },
        {
          id: 3,
          image: 'images/pictureProof/3.jpg',
          provider: 'N‑ERGIE',
          linkSource: '',
          textSource: 'Bildarchiv',
          proof: '© N-ERGIE',
          usage: 'Levelauswahl (Level 2)'
        },
        {
          id: 4,
          image: 'images/pictureProof/4.jpg',
          provider: 'Unsplash',
          LinkType: 'Quelle',
          linkSource: 'https://unsplash.com/photos/fYZ9LnfkAA4',
          textSource: '',
          proof: 'Vladimir Haltakov',
          usage: 'Levelauswahl (Level 3)'
        },
        {
          id: 5,
          image: 'images/pictureProof/5.jpg',
          provider: 'Unsplash',
          LinkType: 'Quelle',
          linkSource: 'https://unsplash.com/photos/Yj1M5riCKk4',
          textSource: '',
          proof: 'NASA',
          usage: 'Levelauswahl/Hintergrund (Level 4)'
        },
        {
          id: 6,
          image: 'images/pictureProof/6.jpg',
          provider: 'Adobe Stock',
          LinkType: 'Quelle',
          linkSource: 'https://stock.adobe.com/de/images/einfache-neue-weisse-kuche-mit-herd/267964808',
          textSource: '',
          proof: 'Robert Kneschke',
          usage: 'Hintergrund (Level 1/5 - Küche)'
        },
        {
          id: 7,
          image: 'images/pictureProof/7.jpg',
          provider: 'Pexels',
          LinkType: 'Quelle',
          linkSource: 'https://www.pexels.com/photo/dirty-construction-dry-pattern-6104790/',
          textSource: '',
          proof: 'Eva Elijas',
          usage: 'Hintergrund (Level 1 - Wasserrohre)'
        },
        {
          id: 8,
          image: 'images/pictureProof/8.jpg',
          provider: 'N‑ERGIE',
          linkSource: '',
          textSource: 'Bildarchiv',
          proof: '© N-ERGIE',
          usage: 'Hintergrund (Level 1 - Hochbehälter)'
        },
        {
          id: 9,
          image: 'images/pictureProof/9.jpg',
          provider: 'N‑ERGIE',
          linkSource: '',
          textSource: 'Bildarchiv',
          proof: '© N-ERGIE',
          usage: 'Hintergrund (Level 1 - Natur)'
        },
        {
          id: 10,
          image: 'images/pictureProof/10.jpg',
          provider: 'N‑ERGIE',
          linkSource: '',
          textSource: 'Bildarchiv',
          proof: '© N-ERGIE',
          usage: 'Hintergrund (Level 1 - Wasserwerk)'
        },
        {
          id: 11,
          image: 'images/pictureProof/11.jpg',
          provider: 'N‑ERGIE',
          linkSource: '',
          textSource: 'Bildarchiv',
          proof: '© N-ERGIE',
          usage: 'Hintergrund (Level 2 - Teich)'
        },
        {
          id: 12,
          image: 'images/pictureProof/12.jpg',
          provider: 'Freepik',
          LinkType: 'Quelle',
          linkSource: 'https://www.freepik.com/free-vector/transparent-clouds-set_1531132.htm',
          textSource: '',
          proof: 'macrovector',
          usage: 'Effekt (Level 2/3 - Wolken)'
        },
        {
          id: 13,
          image: 'images/pictureProof/13.jpg',
          provider: 'Unsplash',
          LinkType: 'Quelle',
          linkSource: 'https://unsplash.com/photos/Ooc2hwpwvkM',
          textSource: '',
          proof: 'Egor Kunovsky',
          usage: 'Hintergrund (Level 3 - Landschaft)'
        },
        {
          id: 14,
          image: 'images/pictureProof/14.jpg',
          provider: 'Pexels',
          LinkType: 'Quelle',
          linkSource: 'https://www.pexels.com/de-de/foto/szenische-ansicht-des-sees-im-wald-247600/',
          textSource: '',
          proof: 'Pixabay',
          usage: 'Hintergrund (Level 3 - Wiese)'
        },
        {
          id: 15,
          image: 'images/pictureProof/15.jpg',
          provider: 'SIGG',
          LinkType: 'Quelle',
          linkSource: 'https://sigg.com/media/catalog/product/cache/f42d1b7a18f67e0d4512262fee3907e1/0/_/0.5l_8581.80_h_c_one_brushed.png',
          textSource: '',
          proof: '© SIGG',
          usage: 'Wasserhelden-Auszeichung (PDF-Download)'
        },
        {
          id: 16,
          image: 'images/pictureProof/16.jpg',
          provider: 'N‑ERGIE',
          linkSource: '',
          textSource: 'Bildarchiv',
          proof: '© N-ERGIE',
          usage: 'Experiment 1 (Level 2)'
        },
        {
          id: 17,
          image: 'images/pictureProof/17.jpg',
          provider: 'N‑ERGIE',
          linkSource: '',
          textSource: 'Bildarchiv',
          proof: '© N-ERGIE',
          usage: 'Experiment 1 (Level 2)'
        },
        {
          id: 18,
          image: 'images/pictureProof/18.jpg',
          provider: 'N‑ERGIE',
          linkSource: '',
          textSource: 'Bildarchiv',
          proof: '© N-ERGIE',
          usage: 'Experiment 1 (Level 2)'
        },
        {
          id: 19,
          image: 'images/pictureProof/19.jpg',
          provider: 'N‑ERGIE',
          linkSource: '',
          textSource: 'Bildarchiv',
          proof: '© N-ERGIE',
          usage: 'Experiment 2 (Level 2)'
        },
        {
          id: 20,
          image: 'images/pictureProof/20.jpg',
          provider: 'N‑ERGIE',
          linkSource: '',
          textSource: 'Bildarchiv',
          proof: '© N-ERGIE',
          usage: 'Experiment 2 (Level 2)'
        },
        {
          id: 21,
          image: 'images/pictureProof/21.jpg',
          provider: 'N‑ERGIE',
          linkSource: '',
          textSource: 'Bildarchiv',
          proof: '© N-ERGIE',
          usage: 'Experiment 2 (Level 2)'
        },
        {
          id: 22,
          image: 'images/pictureProof/22.jpg',
          provider: 'N‑ERGIE',
          linkSource: '',
          textSource: 'Bildarchiv',
          proof: '© N-ERGIE',
          usage: 'Experiment 3 (Level 3)'
        },
        {
          id: 23,
          image: 'images/pictureProof/23.jpg',
          provider: 'N‑ERGIE',
          linkSource: '',
          textSource: 'Bildarchiv',
          proof: '© N-ERGIE',
          usage: 'Experiment 3 (Level 3)'
        },
        {
          id: 24,
          image: 'images/pictureProof/24.jpg',
          provider: 'N‑ERGIE',
          linkSource: '',
          textSource: 'Bildarchiv',
          proof: '© N-ERGIE',
          usage: 'Experiment 3 (Level 3)'
        },
        {
          id: 25,
          image: 'images/pictureProof/25.jpg',
          provider: 'N‑ERGIE',
          linkSource: '',
          textSource: 'Bildarchiv',
          proof: '© N-ERGIE',
          usage: 'Experiment 3 (Level 3)'
        },
        {
          id: 26,
          image: 'images/pictureProof/26.jpg',
          provider: 'N‑ERGIE',
          linkSource: '',
          textSource: 'Bildarchiv',
          proof: '© N-ERGIE',
          usage: 'Experiment 3 (Level 3)'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
table{
  border-spacing: 0 10px;
}

a{
  text-decoration: underline;
}

.round-corners{
  border-radius: 5px;
  background-color: #F0F0F0;
}

.margin-left {
  margin: 20px;
}
li{
  list-style: none;
}

h1 {
  text-align: center;
  margin-bottom: 37px;
}

img {
  width: 77px;
}
</style>